import { filter, find, flatMap, head, map, pipe, sort, toArray } from '@fxts/core';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment-timezone';

import { BusinessError, NdlssError } from '@bbng/util/error';
import {
    CCAdministrativeRo,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CalculateDistanceMatrixRo,
    CollectAdministrative,
    CollectBreak,
    CollectEmptying,
    CollectRo,
    CollectService,
    CollectorRo,
    CustomerRo,
    ECollectCharacteristic,
    EDataType,
    EMembershipType,
    EPlanningType,
    EPriceUnit,
    ETrashType,
    ETruckType,
    EVRPNodeType,
    EVrpOrderIdPrefix,
    ErrorContext,
    ErrorResponsability,
    ILandfillInventory,
    ISODate,
    LandfillRo,
    PRIX_DE_REVIENT_ENDLESS,
    PRIX_DE_REVIENT_NON_ENDLESS,
    PRODUCT_FAMILY,
    PlanningCalculateDto,
    PlanningRo,
    PlanningShiftStepCategory,
    ProductInCCOrCO,
    TruckRo,
    VRPAdminCollect,
    VRPBBConstant,
    VRPBBSkills,
    VRPBBTruckType,
    VRPConstant,
    VRPConstantRo,
    VRPDistanceMatrix,
    VRPDoneSteps,
    VRPDoneStepsNode,
    VRPDriverBB,
    VRPDriverDumpster,
    VRPDumpsterConstant,
    VRPDumpsterSkills,
    VRPDumpsterTruckType,
    VRPInstance,
    VRPLandfillBB,
    VRPLandfillDumpster,
    VRPMetadataDone,
    VRPOrderClientBB,
    VRPOrderClientCommon,
    VRPOrderClientDumpster
} from '@bbng/util/types';

import { getMiddleOfTheDay, PARIS_TIMEZONE } from './dates';

export type HashtableDataType = (
    | LandfillRo
    | TruckRo
    | CollectorRo
    | CCServiceRo
    | CCAdministrativeRo
    | CustomerRo
    | CollectRo
) & {
    entity: string;
};

export type ConstructorVRPInput = {
    dto: PlanningCalculateDto;
    vrpConstants: VRPConstantRo<EPlanningType>;
    data: {
        landfills: LandfillRo[];
        missingLandfills: LandfillRo[];
        trucks: TruckRo[];
        collectors: CollectorRo[];
        ccsService: CCServiceRo[];
        ccsAdministrative: CCAdministrativeRo[];
        customers: CustomerRo[];
        collects: CollectRo[];
    };
    distanceMatrix?: CalculateDistanceMatrixRo;
    clusters?: PlanningCalculateDto['clusters'];
};

export class VRPInputMapper {
    private dto: PlanningCalculateDto;
    private type: EPlanningType;
    private day: {
        middleOfDay: ISODate;
        dayOfWeek: string;
        dayNumber: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    };
    private vrpConstants: VRPConstantRo<EPlanningType>;
    private data: {
        landfills: LandfillRo[];
        missingLandfills: LandfillRo[];
        trucks: TruckRo[];
        collectors: CollectorRo[];
        ccsService: CCServiceRo[];
        ccsAdministrative: CCAdministrativeRo[];
        customers: CustomerRo[];
        collects: CollectRo[];
    };
    private distanceMatrix?: CalculateDistanceMatrixRo;
    private clusters?: PlanningCalculateDto['clusters'];

    constructor(data: ConstructorVRPInput) {
        this.dto = data.dto;
        this.type = data.dto.type;
        this.day = {
            middleOfDay : moment.utc(getMiddleOfTheDay(data.dto.day)).toISOString(),
            dayOfWeek   : moment.utc(data.dto.day).format('dddd'),
            dayNumber   : (moment.utc(data.dto.day).isoWeekday() - 1) as 0 | 1 | 2 | 3 | 4 | 5 | 6
        };
        this.vrpConstants = data.vrpConstants;
        this.data = {
            landfills         : data.data.landfills,
            missingLandfills  : data.data.missingLandfills,
            trucks            : data.data.trucks,
            collectors        : data.data.collectors,
            ccsService        : data.data.ccsService,
            ccsAdministrative : data.data.ccsAdministrative,
            customers         : data.data.customers,
            collects          : data.data.collects
        };
        this.distanceMatrix = data.distanceMatrix;
        this.clusters = data.clusters;
    }

    private assignZoneToCollect = (ccService: CCServiceRo): string => {
        if (this.clusters === undefined) return 'Z0';

        const clusterIdx = Object.values(this.clusters).findIndex((ccId) => {
            return ccId.includes(ccService.id);
        });

        return `Z${clusterIdx}`;
    };

    private assignZoneToCollector = (collector: CollectorRo): string[] => {
        if (this.clusters === undefined) return ['Z0'];

        const correspondingTruckId = this.dto.parameters.find((param) => param.collector_id === collector.id)?.truck_id;

        if (correspondingTruckId === undefined) return ['Z0'];

        const clusterIdx = Object.keys(this.clusters).findIndex((truckId) => {
            return truckId === correspondingTruckId;
        });

        if (this.clusters[correspondingTruckId].length > 0) return [`Z${clusterIdx}`];

        return Object.keys(this.clusters)
            .map((truckId, idx) => {
                if (this.clusters && this.clusters[truckId].length > 0) {
                    return `Z${idx}`;
                }
                return undefined;
            })
            .filter((z): z is string => z !== undefined);
    };

    /**
     * @param {ISODate} date
     * @param {number} minutes
     *
     * @returns {ISODate}
     */
    private _addMinutesToDate = (date: ISODate, minutes = 0): ISODate => {
        return moment.utc(date).add(minutes, 'minutes').toISOString();
    };

    private _addMinutesBeforeFromDate = (date: ISODate, minutes = 0): ISODate => {
        return moment.utc(date).subtract(minutes, 'minutes').toISOString();
    };

    /**
     * @description
     * Convert a an ISO date to a HH:mm string
     * @param date ISODate
     * @returns HH:mm in Paris timezone
     * @example
     * _dateToParisHHmm('2020-01-01T10:30:00.000Z') -> '11:30' (in january, we are in UTC+1 in France)
     * _dateToParisHHmm('2020-07-01T10:30:00.000Z') -> '12:30' (in july, we are in UTC+2 in France)
     */
    private _dateToParisHHmm = (date: ISODate): string => {
        return moment.utc(date).tz(PARIS_TIMEZONE).format('HH:mm');
    };

    /**
     * @description
     * Convert minites to duration
     * If superior to 72h, return 72:00
     *
     * @param number
     * @returns string
     *
     * @example
     * _minsToDuration(92) // 01:32
     */
    private _minsToDuration = (number: number): string => {
        //If superior to 72H, return 72H
        if (number > 4320) {
            return '72:00';
        }
        const durationMinutes = number % 60;
        const durationHours = Math.floor(number / 60);
        return `${durationHours < 10 ? '0' : ''}${durationHours}:${durationMinutes < 10 ? '0' : ''}${durationMinutes}`;
    };

    /**
     * @description
     * Format all constants to be used in the vrp instance
     * according to the type of the requested planning (BIG_BAG | DUMPSTER)
     *
     * Some elements are not fixed, they are given as a param
     *
     * @returns all vrp constants
     */
    private _constants = () => {
        const common: VRPConstant = {
            CTG_LONG_POLLING_AVEC_REDIS               : this.vrpConstants.CTG_LONG_POLLING_AVEC_REDIS,
            CTG_SOLVER_NOMBRE_MAX_THREADS             : this.vrpConstants.CTG_SOLVER_NOMBRE_MAX_THREADS,
            CTG_SOLVER_DUREE_OPTIMISATION_EN_SECONDES :
                this.dto.optimization_duration_seconds ?? this.vrpConstants.CTG_SOLVER_DUREE_OPTIMISATION_EN_SECONDES,
            CTG_SOLVER_VERBOSE_DISPLAY_MODE                     : this.vrpConstants.CTG_SOLVER_VERBOSE_DISPLAY_MODE,
            CTG_OSM_ZOOM_LEVEL                                  : this.vrpConstants.CTG_OSM_ZOOM_LEVEL,
            CTG_OSM_DISTANCE_CORRECTION                         : this.vrpConstants.CTG_OSM_DISTANCE_CORRECTION,
            CTG_NB_UNITE_PAR_HEURE                              : this.vrpConstants.CTG_NB_UNITE_PAR_HEURE,
            CTG_UNITE_TEMPORELLE_MINUTE                         : this.vrpConstants.CTG_UNITE_TEMPORELLE_MINUTE,
            CTG_CHAUFFEUR_MAX_HEURE_DE_TRAVAIL                  : this.vrpConstants.CTG_CHAUFFEUR_MAX_HEURE_DE_TRAVAIL,
            CTG_CHAUFFEUR_MAX_NB_COMMANDE                       : this.vrpConstants.CTG_CHAUFFEUR_MAX_NB_COMMANDE,
            CTG_CHAUFFEUR_MAX_STEPS                             : this.vrpConstants.CTG_CHAUFFEUR_MAX_STEPS,
            CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_COMMANDES_PAR_JOUR :
                this.vrpConstants.CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_COMMANDES_PAR_JOUR,
            CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_DISTANCES_COMMANDES_CONSECUTIVES:
                this.vrpConstants.CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_DISTANCES_COMMANDES_CONSECUTIVES,
            CTG_CHAUFFEUR_MAX_HEURE_GLISSANTE_REGLEMENTATION:
                this.vrpConstants.CTG_CHAUFFEUR_MAX_HEURE_GLISSANTE_REGLEMENTATION,
            CTG_CHAUFFEUR_PAUSE      : this.vrpConstants.CTG_CHAUFFEUR_PAUSE,
            CTG_CHAUFFEUR_ATTENTE    : this.vrpConstants.CTG_CHAUFFEUR_ATTENTE,
            CTG_CHAUFFEUR_TRAITEMENT : this.vrpConstants.CTG_CHAUFFEUR_TRAITEMENT,
            CTG_CHAUFFEUR_ROUTES     : this.vrpConstants.CTG_CHAUFFEUR_ROUTES,
            CTG_JOURS_DE_COLLECTE    : this.vrpConstants.CTG_JOURS_DE_COLLECTE
        };

        const bigbag: Omit<VRPBBConstant, 'CTG_COMMANDE_CAPACITE_FLOTTE_M3'> = {
            CTG_DDXXSBIGM           : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_DDXXSBIGM,
            CTG_DXXSBIGM            : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_DXXSBIGM,
            CTG_XXSBIGM             : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_XXSBIGM,
            CTG_XSBIGM              : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_XSBIGM,
            CTG_SBIGM               : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_SBIGM,
            CTG_MBIGM               : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_MBIGM,
            CTG_LBIGM               : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_LBIGM,
            CTG_XBIGM               : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_XBIGM,
            CTG_CAMION_DUREE_SET_UP : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_CAMION_DUREE_SET_UP,
            CTG_CAMION_DUREE_SET_DOWN: (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_CAMION_DUREE_SET_DOWN,
            CTG_CAMION_DUREE_TRAITEMENT_BB: (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_CAMION_DUREE_TRAITEMENT_BB,
            CTG_CAMION_MIN_VOLUME_UTILE_M3: (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_CAMION_MIN_VOLUME_UTILE_M3,
            CTG_CAMION_MIN_VOLUME_UTILE_KG: (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_CAMION_MIN_VOLUME_UTILE_KG,
            CTG_CAMION_MAX_VOLUME_UTILE_M3: (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_CAMION_MAX_VOLUME_UTILE_M3,
            CTG_CAMION_MAX_VOLUME_UTILE_KG: (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_CAMION_MAX_VOLUME_UTILE_KG,
            CTG_BIG_BAG_GAIN_B0      : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_BIG_BAG_GAIN_B0,
            CTG_BIG_BAG_DEMI_GAIN_B0 : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_BIG_BAG_DEMI_GAIN_B0,
            CTG_BIG_BAG_GAIN_B1      : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_BIG_BAG_GAIN_B1,
            CTG_BIG_BAG_DEMI_GAIN_B1 : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_BIG_BAG_DEMI_GAIN_B1,
            CTG_BIG_BAG_GAIN_B2      : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_BIG_BAG_GAIN_B2,
            CTG_BIG_BAG_DEMI_GAIN_B2 : (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>)
                .CTG_BIG_BAG_DEMI_GAIN_B2,
            CTG_BIG_BAG_TYPE: (this.vrpConstants as VRPConstantRo<EPlanningType.BIG_BAG>).CTG_BIG_BAG_TYPE
        };
        const dumpster: Omit<VRPDumpsterConstant, 'CTG_TAUX_GAIN_DEPOT_BENNE' | 'CTG_TAUX_GAIN_ENLEVEMENT_BENNE'> = {
            CTG_COMMANDE_CAPACITE_FLOTTE_TOURS: (this.vrpConstants as VRPConstantRo<EPlanningType.DUMPSTER>)
                .CTG_COMMANDE_CAPACITE_FLOTTE_TOURS,
            CTG_CAMION_DUREE_LEVAGE_BENNE_VIDE: (this.vrpConstants as VRPConstantRo<EPlanningType.DUMPSTER>)
                .CTG_CAMION_DUREE_LEVAGE_BENNE_VIDE,
            CTG_CAMION_DUREE_DEPOT_BENNE_VIDE: (this.vrpConstants as VRPConstantRo<EPlanningType.DUMPSTER>)
                .CTG_CAMION_DUREE_DEPOT_BENNE_VIDE,
            CTG_CAMION_DUREE_LEVAGE_BENNE_PLEINE: (this.vrpConstants as VRPConstantRo<EPlanningType.DUMPSTER>)
                .CTG_CAMION_DUREE_LEVAGE_BENNE_PLEINE,
            CTG_CAMION_DUREE_DEPOT_BENNE_PLEINE: (this.vrpConstants as VRPConstantRo<EPlanningType.DUMPSTER>)
                .CTG_CAMION_DUREE_DEPOT_BENNE_PLEINE,
            CTG_CAMION_DUREE_DECHARGEMENT_BENNE_PLEINE: (this.vrpConstants as VRPConstantRo<EPlanningType.DUMPSTER>)
                .CTG_CAMION_DUREE_DECHARGEMENT_BENNE_PLEINE,
            CTG_CAMION_DUREE_TRAITEMENT_BENNE: (this.vrpConstants as VRPConstantRo<EPlanningType.DUMPSTER>)
                .CTG_CAMION_DUREE_TRAITEMENT_BENNE
        };

        const dynamicBigBag = {
            CTG_COMMANDE_CAPACITE_FLOTTE_M3: this.dto.general_config.maximum_volume
        };
        const dynamicDumpster = {
            CTG_TAUX_GAIN_DEPOT_BENNE      : this.dto.general_config.dumpster_deposit_priority,
            CTG_TAUX_GAIN_ENLEVEMENT_BENNE : this.dto.general_config.dumpster_removal_priority
        };

        return {
            ...common,
            ...(this.dto.type === EPlanningType.BIG_BAG
                ? {
                      ...bigbag,
                      ...dynamicBigBag
                  }
                : {
                      ...dumpster,
                      ...dynamicDumpster
                  })
        };
    };

    /**
     * @description
     * Return the value for the key CTG_CAMION_TYPE
     *
     * @returns Record<string, VRPBBTruckType|VRPDumpsterTruckType>
     */
    private _camions = () => {
        const trucks = this.data.trucks;

        if (trucks.length === 0)
            throw new NdlssError({
                httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                code           : 'custom',
                dataType       : EDataType.PLANNING,
                context        : ErrorContext.Other,
                responsability : ErrorResponsability.Server,
                params         : {
                    message:
                        "We couln't find trucks in the database to create the planning. At this stage, it's not possible."
                }
            });

        return trucks.reduce((acc, truck) => {
            const data = {
                type_carburant               : 'gazoil',
                autonomie_max                : truck.characteristics.max_autonomy || 400,
                autonomie_restante           : truck.characteristics.remaining_autonomy || 400,
                duree_rechargement_carburant : this._minsToDuration(truck.characteristics.fuel_refill_duration),
                ...(this.type === EPlanningType.BIG_BAG
                    ? {
                          charge_utile_kg            : truck.characteristics.usable_load_kg,
                          volume_utiles_m3           : truck.characteristics.bigbag_capacity || 20,
                          duree_set_up               : this._minsToDuration(truck.characteristics.setup_duration),
                          duree_set_down             : this._minsToDuration(truck.characteristics.setdown_duration),
                          limite_rentabilite_nb_sacs : truck.characteristics.profitability_bag_number_limit
                      }
                    : {
                          capacite_nb_bennes           : truck.characteristics.dumpster_capacity || 2,
                          limite_rentabilite_nb_bennes : truck.characteristics.profitability_dumpster_number_limit || 3
                      }),
                metadata: {
                    truck_id: truck.id
                }
            };
            acc[truck.id] = data;
            return acc;
        }, {} as Record<string, VRPBBTruckType | VRPDumpsterTruckType>);
    };

    /**
     * @description
     * Return the value for the key Decheteries
     *
     * @returns Record<string, VRPLandfillCenterDumpster | VRPLandfillCenterBB>
     */
    private _decheteries = () => {
        const landfills = this.data.landfills;

        if (landfills.length === 0) {
            throw new NdlssError({
                httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                code           : 'custome',
                dataType       : EDataType.PLANNING,
                context        : ErrorContext.Other,
                responsability : ErrorResponsability.Server,
                params         : {
                    message:
                        "We couln't find landfills or centers in the database to create the planning. At this stage, it's not possible."
                }
            });
        }

        const data = [
            ...landfills.map((l) => ({
                ...l,
                is_missing: false
            })),
            ...this.data.missingLandfills.map((l) => ({
                ...l,
                is_missing: true
            }))
        ]
            .map((el: LandfillRo & { is_missing: boolean }) => {
                if (el.opening_time[this.day.dayNumber].is_closed && el.is_missing === false) return null;

                /**
                 * We need to pass all the centers and landfills that are opened the day of the planning.
                 * We also need to add the ones that were in collects but are closed/archived for some reason and were not present in the retrieve data database.
                 */
                // const centerUsedInCollect = collects.find((collect) => {
                //     if (collect.category === PlanningShiftStepCategory.EMPTYING_CENTER) {
                //         if (
                //             (collect as CollectRo<PlanningShiftStepCategory.EMPTYING_CENTER>).informations.center
                //                 .id === center.id
                //         ) {
                //             return true;
                //         }
                //         return false;
                //     }
                //     return false;
                // });

                /**
                 * Check if it is open without lunchbreak
                 */
                const isOpenWithoutLunchBreak = () => {
                    const morning_close = el.opening_time[this.day.dayNumber].morning_close;
                    const afternoon_open = el.opening_time[this.day.dayNumber].afternoon_open;

                    if (morning_close === null || afternoon_open === null) return false;
                    return moment(morning_close, 'HH:mm').isSame(moment(afternoon_open, 'HH:mm'));
                };

                const formatHoraires = () => {
                    const morning_open = this._dateToParisHHmm(el.opening_time[this.day.dayNumber].morning_open);

                    const morning_close_date = isOpenWithoutLunchBreak()
                        ? el.opening_time[this.day.dayNumber].morning_close
                        : moment
                              .utc(el.opening_time[this.day.dayNumber].morning_close)
                              .subtract(15, 'minutes')
                              .toISOString();
                    const morning_close = this._dateToParisHHmm(morning_close_date);

                    const afternoon_open = this._dateToParisHHmm(el.opening_time[this.day.dayNumber].afternoon_open);
                    const afternoon_close = this._dateToParisHHmm(
                        moment
                            .utc(el.opening_time[this.day.dayNumber].afternoon_close)
                            .subtract(15, 'minutes')
                            .toISOString()
                    );

                    return {
                        horaire_ouverture_matin      : morning_open,
                        horaire_fermeture_matin      : morning_close,
                        horaire_ouverture_apres_midi : afternoon_open,
                        horaire_fermeture_apres_midi : afternoon_close
                    };
                };

                return <VRPLandfillDumpster | VRPLandfillBB>{
                    position_grid_X         : el.address.coordinates.longitude,
                    position_grid_Y         : el.address.coordinates.latitude,
                    decheterie_proprietaire : el.owner_is_endless,
                    ...formatHoraires(),
                    metadata                : {
                        landfill_id : el.id,
                        is_collect  : false
                    },
                    disponibilite_pour_optimisation : el.is_missing ? false : true,
                    capacite_max_journaliere_m3     : el.owner_is_endless
                        ? el.maximum_daily_volume_m3 ?? undefined
                        : undefined
                };
            })
            .filter((el): el is VRPLandfillDumpster | VRPLandfillBB => el !== null);

        return data.reduce((acc, el) => {
            const landfill = pipe(
                this.data.landfills,
                filter((landfill) => landfill.id === el.metadata.landfill_id),
                head
            );
            if (!landfill) {
                throw new BusinessError({
                    httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                    responsability : ErrorResponsability.Client,
                    dataType       : EDataType.PLANNING,
                    code           : 'missing_item',
                    params         : {
                        item: 'landfill'
                    }
                });
            }
            const isEndless = (landfill: LandfillRo): landfill is LandfillRo & { inventory: ILandfillInventory } => {
                return landfill.owner_is_endless;
            };

            const cout_traitement_m3 = () => {
                const { trash_details } = landfill;
                if (!trash_details || trash_details.length === 0) {
                    return isEndless(landfill) ? PRIX_DE_REVIENT_ENDLESS : PRIX_DE_REVIENT_NON_ENDLESS;
                }
                //Get only the m3 unit
                //& type of trash MIXED
                const trash = trash_details.find(
                    (trash) => trash.price_unit === EPriceUnit.M3 && trash.type === ETrashType.MIXED
                );
                if (!trash) {
                    return isEndless(landfill) ? PRIX_DE_REVIENT_ENDLESS : PRIX_DE_REVIENT_NON_ENDLESS;
                }
                return Math.ceil(trash.price_value.net_amount_cents / 100);
            };

            acc[landfill.id] = {
                ...el,
                ...(this.type === EPlanningType.BIG_BAG
                    ? {
                          duree_attente      : this._minsToDuration(landfill.average_waiting_minutes) ?? '00:10',
                          duree_dechargement : '00:10',
                          duree_pesee        : '00:10'
                      }
                    : {
                          duree_traitement : this._minsToDuration(landfill.average_waiting_minutes) ?? '00:10',
                          nb_bennes_8m3    : isEndless(landfill) ? landfill.inventory?.nb_8m3_dumpster ?? 5 : 0,
                          nb_bennes_15m3   : isEndless(landfill) ? landfill.inventory?.nb_15m3_dumpster ?? 5 : 0,
                          nb_bennes_20m3   : isEndless(landfill) ? landfill.inventory?.nb_20m3_dumpster ?? 5 : 0,
                          nb_bennes_30m3   : isEndless(landfill) ? landfill.inventory?.nb_30m3_dumpster ?? 5 : 0
                      }),
                cout_traitement_m3: cout_traitement_m3()
            };

            return acc;
        }, {} as Record<string, VRPLandfillDumpster | VRPLandfillBB>);
    };

    /**
     * @description
     * Return the value for the key Missions_Administratives
     * @returns Record<string, VRPAdminCollect>
     */
    private _administratives = () => {
        const collectsAdministrative = pipe(
            this.data.collects,
            filter((f): f is CollectAdministrative => f.category === PlanningShiftStepCategory.ADMINISTRATIVE),
            toArray
        );

        const ccsAdministriveNotFullfilled = pipe(
            this.data.ccsAdministrative,
            filter((f) => f.status !== CC_STATUS.FINISHED && f.status !== CC_STATUS.HAZARD),
            toArray
        );

        const data = [
            ...collectsAdministrative.map((collect) => ({ ...collect, isCollect: true })),
            ...ccsAdministriveNotFullfilled.map((cc) => ({ ...cc, isCollect: false }))
        ].reduce((acc, adminPresta) => {
            const isCollect = (
                adminPresta: (CollectRo | CCAdministrativeRo) & { isCollect: boolean }
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
            ): adminPresta is CollectAdministrative => {
                return adminPresta.isCollect === true;
            };

            const cc = isCollect(adminPresta)
                ? pipe(
                      this.data.ccsAdministrative,
                      find((ccAdmin) => ccAdmin.id === adminPresta.informations.collect_config_id)
                  )
                : adminPresta;
            const collect = isCollect(adminPresta) ? adminPresta : undefined;

            if (!cc) {
                throw new BusinessError({
                    httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                    responsability : ErrorResponsability.Client,
                    dataType       : EDataType.PLANNING,
                    code           : 'missing_item',
                    params         : {
                        item: 'cc administrative'
                    }
                });
            }

            acc[adminPresta.id] = {
                nom_chauffeur                : collect ? collect.collector.id : cc.collector_id[0],
                date_de_debut_au_plus_tot    : this._dateToParisHHmm(collect ? collect.arrived_at : cc.from_date),
                date_de_fin_au_plus_tard     : this._dateToParisHHmm(collect ? collect.completed_at : cc.to_date),
                position_grid_X              : cc.address.coordinates.longitude,
                position_grid_Y              : cc.address.coordinates.latitude,
                duree_mission_administrative : this._minsToDuration(
                    collect
                        ? moment.utc(collect.completed_at).diff(moment.utc(collect.arrived_at), 'minutes')
                        : cc.execution_time_minutes
                ),
                metadata: {
                    cc_id      : cc.id,
                    is_collect : adminPresta.isCollect,
                    collect    : adminPresta.isCollect ? adminPresta.id : undefined
                }
            };
            return acc;
        }, {} as Record<string, VRPAdminCollect>);
        return data;
    };

    /**
     * @description
     * Helper for commande mostly
     */
    private __helpers = () => {
        return {
            common: {
                mapStatutClient: (customer: CustomerRo) =>
                    customer.membership === EMembershipType.GOLD
                        ? 3
                        : customer.membership === EMembershipType.SILVER
                        ? 2
                        : 1,
                mapTypesCamionAdmissibles: (cc: CCServiceRo, trucK_id?: string) => {
                    if (trucK_id) return [trucK_id];
                    if (cc.products.some((prd) => [ETrashType.ASBESTOS, ETrashType.LEAD].includes(prd.trash_type))) {
                        if (cc.characteristics.includes(ECollectCharacteristic.NARROW_STREET))
                            return pipe(
                                this.data.trucks,
                                filter(
                                    (truck) =>
                                        truck.type.includes(ETruckType.ADR) &&
                                        truck.type.includes(ETruckType.NARROW_STREET)
                                ),
                                map((truck) => truck.id),
                                toArray
                            );
                        return pipe(
                            this.data.trucks,
                            filter((truck) => truck.type.includes(ETruckType.ADR)),
                            map((truck) => truck.id),
                            toArray
                        );
                    }
                    const isNarrowStreet = cc.characteristics.includes(ECollectCharacteristic.NARROW_STREET);
                    const isAmpliroll = cc.characteristics.includes(ECollectCharacteristic.DUMPSTER_AMPLIROLL);
                    const isChain = cc.characteristics.includes(ECollectCharacteristic.DUMPSTER_CHAIN);
                    if (isNarrowStreet || isAmpliroll || isChain) {
                        return pipe(
                            this.data.trucks,
                            filter((truck) => {
                                if (isNarrowStreet && !truck.type.includes(ETruckType.NARROW_STREET)) return false;
                                if (isAmpliroll && !truck.type.includes(ETruckType.DUMPSTER_AMPLIROLL)) return false;
                                if (isChain && !truck.type.includes(ETruckType.DUMPSTER_CHAIN)) return false;
                                return true;
                            }),
                            map((truck) => truck.id),
                            toArray
                        );
                    }
                    return pipe(
                        this.data.trucks,
                        map((truck) => truck.id),
                        toArray
                    );
                }
            },
            bb: {
                mapCompetences: (cc: CCServiceRo): VRPBBSkills[] => {
                    if (cc.products.some((prd) => [ETrashType.ASBESTOS, ETrashType.LEAD].includes(prd.trash_type))) {
                        return [VRPBBSkills.ADR];
                    }
                    return Object.keys(VRPBBSkills) as VRPBBSkills[];
                },
                mapBBNumber: (products: ProductInCCOrCO[]): { b0: number; b1: number; b2: number } => {
                    return {
                        b0:
                            products
                                .filter((item) => item.volume_m3 === 0.25)
                                ?.reduce((acc, item) => acc + item.quantity, 0) ?? 0,
                        b1:
                            products
                                .filter((item) => item.volume_m3 === 1)
                                ?.reduce((acc, item) => acc + item.quantity, 0) ?? 0,
                        b2:
                            products
                                .filter((item) => item.volume_m3 === 2)
                                ?.reduce((acc, item) => acc + item.quantity, 0) ?? 0
                        // b0 : products.filter((item) => item.volume_m3 === 0.25)?.[0]?.quantity ?? 0,
                        // b1 : products.filter((item) => item.volume_m3 === 1)?.[0]?.quantity ?? 0,
                        // b2 : products.filter((item) => item.volume_m3 === 2)?.[0]?.quantity ?? 0
                    };
                }
            },
            dumpster: {
                isRotation: (presta: (CollectService | CCServiceRo) & { isCollect: boolean }) => {
                    if (this.__helpers().isCollect(presta)) {
                        const hasDeposit = presta.informations.collected_items.some(
                            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT
                        );
                        const hasRetrieval = presta.informations.collected_items.some(
                            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL
                        );
                        return hasDeposit && hasRetrieval;
                    }
                    return presta.family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION;
                },
                isLoadWait: (presta: (CollectService | CCServiceRo) & { isCollect: boolean }) => {
                    if (this.__helpers().isCollect(presta)) {
                        return presta.informations.collected_items.some(
                            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT
                        );
                    }
                    return presta.family === CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT;
                },
                isDeposit: (prestra: (CollectService | CCServiceRo) & { isCollect: boolean }) => {
                    if (this.__helpers().isCollect(prestra)) {
                        const hasDeposit = prestra.informations.collected_items.some(
                            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT
                        );
                        const dontHaveRetrieval = prestra.informations.collected_items.every(
                            (item) => item.family !== PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL
                        );
                        return hasDeposit && dontHaveRetrieval;
                    }
                    return prestra.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT;
                },
                isRetrieval: (presta: (CollectService | CCServiceRo) & { isCollect: boolean }) => {
                    if (this.__helpers().isCollect(presta)) {
                        const hasRetrieval = presta.informations.collected_items.some(
                            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL
                        );
                        const dontHaveDeposit = presta.informations.collected_items.every(
                            (item) => item.family !== PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT
                        );
                        return hasRetrieval && dontHaveDeposit;
                    }
                    return presta.family === CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL;
                },
                mapTypeBennes: (
                    product: ProductInCCOrCO
                ): { '8': number; '15': number; '20': number; '30': number; '12': number; '3': number } => {
                    return Object.fromEntries(
                        new Map<number, number>([
                            [3, 0],
                            [8, 0],
                            [12, 0],
                            [15, 0],
                            [20, 0],
                            [30, 0],
                            [product.volume_m3, product.quantity]
                        ])
                    ) as { '8': number; '15': number; '20': number; '30': number; '12': number; '3': number };
                },
                getDumpsterProducts: (products: ProductInCCOrCO[]) => {
                    return products.reduce(
                        (acc, prd) => {
                            if (prd.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT) {
                                acc.deposit = prd;
                            }
                            if (prd.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL) {
                                acc.retrieval = prd;
                            }
                            if (prd.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT) {
                                acc.deposit = prd;
                                acc.retrieval = prd;
                            }
                            return acc;
                        },
                        {
                            deposit   : {},
                            retrieval : {}
                        } as {
                            deposit: ProductInCCOrCO;
                            retrieval: ProductInCCOrCO;
                        }
                    );
                }
            },
            isCollect: (
                evac: (CollectService | CCServiceRo) & { isCollect: boolean }
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
            ): evac is CollectService => {
                return evac.isCollect === true;
            },
            isSplittedCC: (
                evac: CCServiceRo & { isSplitted: boolean }
            ): evac is CCServiceRo & { isSplitted: true } & {
                splitted_informations: {
                    products: ProductInCCOrCO[];
                    status: CC_STATUS;
                    idx: number;
                }[];
            } => {
                return evac.isSplitted === true;
            }
        };
    };

    /**
     * @description
     * Return the value for the key Clients_Commandes
     * @returns Record<string, VRPOrderClientBB|VRPOrderClientDumpster>
     */
    private _commandes = () => {
        const collects = pipe(
            this.data.collects,
            filter((c): c is CollectService => c.category === PlanningShiftStepCategory.SERVICE),
            filter((f) => f.informations.is_splitted === false),
            toArray
        );

        const collects_splitted = pipe(
            this.data.collects,
            filter((c): c is CollectService => c.category === PlanningShiftStepCategory.SERVICE),
            filter((f) => f.informations.is_splitted === true),
            toArray
        );

        const ccs_not_fullfilled = pipe(
            this.data.ccsService,
            filter(
                (cc) =>
                    cc.status !== CC_STATUS.FINISHED &&
                    cc.status !== CC_STATUS.HAZARD &&
                    cc.status !== CC_STATUS.SPLITTED
            ),
            toArray
        );

        /**
         * Get all the splitted CC so we can create fake CC for each splitted part
         */
        const ccs_splitted_tmp = pipe(
            this.data.ccsService,
            filter((cc) => cc.status === CC_STATUS.SPLITTED),
            toArray
        );
        const ccs_splitted_not_fullfilled = ccs_splitted_tmp.reduce((acc, cc) => {
            const notFinishedOrHazard = (cc.splitted_informations ?? []).filter(
                (s) => s.status !== CC_STATUS.FINISHED && s.status !== CC_STATUS.HAZARD
            );
            notFinishedOrHazard.forEach((splitted) => {
                acc.push({
                    ...cc,
                    status                : splitted.status,
                    products              : splitted.products,
                    splitted_informations : [splitted]
                });
            });
            return acc;
        }, [] as CCServiceRo[]);

        const data = [
            ...collects.map((collect) => ({ ...collect, isCollect: true, isSplitted: false })),
            ...collects_splitted.map((collect) => ({ ...collect, isCollect: true, isSplitted: true })),
            ...ccs_not_fullfilled.map((cc) => ({ ...cc, isCollect: false, isSplitted: false })),
            ...ccs_splitted_not_fullfilled.map((cc) => ({ ...cc, isCollect: false, isSplitted: true }))
        ].reduce((acc, evac) => {
            const isCollect = this.__helpers().isCollect(evac);
            const isSplitted = evac.isSplitted;

            const cc = isCollect
                ? pipe(
                      this.data.ccsService,
                      filter((cc) => cc.id === evac.informations.collect_config_id),
                      head
                  )
                : evac;
            if (!cc) {
                throw new BusinessError({
                    httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                    responsability : ErrorResponsability.Client,
                    dataType       : EDataType.PLANNING,
                    code           : 'missing_item',
                    params         : {
                        item: 'cc service'
                    }
                });
            }

            const collect = isCollect ? evac : undefined;
            const customer = pipe(
                this.data.customers,
                filter((c) => c.id === cc.customer_id[0]),
                head
            );
            if (!customer) {
                throw new BusinessError({
                    httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                    responsability : ErrorResponsability.Client,
                    dataType       : EDataType.PLANNING,
                    code           : 'missing_item',
                    params         : {
                        item: 'customer'
                    }
                });
            }

            const getSplittedIndex = (cc: CCServiceRo, collect?: CollectService): number | undefined => {
                if (!isSplitted) {
                    return undefined;
                }
                if (isCollect && collect) {
                    return collect.informations.splitted_idx;
                } else {
                    return cc.splitted_informations?.[0].idx;
                }
            };

            //We check if we have a collector assigned to the cc
            let truck_id: string | undefined = undefined;
            let collector: CollectorRo | undefined = undefined;
            if (cc.collector_id.length > 0) {
                const truckId = this.dto.parameters.find((p) => p.collector_id === cc.collector_id[0])?.truck_id;
                collector = this.data.collectors.find((c) => c.id === cc.collector_id[0]);
                if (truckId) {
                    truck_id = truckId;
                }
            }

            /**
             * Add dto end slot margin to the end of cc slot
             */
            const ccFromDateWithMargin = this._addMinutesBeforeFromDate(
                cc.from_date,
                this.dto.begin_slot_margin_minutes
            );
            const ccToDateWithMargin = this._addMinutesToDate(cc.to_date, this.dto.end_slot_margin_minutes);

            const common: VRPOrderClientCommon = {
                zone                         : collector ? this.assignZoneToCollector(collector)[0] : this.assignZoneToCollect(cc), //If we assigned a collector by default to the cc, we need to make sure that the zone of the cc is included in the zone_chalandise of the collector
                statut_client                : this.__helpers().common.mapStatutClient(customer),
                jour_du_service              : this.day.dayOfWeek,
                types_de_camions_admissibles : collect
                    ? [collect.truck.id]
                    : this.__helpers().common.mapTypesCamionAdmissibles(cc, truck_id),
                date_de_debut_au_plus_tot : this._dateToParisHHmm(collect ? collect.arrived_at : ccFromDateWithMargin),
                date_de_fin_au_plus_tard  : this._dateToParisHHmm(collect ? collect.completed_at : ccToDateWithMargin),
                position_grid_X           : cc.address.coordinates.longitude,
                position_grid_Y           : cc.address.coordinates.latitude,
                nb_heures_depuis_commande : this._minsToDuration(
                    moment.utc().diff(moment.utc(cc.created_at), 'minutes')
                ),
                metadata: {
                    cc_id        : cc.id,
                    is_collect   : evac.isCollect,
                    customer_id  : cc.customer_id[0],
                    collect_id   : collect ? collect.id : undefined,
                    is_splitted  : isSplitted ? true : undefined,
                    splitted_idx : getSplittedIndex(cc, collect)
                }
            };

            if (this.type === EPlanningType.BIG_BAG) {
                const bigbag: VRPOrderClientBB = {
                    ...common,
                    competences: collect
                        ? (Object.keys(VRPBBSkills) as VRPBBSkills[])
                        : this.__helpers().bb.mapCompetences(cc),
                    nb_de_BBB0: this.__helpers().bb.mapBBNumber(
                        collect ? collect.informations.collected_items : cc.products
                    ).b0,
                    nb_de_BBB1: this.__helpers().bb.mapBBNumber(
                        collect ? collect.informations.collected_items : cc.products
                    ).b1,
                    nb_de_BBB2: this.__helpers().bb.mapBBNumber(
                        collect ? collect.informations.collected_items : cc.products
                    ).b2
                };
                const idForAcc = () => {
                    if (isCollect) {
                        return evac.id;
                    } else {
                        if (this.__helpers().isSplittedCC(evac)) {
                            return evac.id + '|' + evac.splitted_informations[0].idx;
                        } else {
                            return evac.id;
                        }
                    }
                };

                acc[idForAcc()] = bigbag;
                return acc;
            } else {
                const price = collect
                    ? Math.ceil(collect.informations.price.base_net.amount / 100)
                    : Math.ceil(cc.price.base_net.amount / 100);

                const commonDumpster = {
                    prix_facture           : price === 0 ? 400 : price,
                    decheterie_successeur  : [],
                    duree_attente          : this._minsToDuration(0),
                    duree_traitement_benne : this._minsToDuration(
                        collect
                            ? moment.utc(collect.completed_at).diff(moment.utc(collect.arrived_at), 'minutes')
                            : cc.execution_time_minutes
                    ),
                    espace_multiple_bennes: 1
                };

                const isDeposit = this.__helpers().dumpster.isDeposit(evac);
                const isRetrieval = this.__helpers().dumpster.isRetrieval(evac);
                const isRotation = this.__helpers().dumpster.isRotation(evac);
                const isLoadWait = this.__helpers().dumpster.isLoadWait(evac);

                if (isDeposit || isRetrieval) {
                    const dumpster: VRPOrderClientDumpster = {
                        ...common,
                        ...commonDumpster,
                        mission             : isDeposit ? VRPDumpsterSkills.BENNE_D : VRPDumpsterSkills.BENNE_E,
                        competences         : isDeposit ? [VRPDumpsterSkills.BENNE_D] : [VRPDumpsterSkills.BENNE_E],
                        commande_successeur : [],
                        type_benne_8m3      : this.__helpers().dumpster.mapTypeBennes(
                            isDeposit
                                ? this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).deposit
                                : this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).retrieval
                        )['8'],
                        type_benne_12m3: this.__helpers().dumpster.mapTypeBennes(
                            isDeposit
                                ? this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).deposit
                                : this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).retrieval
                        )['12'],
                        type_benne_15m3: this.__helpers().dumpster.mapTypeBennes(
                            isDeposit
                                ? this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).deposit
                                : this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).retrieval
                        )['15'],
                        type_benne_20m3: this.__helpers().dumpster.mapTypeBennes(
                            isDeposit
                                ? this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).deposit
                                : this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).retrieval
                        )['20'],
                        type_benne_30m3: this.__helpers().dumpster.mapTypeBennes(
                            isDeposit
                                ? this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).deposit
                                : this.__helpers().dumpster.getDumpsterProducts(
                                      collect ? collect.informations.collected_items : cc.products
                                  ).retrieval
                        )['30']
                    };
                    acc[evac.id] = dumpster;
                    return acc;
                }
                if (isLoadWait) {
                    /**
                     * If it's a load wait, we need to create 2 commandes : one for the deposit, and one for the retrieval.
                     * Do not forget, there is only 1 product for the load wait.
                     */
                    const dumpster1: VRPOrderClientDumpster = {
                        // Deposit
                        ...common,
                        ...commonDumpster,
                        duree_attente: this._minsToDuration(
                            collect
                                ? moment.utc(collect.arrived_at).diff(moment.utc(collect.completed_at), 'minutes')
                                : cc.waiting_time_minutes ?? 45
                        ),
                        duree_traitement_benne: this._minsToDuration(
                            collect
                                ? Math.ceil(
                                      moment.utc(collect.completed_at).diff(moment.utc(collect.arrived_at), 'minutes') /
                                          2
                                  )
                                : Math.ceil(cc.execution_time_minutes / 2)
                        ),
                        mission             : VRPDumpsterSkills.BENNE_D,
                        competences         : [VRPDumpsterSkills.BENNE_D],
                        commande_successeur : [EVrpOrderIdPrefix.RETRIEVAL + '|' + evac.id],
                        type_benne_8m3      : this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['8'],
                        type_benne_12m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['12'],
                        type_benne_15m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['15'],
                        type_benne_20m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['20'],
                        type_benne_30m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['30']
                    };
                    const dumpster2: VRPOrderClientDumpster = {
                        // Retrieval
                        ...common,
                        ...commonDumpster,
                        duree_traitement_benne: this._minsToDuration(
                            collect
                                ? Math.ceil(
                                      moment.utc(collect.completed_at).diff(moment.utc(collect.arrived_at), 'minutes') /
                                          2
                                  )
                                : Math.ceil(cc.execution_time_minutes / 2)
                        ),
                        mission             : VRPDumpsterSkills.BENNE_E,
                        competences         : [VRPDumpsterSkills.BENNE_E],
                        commande_successeur : [],
                        type_benne_8m3      : this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['8'],
                        type_benne_12m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['12'],
                        type_benne_15m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['15'],
                        type_benne_20m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['20'],
                        type_benne_30m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['30']
                    };
                    acc[EVrpOrderIdPrefix.DEPOSIT + '|' + evac.id] = dumpster1;
                    acc[EVrpOrderIdPrefix.RETRIEVAL + '|' + evac.id] = dumpster2;
                    return acc;
                }
                if (isRotation) {
                    const dumpster1: VRPOrderClientDumpster = {
                        // Deposit
                        ...common,
                        ...commonDumpster,
                        duree_traitement_benne: this._minsToDuration(
                            collect
                                ? Math.ceil(
                                      moment.utc(collect.completed_at).diff(moment.utc(collect.arrived_at), 'minutes') /
                                          2
                                  )
                                : Math.ceil(cc.execution_time_minutes / 2)
                        ),
                        mission             : VRPDumpsterSkills.BENNE_D,
                        competences         : [VRPDumpsterSkills.BENNE_D],
                        commande_successeur : [EVrpOrderIdPrefix.RETRIEVAL + '|' + evac.id],
                        type_benne_8m3      : this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['8'],
                        type_benne_12m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['12'],
                        type_benne_15m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['15'],
                        type_benne_20m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['20'],
                        type_benne_30m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).deposit
                        )['30']
                    };
                    const dumpster2: VRPOrderClientDumpster = {
                        // Retrieval
                        ...common,
                        ...commonDumpster,
                        duree_traitement_benne: this._minsToDuration(
                            collect
                                ? Math.ceil(
                                      moment.utc(collect.completed_at).diff(moment.utc(collect.arrived_at), 'minutes') /
                                          2
                                  )
                                : Math.ceil(cc.execution_time_minutes / 2)
                        ),
                        mission             : VRPDumpsterSkills.BENNE_E,
                        competences         : [VRPDumpsterSkills.BENNE_E],
                        commande_successeur : [],
                        type_benne_8m3      : this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['8'],
                        type_benne_12m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['12'],
                        type_benne_15m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['15'],
                        type_benne_20m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['20'],
                        type_benne_30m3: this.__helpers().dumpster.mapTypeBennes(
                            this.__helpers().dumpster.getDumpsterProducts(
                                collect ? collect.informations.collected_items : cc.products
                            ).retrieval
                        )['30']
                    };
                    acc[EVrpOrderIdPrefix.DEPOSIT + '|' + evac.id] = dumpster1;
                    acc[EVrpOrderIdPrefix.RETRIEVAL + '|' + evac.id] = dumpster2;
                    return acc;
                }
            }
            return acc;
        }, {} as Record<string, VRPOrderClientBB | VRPOrderClientDumpster>);

        /**
         * 🚨 BENNE_F 🚨
         * If the planning is of type DUMPSTER
         * We need to add BENNE_F  so the driver don't need to go to it's associated center after quitting
         */
        if (this.type === EPlanningType.DUMPSTER) {
            this.data.collectors.forEach((collector) => {
                const param = this.dto.parameters.find((p) => p.collector_id === collector.id);
                if (!param) return;

                const driverHouseStartCollect = pipe(
                    this.data.collects,
                    filter((c) => c.category === PlanningShiftStepCategory.DRIVER_HOUSE_START),
                    filter((c) => c.collector.id === collector.id),
                    toArray
                );
                const driverHasStarted = driverHouseStartCollect.length > 0;

                const truck = pipe(
                    this.data.trucks,
                    filter((t) => t.id === param.truck_id),
                    head
                );
                if (!truck) {
                    throw new BusinessError({
                        httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                        responsability : ErrorResponsability.Client,
                        dataType       : EDataType.PLANNING,
                        code           : 'missing_item',
                        params         : {
                            item: 'truck'
                        }
                    });
                }

                const landfill = pipe(
                    this.data.landfills,
                    filter((l) => l.id === collector.landfill_id[0]),
                    head
                );

                if (!landfill) {
                    throw new BusinessError({
                        httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                        responsability : ErrorResponsability.Client,
                        dataType       : EDataType.PLANNING,
                        code           : 'missing_item',
                        params         : {
                            item: 'landfill'
                        }
                    });
                }

                const commande: VRPOrderClientDumpster = {
                    zone                         : 'Z0',
                    statut_client                : 3,
                    jour_du_service              : this.day.dayOfWeek,
                    types_de_camions_admissibles : [truck.id],
                    date_de_debut_au_plus_tot    : this._dateToParisHHmm(
                        driverHasStarted
                            ? driverHouseStartCollect[0].arrived_at
                            : moment.utc(param?.shift_config.start_date).subtract(10, 'minutes').toISOString()
                    ),
                    date_de_fin_au_plus_tard: this._dateToParisHHmm(
                        driverHasStarted ? driverHouseStartCollect[0].completed_at : param?.shift_config.start_date
                    ),
                    position_grid_X           : truck.garage_address.coordinates.longitude,
                    position_grid_Y           : truck.garage_address.coordinates.latitude,
                    nb_heures_depuis_commande : this._minsToDuration(9999), //We put that number so it is highest priority but it will be stuck at 72:00
                    prix_facture              : 0,
                    decheterie_successeur     : [],
                    duree_attente             : '00:00',
                    duree_traitement_benne    : '00:10',
                    espace_multiple_bennes    : 1,
                    mission                   : VRPDumpsterSkills.BENNE_F,
                    competences               : [VRPDumpsterSkills.BENNE_F],
                    commande_successeur       : [],
                    type_benne_8m3            : 0,
                    type_benne_12m3           : 0,
                    type_benne_15m3           : 0,
                    type_benne_20m3           : 0,
                    type_benne_30m3           : 0,
                    metadata                  : {
                        cc_id       : '',
                        is_collect  : driverHasStarted,
                        customer_id : '',
                        collect_id  : driverHasStarted ? driverHouseStartCollect[0].id : undefined,
                        is_benne_F  : true
                    }
                };

                data[(driverHasStarted ? driverHouseStartCollect[0].id : collector.id) + '_BENNE_F'] = commande;
            });
        }
        return data;
    };

    /**
     * @description
     * Return the value for the key Chauffeurs
     * @returns Record<string, VRPDriverBB | VRPDriverDumpster>>
     */
    private _chauffeurs = () => {
        return this.dto.parameters.reduce((acc, param) => {
            const collector = pipe(
                this.data.collectors,
                filter((c) => c.id === param.collector_id),
                head
            );
            if (!collector) {
                throw new BusinessError({
                    httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                    responsability : ErrorResponsability.Client,
                    dataType       : EDataType.PLANNING,
                    code           : 'missing_item',
                    params         : {
                        item: 'collector'
                    }
                });
            }
            const truck = pipe(
                this.data.trucks,
                filter((t) => t.id === param.truck_id),
                head
            );
            if (!truck) {
                throw new BusinessError({
                    httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                    responsability : ErrorResponsability.Client,
                    dataType       : EDataType.PLANNING,
                    code           : 'missing_item',
                    params         : {
                        item: 'truck'
                    }
                });
            }

            const collectsFromCollector = pipe(
                this.data.collects,
                filter((c) => c.collector.id === collector.id),
                sort((a, b) => (moment.utc(a.completed_at).isAfter(moment.utc(b.completed_at)) ? 1 : -1)),
                toArray
            );

            const driverHouseStart = pipe(
                collectsFromCollector,
                filter((c) => c.category === PlanningShiftStepCategory.DRIVER_HOUSE_START),
                toArray
            );
            const hasStarted = driverHouseStart.length > 0;

            const driverHouseEnd = pipe(
                collectsFromCollector,
                filter((c) => c.category === PlanningShiftStepCategory.DRIVER_HOUSE_END),
                toArray
            );
            const hasEnded = driverHouseEnd.length > 0;

            /**
             * 🚨 BENNE_F 🚨
             * We need to add if planning type is DUMPSTER
             * We need to add Benne F  so the driver don't need to go to it's associated center after leaving his house.
             * driver_house start -> center -> Benne F on the same address as driver_house_start.
             * Remember that the 2 first nodes are completly fake and ignored by the output mapper.
             * => The benne_F is transformed by the output mapper to a driver_house_start.
             * We either add 2 fake nodes or 3 fakes nodes, depending on if we have a driver_house_start or not.
             */
            const addFakeNodesForBenneF = (): VRPDoneStepsNode[] => {
                const fakeNodes: VRPDoneStepsNode[] = [
                    {
                        node_type                : EVRPNodeType.DRIVER_HOUSE_LOCATION_START,
                        debug_node_id            : '-1',
                        node_name                : collector.id + '_START',
                        remaining_capacity_in_m3 : undefined,
                        arrival_time             : this._dateToParisHHmm(param.shift_config.start_date),
                        departure_time           : this._dateToParisHHmm(param.shift_config.start_date),
                        service_duration         : '00:00',
                        metadata                 : <VRPMetadataDone>{
                            is_fake: true
                        },
                        order_volume_in_m3: '0'
                    },
                    {
                        node_type                : EVRPNodeType.LANDFILL_LOCATION,
                        debug_node_id            : '-1',
                        node_name                : collector.landfill_id[0],
                        remaining_capacity_in_m3 : undefined,
                        arrival_time             : this._dateToParisHHmm(param.shift_config.start_date),
                        departure_time           : this._dateToParisHHmm(param.shift_config.start_date),
                        service_duration         : '00:00',
                        metadata                 : <VRPMetadataDone>{
                            is_fake: true
                        },
                        order_volume_in_m3: '0'
                    }
                ];

                if (!hasStarted) {
                    fakeNodes.push({
                        node_type                : EVRPNodeType.ORDER_LOCATION,
                        debug_node_id            : '-1',
                        node_name                : collector.id + '_BENNE_F',
                        remaining_capacity_in_m3 : undefined,
                        arrival_time             : this._dateToParisHHmm(param.shift_config.start_date),
                        departure_time           : this._dateToParisHHmm(param.shift_config.start_date),
                        service_duration         : '00:10',
                        metadata                 : <VRPMetadataDone>{
                            is_fake      : true,
                            collector_id : collector.id,
                            truck_id     : truck.id,
                            is_benne_F   : true
                        },
                        order_volume_in_m3: '0'
                    });
                }

                return fakeNodes;
            };

            const mapEtapeDejaRealisee = (): Record<string, VRPDoneSteps> => {
                /**
                 * 🚨 BENNE_F 🚨
                 * Added conditions
                 * We filter by type because if it's dumpter, we need to add the benne F
                 * And no matter the case, we need to add 3 nodes.
                 */
                if (collectsFromCollector.length === 0 && this.type === EPlanningType.BIG_BAG) {
                    return {};
                }
                /**
                 * 🚨 BENNE_F 🚨
                 * Added conditions
                 * Same explanation as before.
                 */
                if (collectsFromCollector.length === 1 && hasStarted && this.type === EPlanningType.BIG_BAG) {
                    return {};
                }
                const handleBreakKeys = () => {
                    const breaks = pipe(
                        collectsFromCollector,
                        filter((c): c is CollectBreak => c.category === PlanningShiftStepCategory.BREAK),
                        toArray
                    );

                    // Last collect done
                    const timeCursor: string | undefined =
                        collectsFromCollector[collectsFromCollector.length - 1]?.completed_at;
                    // Last point to refer for time before break
                    const timeCheckpoint: string | undefined =
                        breaks[breaks.length - 1]?.completed_at ?? driverHouseStart[0]?.completed_at;

                    if (!timeCursor || !timeCheckpoint) {
                        return {
                            remaining_time_before_break: '04:20'
                        };
                    }

                    /**
                     * Calculate the time of the break based on timeCheckpoint
                     * Calculate the duration between the timeCursor and the time of the break
                     */
                    const baseDuration = moment
                        .duration(this.vrpConstants.CTG_CHAUFFEUR_MAX_HEURE_GLISSANTE_REGLEMENTATION)
                        .subtract(10, 'minutes');
                    const pauseTime = moment.utc(timeCheckpoint).add(baseDuration).toISOString();
                    const diff = moment.utc(pauseTime).diff(moment.utc(timeCursor), 'minutes');
                    const remaining_time_before_break =
                        diff <= 0 ? '00:01' : moment.utc().set({ hour: 0, minute: diff }).format('HH:mm');

                    return {
                        remaining_time_before_break: remaining_time_before_break
                    };
                };
                const handleNodeTypeAndNameAndMetadata = (collect: CollectRo) => {
                    switch (collect.category) {
                        case PlanningShiftStepCategory.DRIVER_HOUSE_START:
                            return {
                                type     : EVRPNodeType.DRIVER_HOUSE_LOCATION_START,
                                name     : (collect as CollectRo).collector.id + '_START',
                                metadata : <VRPMetadataDone>{
                                    is_collect : true,
                                    collect_id : collect.id,
                                    type       : EVRPNodeType.DRIVER_HOUSE_LOCATION_START
                                }
                            };
                        case PlanningShiftStepCategory.DRIVER_HOUSE_END:
                            return {
                                type     : EVRPNodeType.DRIVER_HOUSE_LOCATION_END,
                                name     : (collect as CollectRo).collector.id + '_END',
                                metadata : <VRPMetadataDone>{
                                    is_collect : true,
                                    collect_id : collect.id,
                                    type       : EVRPNodeType.DRIVER_HOUSE_LOCATION_END
                                }
                            };
                        case PlanningShiftStepCategory.ADMINISTRATIVE:
                            return {
                                type     : EVRPNodeType.ADMINISTRATIVE_LOCATION,
                                name     : collect.id,
                                metadata : <VRPMetadataDone>{
                                    is_collect : true,
                                    collect_id : collect.id,
                                    type       : EVRPNodeType.ADMINISTRATIVE_LOCATION,
                                    cc_id      : (collect as CollectAdministrative).informations.collect_config_id
                                }
                            };
                        case PlanningShiftStepCategory.EMPTYING:
                            return {
                                type     : EVRPNodeType.LANDFILL_LOCATION,
                                name     : (collect as CollectEmptying).informations.landfill_id,
                                metadata : <VRPMetadataDone>{
                                    is_collect  : true,
                                    collect_id  : collect.id,
                                    landfill_id : (
                                        collect as CollectRo & {
                                            informations: { step_category: PlanningShiftStepCategory.EMPTYING };
                                        }
                                    ).informations.landfill_id,
                                    type: EVRPNodeType.LANDFILL_LOCATION
                                }
                            };
                        case PlanningShiftStepCategory.SERVICE:
                            /**
                             * We don't handle the case for LOADWAIT and ROTATION !!
                             */
                            return {
                                type     : EVRPNodeType.ORDER_LOCATION,
                                name     : collect.id,
                                metadata : <VRPMetadataDone>{
                                    is_collect  : true,
                                    collect_id  : collect.id,
                                    cc_id       : (collect as CollectService).informations.collect_config_id,
                                    type        : EVRPNodeType.ORDER_LOCATION,
                                    customer_id : (collect as CollectService).informations.customer_id,
                                    is_splitted : (collect as CollectService).informations.is_splitted
                                        ? true
                                        : undefined,
                                    splitted_idx: (collect as CollectService).informations.splitted_idx
                                }
                            };
                        default: //This case should not happen as we fitered the collects table for this collector by removing breaks.
                            return {
                                type     : EVRPNodeType.BREAK,
                                name     : collect.id,
                                metadata : <VRPMetadataDone>{}
                            };
                    }
                };
                const handleRemainingCapacity = () => {
                    const value =
                        (truck.characteristics.bigbag_capacity ?? 0) -
                        (truck.characteristics.bigbag_current_load_m3 ?? 0);
                    //If dumpster undefined, else, we add a second condition to avoid negative value
                    return this.type == EPlanningType.BIG_BAG ? (value < 0 ? `0` : `${value}`) : undefined;
                };
                const handleServiceDuration = (collect: CollectRo) => {
                    if (
                        collect.category === PlanningShiftStepCategory.DRIVER_HOUSE_START ||
                        collect.category === PlanningShiftStepCategory.DRIVER_HOUSE_END
                    ) {
                        return undefined;
                    }
                    const diffInMinutes = moment
                        .utc(collect.completed_at)
                        .diff(moment.utc(collect.arrived_at), 'minutes');
                    return this._minsToDuration(diffInMinutes);
                };
                const handleOrderVolume = (collect: CollectRo) => {
                    if (collect.category !== PlanningShiftStepCategory.SERVICE) {
                        return undefined;
                    }
                    return (
                        collect as CollectRo & { informations: { step_category: PlanningShiftStepCategory.SERVICE } }
                    ).informations.collected_items
                        .reduce((acc, item) => acc + item.quantity * item.volume_m3, 0)
                        .toString();
                };

                return {
                    useless_key: {
                        debug_driver_id : '-1',
                        ...handleBreakKeys(),
                        nodes           : [
                            /**
                             * 🚨 BENNE_F 🚨
                             */
                            ...(this.type === EPlanningType.DUMPSTER ? addFakeNodesForBenneF() : []),
                            ...pipe(
                                collectsFromCollector,
                                filter((c) => c.category !== PlanningShiftStepCategory.BREAK),
                                sort((a, b) =>
                                    moment.utc(a.completed_at).isAfter(moment.utc(b.completed_at)) ? 1 : -1
                                ),
                                flatMap((collect) => {
                                    const node: VRPDoneStepsNode = {
                                        node_type                : handleNodeTypeAndNameAndMetadata(collect).type,
                                        debug_node_id            : '-1',
                                        node_name                : handleNodeTypeAndNameAndMetadata(collect).name,
                                        remaining_capacity_in_m3 : handleRemainingCapacity(),
                                        arrival_time             : this._dateToParisHHmm(collect.arrived_at),
                                        departure_time           : this._dateToParisHHmm(collect.completed_at),
                                        service_duration         : handleServiceDuration(collect),
                                        metadata                 : handleNodeTypeAndNameAndMetadata(collect).metadata,
                                        order_volume_in_m3       : handleOrderVolume(collect)
                                    };

                                    /**
                                     * 🚨 BENNE_F 🚨
                                     * Handle BENNE_F by overiding the node_type and node_name mostly
                                     * if the type is BIG_BAG, we don't want to override the node_type and node_name
                                     */
                                    if (
                                        this.type === EPlanningType.DUMPSTER &&
                                        node.node_type === EVRPNodeType.DRIVER_HOUSE_LOCATION_START
                                    ) {
                                        return <VRPDoneStepsNode>{
                                            ...node,
                                            node_type : EVRPNodeType.ORDER_LOCATION,
                                            node_name : collect.id + '_BENNE_F',
                                            metadata  : {
                                                truck_id     : collect.truck.id,
                                                collector_id : collect.collector.id,
                                                is_collect   : true,
                                                collect_id   : collect.id,
                                                is_benne_F   : true
                                            }
                                        };
                                    }

                                    if (
                                        this.type === EPlanningType.BIG_BAG ||
                                        node.node_type !== EVRPNodeType.ORDER_LOCATION
                                    )
                                        return node;

                                    const CollectService = { ...collect, isCollect: true } as CollectService & {
                                        isCollect: true;
                                    };
                                    const isDeposit = this.__helpers().dumpster.isDeposit(CollectService);
                                    const isRetrieval = this.__helpers().dumpster.isRetrieval(CollectService);
                                    const isRotation = this.__helpers().dumpster.isRotation(CollectService);
                                    const isLoadWait = this.__helpers().dumpster.isLoadWait(CollectService);

                                    if (isDeposit || isRetrieval) return node;
                                    /**
                                     * A rotation or a load wait is only one collect. But the algo handles them as 2.
                                     * We need to create a deposit followed by a retrieval but we need to be carreful about the time.
                                     * Arrival of collect correspond to arrival of deposit
                                     * Departure of collect correspond to departure of retrieval
                                     * For the departure of deposit and arrival of retrieval we need to create a fake time (middle of the prestation)
                                     * @returns HH:mm
                                     */
                                    const handleMidFakeTime = () => {
                                        const diffInMinutes = moment
                                            .utc(collect.completed_at)
                                            .diff(moment.utc(collect.arrived_at), 'minutes');
                                        const mid = moment
                                            .utc(collect.completed_at)
                                            .add(diffInMinutes / 2, 'minutes')
                                            .toISOString();
                                        return this._dateToParisHHmm(mid);
                                    };
                                    if (isLoadWait || isRotation) {
                                        return [
                                            <VRPDoneStepsNode>{
                                                ...node,
                                                node_name      : EVrpOrderIdPrefix.DEPOSIT + '|' + node.node_name,
                                                departure_time : handleMidFakeTime()
                                            },
                                            <VRPDoneStepsNode>{
                                                ...node,
                                                node_name    : EVrpOrderIdPrefix.RETRIEVAL + '|' + node.node_name,
                                                arrival_time : handleMidFakeTime()
                                            }
                                        ];
                                    }
                                    return node;
                                }),
                                toArray
                            )
                        ]
                    }
                };
            };

            acc[collector.id] = {
                lenteur_chauffeur         : 1 / (collector.efficiency ?? 1),
                [collector.id + '_START'] : {
                    position_grid_X_debut : truck.garage_address.coordinates.longitude,
                    position_grid_Y_debut : truck.garage_address.coordinates.latitude
                },
                [collector.id + '_END']: {
                    position_grid_X_fin : truck.garage_address.coordinates.longitude,
                    position_grid_Y_fin : truck.garage_address.coordinates.latitude
                },
                nom_type_camion                    : truck.id,
                cout_du_chauffeur                  : collector.hourly_rate ? Math.ceil(collector.hourly_rate.net_amount_cents / 100) : 70,
                //if we have the a collect DRIVER_START, we pass the time of the collect otherwise we pass the asked time
                heure_de_debut_journee_au_plus_tot : this._dateToParisHHmm(
                    hasStarted ? driverHouseStart[0].completed_at : param.shift_config.start_date
                ),
                //Same applies for the end of the day even if it has no sense has we don't care about the result of an other calculate with planning.
                heure_de_fin_journee_au_plus_tard: this._dateToParisHHmm(
                    hasEnded ? driverHouseEnd[0].completed_at : param.shift_config.end_date
                ),
                zones_de_chalandise   : this.assignZoneToCollector(collector),
                etapes_deja_realisees : mapEtapeDejaRealisee(),
                metadata              : {
                    collector_id : param.collector_id,
                    is_collect   : false
                },
                competences:
                    this.type === EPlanningType.BIG_BAG
                        ? (Object.keys(VRPBBSkills) as VRPBBSkills[])
                        : (Object.keys(VRPDumpsterSkills) as VRPDumpsterSkills[]),
                ...(this.type === EPlanningType.BIG_BAG
                    ? {
                          volume_occupe_m3: truck.characteristics.bigbag_current_load_m3 ?? 0
                      }
                    : {
                          zone_de_parking: collector.landfill_id[0]
                      })
            } as VRPDriverBB | VRPDriverDumpster;
            return acc;
        }, {} as Record<string, VRPDriverBB | VRPDriverDumpster>);
    };

    /**
     * @description
     * Replace the coordinates of the nodes by the key of each object
     * @returns Record<number, VRPDistanceMatrix>
     */
    private _distanceMatrix = (): Record<number, VRPDistanceMatrix> => {
        if (!this.distanceMatrix) {
            return {};
        }
        let matrix = JSON.stringify(this.distanceMatrix);
        const _escapeRegExp = (string: string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        };
        const replaceAll = (str: string, term: string, replacement: string) => {
            return str.replace(new RegExp(_escapeRegExp(term), 'g'), replacement);
        };

        const clients_commandes = this._commandes();
        const missions_administratives = this._administratives();
        const chauffeurs = this._chauffeurs();
        const decheteries = this._decheteries();

        //clients_commandes
        Object.entries(clients_commandes).forEach(([key, value]) => {
            const { position_grid_X, position_grid_Y } = value as VRPOrderClientBB | VRPOrderClientDumpster;
            const stringToReplace = `{"position_grid_X":${position_grid_X},"position_grid_Y":${position_grid_Y}}`;
            matrix = replaceAll(matrix, stringToReplace, `"${key}"`);
        });

        //missions_administratives
        Object.entries(missions_administratives).forEach(([key, value]) => {
            const { position_grid_X, position_grid_Y } = value as VRPAdminCollect;
            const stringToReplace = `{"position_grid_X":${position_grid_X},"position_grid_Y":${position_grid_Y}}`;
            matrix = replaceAll(matrix, stringToReplace, `"${key}"`);
        });

        //chauffeurs
        Object.entries(chauffeurs).forEach(([key, value]) => {
            const {
                [(key + '_START') as `${string}_START`]: { position_grid_X_debut, position_grid_Y_debut }
            } = value as VRPDriverBB | VRPDriverDumpster;
            const stringToReplace = `{"position_grid_X":${position_grid_X_debut},"position_grid_Y":${position_grid_Y_debut}}`;
            matrix = replaceAll(matrix, stringToReplace, `"${key + '_START'}"`);
        });
        /**
         * So we can handle the start and the end of the driver
         * but it's useless as it start and end at the same place when calculating because we can't know
         * where the driver will be at the end of the day in advance
         */
        const replaceAndAddEnd = () => {
            type ReduceType = { depart: any; arrivee: any; distance: number; duree: string };
            const { lastKey, toDuplicate } = Object.entries(JSON.parse(matrix)).reduce(
                (acc, [key, value]) => {
                    const typedValue = value as ReduceType;
                    if (
                        JSON.stringify(typedValue.depart).includes('_START') ||
                        JSON.stringify(typedValue.arrivee).includes('_START')
                    ) {
                        acc.toDuplicate.push(typedValue);
                    }
                    acc.lastKey = key;
                    return acc;
                },
                { lastKey: '0', toDuplicate: [] as ReduceType[] }
            );

            const tmpStringArray = replaceAll(JSON.stringify(toDuplicate), '_START', '_END');
            const tmpArray = JSON.parse(tmpStringArray);

            const addEnd = tmpArray.reduce((acc: Record<string, any>, el: any, idx: number) => {
                const index = parseInt(lastKey) + idx + 1;
                acc[index] = el;
                return acc;
            }, {} as Record<string, any>);

            const newMatrix = {
                ...JSON.parse(matrix),
                ...addEnd
            };

            matrix = JSON.stringify(newMatrix);
        };
        replaceAndAddEnd();

        //decheteries
        Object.entries(decheteries).forEach(([key, value]) => {
            const { position_grid_X, position_grid_Y } = value as VRPLandfillBB | VRPLandfillDumpster;
            const stringToReplace = `{"position_grid_X":${position_grid_X},"position_grid_Y":${position_grid_Y}}`;
            matrix = replaceAll(matrix, stringToReplace, `"${key}"`);
        });

        const parsedMatrix = JSON.parse(matrix) as Record<
            string,
            {
                duree: string;
                depart: string;
                arrivee: string;
                distance: number;
            }
        >;

        //get all keys
        const allKeys = Object.keys(parsedMatrix).map((el) => Number(el));
        //get bigest number
        const biggestNumber = Math.max(...allKeys);

        //retrieve all object that have 'deposit|' in depart or arrivee key
        const objectsWithDeposit = Object.entries(parsedMatrix)
            .filter(([_, value]) => {
                return (
                    value.depart.includes(EVrpOrderIdPrefix.DEPOSIT) ||
                    value.arrivee.includes(EVrpOrderIdPrefix.DEPOSIT)
                );
            })
            .map(([_, value]) => {
                return value;
            });

        //create the same object but with 'retrieval|' instead of 'deposit|'
        const objectsWithRetrieval = objectsWithDeposit.map((obj) => {
            return {
                ...obj,
                depart  : obj.depart.replace(EVrpOrderIdPrefix.DEPOSIT, EVrpOrderIdPrefix.RETRIEVAL),
                arrivee : obj.arrivee.replace(EVrpOrderIdPrefix.DEPOSIT, EVrpOrderIdPrefix.RETRIEVAL)
            };
        });

        //add the new objects to the matrix
        objectsWithRetrieval.forEach((obj, idx) => {
            parsedMatrix[biggestNumber + idx + 1] = obj;
        });

        return parsedMatrix;
    };

    /**
     * @description
     * Generate the VRP instance to send to the algorithm
     * @returns VRPInstance
     */
    public generateInstance = (): VRPInstance => {
        return {
            ...this._constants(),
            CTG_CAMION_TYPE : this._camions(),
            CTG_COMPETENCES :
                this.type === EPlanningType.BIG_BAG ? Object.values(VRPBBSkills) : Object.values(VRPDumpsterSkills),
            Clients_Commandes        : this._commandes(),
            Missions_Administratives : this._administratives(),
            Chauffeurs               : this._chauffeurs(),
            Decheteries              : this._decheteries(),
            Distance_Matrice         : this._distanceMatrix()
        };
    };
}

// export const sortedCollects = (
//     collects: CollectRo<PlanningShiftStepCategory>[]
// ): Record<PlanningShiftStepCategory, CollectRo<PlanningShiftStepCategory>[]> => {
//     return collects.reduce(
//         (acc, collect) => {
//             switch (collect.category) {
//                 case PlanningShiftStepCategory.SERVICE:
//                     acc[PlanningShiftStepCategory.SERVICE].push(collect);
//                     break;
//                 case PlanningShiftStepCategory.EMPTYING_CENTER:
//                     acc[PlanningShiftStepCategory.EMPTYING_CENTER].push(collect);
//                     break;
//                 case PlanningShiftStepCategory.EMPTYING_LANDFILL:
//                     acc[PlanningShiftStepCategory.EMPTYING_LANDFILL].push(collect);
//                     break;
//                 case PlanningShiftStepCategory.DRIVER_HOUSE_START:
//                     acc[PlanningShiftStepCategory.DRIVER_HOUSE_START].push(collect);
//                     break;
//                 case PlanningShiftStepCategory.DRIVER_HOUSE_END:
//                     acc[PlanningShiftStepCategory.DRIVER_HOUSE_END].push(collect);
//                     break;
//                 case PlanningShiftStepCategory.BREAK:
//                     acc[PlanningShiftStepCategory.BREAK].push(collect);
//                     break;
//                 default:
//                     acc[PlanningShiftStepCategory.ADMINISTRATIVE].push(collect);
//             }
//             return acc;
//         },
//         {
//             [PlanningShiftStepCategory.SERVICE]            : [],
//             [PlanningShiftStepCategory.EMPTYING_CENTER]    : [],
//             [PlanningShiftStepCategory.EMPTYING_LANDFILL]  : [],
//             [PlanningShiftStepCategory.DRIVER_HOUSE_START] : [],
//             [PlanningShiftStepCategory.DRIVER_HOUSE_END]   : [],
//             [PlanningShiftStepCategory.BREAK]              : [],
//             [PlanningShiftStepCategory.ADMINISTRATIVE]     : []
//         } as Record<PlanningShiftStepCategory, CollectRo<PlanningShiftStepCategory>[]>
//     );
// };

// export const mapCCForPlanningRo = (ccs: CollectConfigRo[]): Record<ECollectConfigStatus, CollectConfigRo[]> => {
//     return ccs.reduce(
//         (acc, cc) => {
//             switch (cc.status) {
//                 case ECollectConfigStatus.CANCELED:
//                     acc.CANCELED = [...acc.CANCELED, cc];
//                     break;
//                 case ECollectConfigStatus.FINISHED:
//                     acc.FINISHED = [...acc.FINISHED, cc];
//                     break;
//                 case ECollectConfigStatus.HAZARD:
//                     acc.HAZARD = [...acc.HAZARD, cc];
//                     break;
//                 case ECollectConfigStatus.ORDER_TO_PAY:
//                     acc.ORDER_TO_PAY = [...acc.ORDER_TO_PAY, cc];
//                     break;
//                 case ECollectConfigStatus.PLANNED:
//                     acc.PLANNED = [...acc.PLANNED, cc];
//                     break;
//                 case ECollectConfigStatus.TO_PREPARE:
//                     acc.TO_PREPARE = [...acc.TO_PREPARE, cc];
//                     break;
//                 default:
//                     acc.TO_PLAN = [...acc.TO_PLAN, cc];
//                     break;
//             }
//             return acc;
//         },
//         {
//             [ECollectConfigStatus.CANCELED]             : [],
//             [ECollectConfigStatus.FINISHED]             : [],
//             [ECollectConfigStatus.HAZARD]               : [],
//             [ECollectConfigStatus.ORDER_TO_PAY]         : [],
//             [ECollectConfigStatus.PLANNED]              : [],
//             [ECollectConfigStatus.TO_PREPARE]           : [],
//             [ECollectConfigStatus.TO_PLAN]              : [],
//             [ECollectConfigStatus.WAITING_FOR_APPROVAL] : []
//         } as Record<ECollectConfigStatus, CollectConfigRo[]>
//     );
// };

export const createPlanningCalculateDto = (planning: PlanningRo, truck: TruckRo): PlanningCalculateDto => {
    return {
        day            : planning.day,
        region         : planning.region,
        type           : planning.type,
        general_config : {
            dumpster_deposit_priority : planning.general_config.dumpster_deposit_priority,
            dumpster_removal_priority : planning.general_config.dumpster_removal_priority,
            maximum_volume            : planning.general_config.maximum_volume
        },
        cc_service_to_plan_id        : [],
        cc_administrative_to_plan_id : [],
        parameters                   : [
            {
                planning_id       : planning.id,
                ccsService        : planning.shift.steps_service.map((step) => step.collect_config_id),
                ccsAdministrative : planning.shift.steps_administrative.map((step) => step.collect_config_id),
                shift_config      : {
                    start_date : planning.shift_config.start_date,
                    end_date   : planning.shift_config.end_date
                },
                truck_id     : truck.id,
                collector_id : planning.collector_id[0]
            }
        ],
        calcul_method: planning.calcul_method
    };
};
