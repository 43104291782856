import { Invoice, Invoice_Authz } from '@prisma/client/invoice';

import { CustomerRo, EBillingBundle, EBillingMode, EBillingShipment } from '../customer';
import { IKafkaQuery } from '../kafka';
import { BillingBranch, ISODate, NdlssPrice } from '../misc';
import { EZohoInvoiceStatus, EZohoOrganization } from '../zoho';

export const INVOICE_ACTION_KEY = 'INVOICE:ACTION';

export type InvoicePreGenerateDto = {
    month: number;
    year: number;
};

export type InvoiceGenerateDto = InvoicePreGenerateDto & {
    numberToGenerate: number;
    bundleMode: EBillingBundle;
    billingBranch: BillingBranch;
};

export type InvoicePreGenerate = {
    [EBillingBundle.GLOBAL]: number;
    [EBillingBundle.BY_CONSTRUCTION_SITE]: number;
    [EBillingBundle.BY_COLLECT]: number;
};
export type InvoicePreGenerateRo = {
    [Key in BillingBranch]: InvoicePreGenerate;
};

export type InvoiceEmailDto = {
    month: number;
    year: number;
    billingBranch: BillingBranch;
};

export type InvoiceDebitDto = InvoiceEmailDto;

export interface InvoiceCreateActionDto {
    id?: string;
    id_zoho_invoice: string;
    number_zoho_invoice: string;
    scheduled_payment_date: ISODate;
    paid_payment_date?: ISODate;
    status: EZohoInvoiceStatus;
    price: NdlssPrice;
    billing_organization: EZohoOrganization;
    billing_branch: BillingBranch;
    customer_id: string[];
    construction_site_id?: string[];
    month: number;
    year: number;
    billing_mode: EBillingMode;
    billing_bundle: EBillingBundle;
    billing_shipment: EBillingShipment;
    payment_time: number;
    appears_on_list?: boolean;
}

export type InvoiceEditFromZohoInvoiceIdDto = {
    id_zoho_invoice?: string;
    number_zoho_invoice?: string;
    status?: EZohoInvoiceStatus;
    scheduled_payment_date?: string;
    paid_payment_date?: string;
    price?: NdlssPrice;
    billing_branch?: BillingBranch;
};

export type InvoiceEditActionDto = {
    id_gocardless_payment?: string | null;
    price?: NdlssPrice;
};

export interface InvoiceRo {
    id: string;

    billing_mode: EBillingMode;
    billing_bundle: EBillingBundle;
    billing_shipment: EBillingShipment;
    payment_time: number;
    billing_organization: EZohoOrganization; // for legacy invoices only (to know where invoice is, on zoho 1 or zoho 2)
    billing_branch: BillingBranch; // for other invoices (from zoho1 > BB | zoho2 > BN refactor)
    id_zoho_invoice: string;
    number_zoho_invoice: string;
    scheduled_payment_date: ISODate;
    paid_payment_date: ISODate;
    status: EZohoInvoiceStatus;
    price: NdlssPrice;
    year: number;
    month: number;
    customer_id: string[];
    construction_site_id: string[];
    id_gocardless_payment: string | null;

    archived: boolean;
    created_by: string;
    created_at: Date;
    updated_at: Date;
}

export type InvoicePrisma = Invoice & { authorization: Invoice_Authz };

export interface InvoiceQuery extends IKafkaQuery {
    month?: number;
    year?: number;
    min_date?: ISODate;
    max_date?: ISODate;
    billing_mode?: EBillingMode;
    billing_shipment?: EBillingShipment;
    billing_organization?: EZohoOrganization;
    billing_branch?: BillingBranch;
    billing_bundle?: EBillingBundle;
    id_zoho_invoice?: string;
    customer_id?: string;
    construction_site_id?: string;
    status?: EZohoInvoiceStatus;
    statuses?: EZohoInvoiceStatus[];
    debited?: boolean;
}

export type InvoiceEmailRo = {
    invoices: InvoiceRo[];
    customersWithoutEmail: CustomerRo[];
};

export type InvoiceDownloadRo = {
    name: string;
    document: Buffer;
};
