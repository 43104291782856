import { CollectAnomaliesForm, CollectAnomaliesFormProps } from './Anomalies';
import { CollectDocuments, CollectDocumentsProps } from './Documents';
import { CollectInfo, CollectInfoProps } from './Info';
import { CollectLandfill, CollectLandfillProps } from './Landfill';
import { CollectSMSInformationModal, CollectSMSInformationModalProps } from './Notification';

const Modules = {
    Info: (props: CollectInfoProps) => {
        return <CollectInfo {...props} />;
    },
    Landfill: (props: CollectLandfillProps) => {
        return <CollectLandfill {...props} />;
    },
    Documents: (props: CollectDocumentsProps) => {
        return <CollectDocuments {...props} />;
    },
    Notification: (props: CollectSMSInformationModalProps) => {
        return <CollectSMSInformationModal {...props} />;
    },
    Anomalies: (props: CollectAnomaliesFormProps) => {
        return <CollectAnomaliesForm {...props} />;
    }
};

export default Modules;
