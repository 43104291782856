import { CCServiceRoFront, CollectRoFront, ProductInCCOrCO } from '@bbng/util/types';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { useMemo } from 'react';
import { mapCollectConfigFamilyText, mapTrashTypetext } from '../../common/enumMapper';
import { PriceContainer } from '../../modules/order/Overview';
import {
    FlexContainer,
    CollectedItemsContainer,
    PriceWrapper,
    ItemWrapper,
    ItemQuantityWrapper,
    ItemTitleWrapper,
    ItemContentWrapper,
    ItemDescriptionWrapper,
    ItemDifferenceWrapper,
    ItemDifferenceIcon,
    ItemDifferenceText
} from './style';

type MergedProduct = {
    ordered: ProductInCCOrCO | undefined;
    collected: ProductInCCOrCO | undefined;
};

export type CollectProductsProps = {
    collect: CollectRoFront | undefined;
    cc: CCServiceRoFront | undefined;
};

export const CollectProducts: React.FC<CollectProductsProps> = ({ collect, cc }): JSX.Element | null => {
    const mergedProducts: MergedProduct[] = useMemo(() => {
        if (!cc || !collect || !('collected_items' in collect.informations)) {
            return [];
        }

        const orderedProducts = cc.products;
        const collectedProducts = collect.informations.collected_items;

        const productIds = new Set([...orderedProducts, ...collectedProducts].map((p) => p.id));
        return Array.from(productIds).map((id) => {
            const orderedProduct = orderedProducts.find((p) => p.id === id);
            const collectedProduct = collectedProducts.find((p) => p.id === id);
            return {
                ordered   : orderedProduct,
                collected : collectedProduct
            };
        });
    }, [cc, collect]);

    if (!cc || !collect || !('collected_items' in collect.informations)) {
        return null;
    }

    return (
        <FlexContainer>
            <CollectedItemsContainer>
                <DataTable
                    value={mergedProducts}
                    emptyMessage="Aucune prestation"
                    headerColumnGroup={
                        <ColumnGroup>
                            <Row>
                                <Column header="Prévu" align="center" />
                                <Column header="Différence" align="center" />
                                <Column header="Réalisé" align="center" />
                            </Row>
                        </ColumnGroup>
                    }
                >
                    <Column body={ItemTemplate(false)} />
                    <Column body={DifferenceTemplate} />
                    <Column body={ItemTemplate(true)} />
                </DataTable>
                <PriceWrapper>
                    {cc?.price && PriceContainer(cc?.price)}
                    {collect.informations.price && PriceContainer(collect.informations.price)}
                </PriceWrapper>
            </CollectedItemsContainer>
        </FlexContainer>
    );
};

export const ItemTemplate = (collected: boolean) => (rowData: MergedProduct) => {
    const data = collected ? rowData.collected : rowData.ordered;
    if (data) {
        return (
            <ItemWrapper>
                <ItemQuantityWrapper>{data.quantity}</ItemQuantityWrapper>
                <ItemContentWrapper>
                    <ItemTitleWrapper>
                        {mapCollectConfigFamilyText(data.family, true)} - {mapTrashTypetext(data.trash_type)} -{' '}
                        {data.volume_m3}m³
                    </ItemTitleWrapper>
                    <ItemDescriptionWrapper>{data.name}</ItemDescriptionWrapper>
                </ItemContentWrapper>
            </ItemWrapper>
        );
    }
    return null;
};

export const DifferenceTemplate = (rowData: MergedProduct) => {
    const diff = (rowData.collected?.quantity ?? 0) - (rowData.ordered?.quantity ?? 0);
    const diffText = diff > 0 ? `+${diff}` : diff < 0 ? diff : '';

    const className = diff > 0 ? 'pi pi-arrow-up-right' : diff < 0 ? 'pi pi-arrow-down-right' : 'pi pi-pause';
    const color = diff > 0 ? 'green' : diff < 0 ? 'red' : 'blue';

    return (
        <ItemDifferenceWrapper>
            <ItemDifferenceIcon className={className} color={color} rotate={diff === 0} />
            <ItemDifferenceText color={color}>{diffText}</ItemDifferenceText>
        </ItemDifferenceWrapper>
    );
};
