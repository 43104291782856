import Joi from 'joi';

import { CC_STATUS } from '../collect-config';
import { DocumentCreateDtoSchema } from '../document/document.schema';
import { generateRelationsSchema } from '../dto';
import { PhoneE164Schema } from '../misc.schema';
import { EPlanningRegion, EPlanningType, PlanningShiftStepCategory, PlanningStepCommonDtoSchema } from '../planning';
import { CollectService, COLLECT_ANOMALIES, ECollectHazardReason } from './collect';

const LinesSchema = Joi.object({
    id       : Joi.string().required(),
    quantity : Joi.number().positive().required()
});

export const CollectCreateDtoSchema = Joi.object({
    day    : Joi.date().iso().required(),
    region : Joi.string()
        .valid(...Object.keys(EPlanningRegion))
        .required(),
    type: Joi.string()
        .valid(...Object.keys(EPlanningType))
        .required(),

    status: Joi.string()
        .valid(...Object.keys(CC_STATUS))
        .required(),
    was_planned: Joi.boolean().required(),

    planning_id  : Joi.string().required(),
    truck_id     : Joi.string().required(),
    collector_id : Joi.string().required(),

    hazard_reason: Joi.when('status', {
        is   : CC_STATUS.HAZARD,
        then : Joi.string()
            .valid(...Object.keys(ECollectHazardReason))
            .required(),
        otherwise: Joi.forbidden()
    }),
    hazard_comment: Joi.when('hazard_reason', {
        is        : Joi.string().valid(...Object.keys(ECollectHazardReason)),
        then      : Joi.string().allow(''),
        otherwise : Joi.forbidden()
    }).when('hazard_reason', {
        is        : ECollectHazardReason.OTHER,
        then      : Joi.string().min(10).required(),
        otherwise : Joi.string()
    }),

    delivery_number: Joi.when('type', {
        is   : Joi.string().valid(EPlanningType.DUMPSTER),
        then : Joi.when('category', {
            is        : Joi.string().valid(PlanningShiftStepCategory.SERVICE),
            then      : Joi.string().allow('', null),
            otherwise : Joi.forbidden()
        }),
        otherwise: Joi.forbidden()
    }),

    category: Joi.string()
        .valid(...Object.keys(PlanningShiftStepCategory))
        .required(),

    arrived_at         : Joi.date().iso().required(),
    completed_at       : Joi.date().iso().required(),
    documents          : Joi.array().items(DocumentCreateDtoSchema),
    do_not_recalculate : Joi.boolean(),
    notifyBySMS        : Joi.boolean().optional(),
    contactToNotify    : Joi.array().items(PhoneE164Schema).optional()
});

export const CollectCreateServiceSuccessDtoSchema = CollectCreateDtoSchema.concat(
    Joi.object({
        planning_id  : Joi.string().optional(),
        truck_id     : Joi.string().optional(),
        collector_id : Joi.string().optional(),
        presta_id    : Joi.string().optional(),

        collected_items : Joi.array().items(LinesSchema),
        signed_by       : Joi.string(),
        dumpster_weight : Joi.when('type', {
            is        : EPlanningType.DUMPSTER,
            then      : Joi.number(),
            otherwise : Joi.forbidden()
        }),
        was_planned       : Joi.boolean().valid(true).required(),
        step_service      : PlanningStepCommonDtoSchema.required(),
        is_splitted       : Joi.boolean().required(),
        collect_config_id : Joi.string().required()
    })
);

export const CollectCreateServiceHazardDtoSchema = CollectCreateDtoSchema.concat(
    Joi.object({
        planning_id       : Joi.string().optional(),
        truck_id          : Joi.string().optional(),
        collector_id      : Joi.string().optional(),
        presta_id         : Joi.string().optional(),
        was_planned       : Joi.boolean().valid(true).required(),
        step_service      : PlanningStepCommonDtoSchema.required(),
        collected_items   : Joi.array().items(LinesSchema),
        collect_config_id : Joi.string().required(),
        is_splitted       : Joi.boolean().required()
    })
);

const CollectAnomaliesNumberEntrySchema = Joi.object({
    value      : Joi.number().positive().required(),
    confidence : Joi.number().min(0).max(100).required()
});
const CollectAnomaliesBooleanEntrySchema = Joi.object({
    value      : Joi.boolean().allow(true).required(),
    confidence : Joi.number().min(0).max(100).required()
});

export const CollectEditServiceDtoSchema = Joi.object({
    collected_items: Joi.when('status', {
        is        : CC_STATUS.FINISHED,
        then      : Joi.array().items(LinesSchema).min(1).required(),
        otherwise : Joi.array().items(LinesSchema).optional()
    }),
    dumpster_weight : Joi.number().min(0).optional(),
    delivery_number : Joi.string().optional(),
    status          : Joi.string()
        .valid(...Object.keys(CC_STATUS))
        .required(),
    hazard_reason: Joi.when('status', {
        is   : CC_STATUS.HAZARD,
        then : Joi.string()
            .valid(...Object.keys(ECollectHazardReason))
            .required(),
        otherwise: Joi.forbidden()
    }),
    hazard_comment: Joi.when('hazard_reason', {
        is        : Joi.string().valid(...Object.keys(ECollectHazardReason)),
        then      : Joi.string().allow(''),
        otherwise : Joi.forbidden()
    }).when('hazard_reason', {
        is        : ECollectHazardReason.OTHER,
        then      : Joi.string().min(10).required(),
        otherwise : Joi.string()
    }),
    documents : Joi.array().items(DocumentCreateDtoSchema),
    anomalies : Joi.object({
        [COLLECT_ANOMALIES.DIFFER_FROM_ORDER] : CollectAnomaliesBooleanEntrySchema,
        [COLLECT_ANOMALIES.FAILED_SORTING]    : CollectAnomaliesBooleanEntrySchema,
        [COLLECT_ANOMALIES.MISSING_CONTAINER] : CollectAnomaliesBooleanEntrySchema,
        // [COLLECT_ANOMALIES.MISSING_DEPOSIT_CONTAINER]   : CollectAnomaliesBooleanEntrySchema,
        // [COLLECT_ANOMALIES.MISSING_RETRIEVAL_CONTAINER] : CollectAnomaliesBooleanEntrySchema,
        [COLLECT_ANOMALIES.OVERFLOW_VOLUME]   : CollectAnomaliesNumberEntrySchema
    })
}).concat(generateRelationsSchema<CollectService>('document_id'));

export const CollectCreateServiceNotPlannedDtoSchema = CollectCreateServiceSuccessDtoSchema.concat(
    Joi.object({
        was_planned  : Joi.boolean().valid(false).required(),
        splitted_idx : Joi.when('is_splitted', {
            is        : true,
            then      : Joi.number().required(),
            otherwise : Joi.forbidden()
        }),
        landfill_id  : Joi.string().optional(),
        step_service : Joi.forbidden()
    })
);

export const CollectCreateAdministrativeDtoSchema = CollectCreateDtoSchema.concat(
    Joi.object({
        was_planned         : Joi.boolean().valid(true).required(),
        step_administrative : PlanningStepCommonDtoSchema.required(),
        collect_config_id   : Joi.string().required()
    })
);

export const CollectCreateEmptyingHazardDtoSchema = CollectCreateDtoSchema.concat(
    Joi.object({ when_planning: Joi.forbidden() })
).concat(
    Joi.object({
        step_emptying : PlanningStepCommonDtoSchema.required(),
        landfill_id   : Joi.string().required()
    })
);

export const CollectCreateEmptyingDtoSchema = CollectCreateDtoSchema.concat(
    Joi.object({
        step_emptying: Joi.when('was_planned', {
            is        : false,
            then      : Joi.forbidden(),
            otherwise : PlanningStepCommonDtoSchema.required()
        }),
        landfill_number : Joi.string().required(),
        landfill_id     : Joi.string().required(),
        weight          : Joi.number().min(0).optional()
    })
);

export const CollectCreateBreakDtoSchema = CollectCreateDtoSchema.concat(
    Joi.object({
        duration   : Joi.number().valid(15, 30, 45).required(),
        step_break : Joi.when('was_planned', {
            is        : true,
            then      : PlanningStepCommonDtoSchema.required(),
            otherwise : Joi.forbidden()
        })
    })
);

export const CollectCreateDriverDtoSchema = CollectCreateDtoSchema.concat(
    Joi.object({
        origin      : Joi.string().valid('start', 'end').required(),
        was_planned : Joi.boolean().valid(true).required(),
        step_driver : PlanningStepCommonDtoSchema
    })
);
