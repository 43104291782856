import {
    Construction_Site,
    Construction_Site_Authz,
    Construction_Site_History
} from '@prisma/client/construction-site';

import { AddressCreateDto, AddressEditDto, AddressRo } from '../address';
import { CollectorRo } from '../collector';
import { CustomerRo } from '../customer';
import { DocumentCreateDto, DocumentRo } from '../document';
import { IKafkaQuery } from '../kafka';
import { CREATED_FROM, IContact, ISODate } from '../misc';
import { PrestaRo } from '../presta';

export type ConstructionSitePrisma = Construction_Site & {
    authorization: Construction_Site_Authz;
    history: Construction_Site_History;
};

export interface ConstructionSiteRo {
    id: string;
    label: string;
    address: AddressRo;
    additional_info: string;
    characteristics: Array<ConstructionSiteCharacteristic>;

    hubspot_id: string | null;

    log_contact?: Array<IContact>;
    construction_site_contact?: Array<IContact>;

    order_sheet_number?: string;

    customer_id: Array<string>;

    document_id: Array<string>;

    presta_id: Array<string>;

    list_collector_type: CollectorListType;
    list_collector_id: string[];

    archived: boolean;
    created_at: ISODate;
    created_from: CREATED_FROM;
    updated_at: ISODate;
    created_by: string;
    '@onCascade'?: Array<string>;
}

export type ConstructionSiteWithDocuments = Omit<
    ConstructionSiteRo,
    'document_id' | 'customer_id' | 'list_collector_id' | 'presta_id'
> & {
    document_id: DocumentRo[];
    customer_id: CustomerRo[];
    list_collector_id: CollectorRo[];
    presta_id: PrestaRo[];
};

export interface ConstructionSiteCreateDto {
    label: string;
    address: AddressCreateDto;
    characteristics: Array<ConstructionSiteCharacteristic>;
    additional_info?: string;
    log_contact?: Array<IContact>;
    order_sheet_number?: string;
    construction_site_contact?: Array<IContact>;
    customer_id: Array<string>;
    document_id?: DocumentCreateDto[];
    presta_id?: Array<string>;
    created_from?: CREATED_FROM;
    list_collector_type?: CollectorListType;
    list_collector_id?: string[];
}
export type ConstructionSiteCreateActionDto = Omit<ConstructionSiteCreateDto, 'document_id'> & {
    id?: string;
    archived?: boolean;
    document_id?: Array<string>;
};

export type ConstructionSiteEditDto = Partial<Omit<ConstructionSiteCreateDto, 'document_id'>> & {
    label?: string;
    log_contact?: Array<IContact>;
    additional_info?: string;
    construction_site_contact?: Array<IContact>;
    hubspot_id?: string | null;
    order_sheet_number?: string;
    document_id?: {
        add?: DocumentCreateDto[];
        remove?: string;
    };
    presta_id?: Array<string>;
    address?: AddressEditDto;
    characteristics?: Array<ConstructionSiteCharacteristic>;
    list_collector_type?: CollectorListType;
    list_collector_id?: string[];
};

export type ConstructionSiteEditActionDto = Partial<
    Omit<ConstructionSiteEditDto, 'document_id'> & {
        archived?: boolean;
        document_id?: Array<string>;
    }
>;

export enum ConstructionSiteCharacteristic {
    LEED = 'LEED',
    NARROW_STREET = 'NARROW_STREET'
}

export interface ConstructionSiteQuery extends IKafkaQuery {
    customer_id?: string;
    presta_id?: string;
    created_from?: CREATED_FROM;
    formatted_name?: string; // only for check existing endpoint
    collectorAccessListType?: CollectorListType;
    collectorIdOnList?: string;
}

export enum CollectorListType {
    BLACK_LIST = 'BLACK_LIST',
    WHITE_LIST = 'WHITE_LIST'
}
