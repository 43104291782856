import { DtoValidationError } from '@bbng/util/error';
import {
    CCServiceRo,
    CCServiceRoFront,
    CCServiceUpdateDto,
    CC_FAMILY,
    CollectorRo,
    ECollectCharacteristic,
    ECollectorSkill,
    EDataType,
    ErrorResponsability,
    ETruckType,
    ISODate,
    MAX_DAY_LIMIT_FOR_ORDER_CANCELLATION,
    MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION,
    ProductInCCOrCO,
    ProductRo,
    PRODUCT_DUMPSTER_TYPE,
    PRODUCT_FAMILY,
    TruckRo
} from '@bbng/util/types';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { getPreviousBusinessDay } from './dates';

/**
 * Check if a sensible field is updated: i.e a field that can change the planning.
 * @param {CCServiceUpdateDto} dto
 *
 * @returns {boolean}
 */
export const isSensibleFieldsUpdated = (dto: CCServiceUpdateDto): boolean => {
    return (
        dto?.characteristics !== undefined ||
        dto?.from_date !== undefined ||
        dto?.to_date !== undefined ||
        dto.already_available_date !== undefined ||
        dto?.waiting_time_minutes !== undefined ||
        dto?.lines !== undefined ||
        dto?.execution_time_minutes !== undefined
    );
};

/**
 * Check if a given collect-config (from its date) cancelation implies fees.
 * @param {ISODate} cc_date
 *
 * @returns {boolean} true if it's too late to cancel the collect-config without fees, false otherwise.
 */
export const checkIsLateCancelation = (cc_date: ISODate): boolean => {
    /**
     * Get the limit date for order cancellation, which is MAX_DAY_LIMIT_FOR_ORDER_CANCELLATION business days before the cc_date.
     */
    let limitDate: ISODate | undefined = undefined;
    for (let i = 0; i < MAX_DAY_LIMIT_FOR_ORDER_CANCELLATION; i++) {
        limitDate = getPreviousBusinessDay(cc_date);
    }

    /**
     * If the limit date is in the future, the order can be cancelled without fees.
     */
    const limit = moment(limitDate).set(MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION);
    const now = moment();
    return now.isAfter(limit);
};

/**
 * Find first deposit product among products list.
 * @param {ProductRo[]} products
 *
 * @returns {ProductRo | undefined}
 */
export const findProductWitDeposit = (products: ProductRo[]): ProductRo | undefined => {
    const product = products.find((p) => p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT);
    return product;
};

/**
 * Find first retrieval product among products list.
 * @param {ProductRo[]} products
 *
 * @returns {ProductRo | undefined}
 */
export const findProductWithRetrieval = (products: ProductRo[]): ProductRo | undefined => {
    const product = products.find((p) => p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL);
    return product;
};

/**
 * Find all retrieval products among products list.
 * @param {ProductRo[]} products
 *
 * @returns {ProductRo[]}
 */
export const findAllProductsWithRetrieval = (products: ProductRo[]): ProductRo[] => {
    const filteredProducts = products.filter((p) => p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL);
    return filteredProducts;
};

/**
 * Check deposit and retrieval are compatible.
 * @param {ProductInCCOrCO | ProductRo} deposit
 * @param {ProductInCCOrCO | ProductRo} retrieval
 *
 * @returns {boolean}
 */
export const depositAndRetrievalCompatible = (
    deposit: ProductInCCOrCO | ProductRo,
    retrieval: ProductInCCOrCO | ProductRo
): boolean => {
    if (deposit.volume_m3 !== retrieval.volume_m3) return false;
    if (deposit.dumpster_type && retrieval.dumpster_type) {
        if (deposit.dumpster_type?.every((type) => !retrieval.dumpster_type?.includes(type))) return false;
    }
    return true;
};

/**
 * Check if a given cc is compatible with associated truck & collector (narrow street, ampliroll, chain...)
 * @param {CCServiceRo} cc
 * @param {TruckRo} truck
 * @param {CollectorRo} collector
 *
 * @returns {boolean} will throw if not compatible
 */
export const checkCCTruckCollectorCompatibility = ({
    cc,
    truck,
    collector,
    throwOnError = true
}: {
    cc: CCServiceRo | CCServiceRoFront;
    truck?: TruckRo;
    collector?: CollectorRo;
    throwOnError: boolean;
}): boolean => {
    if (cc.characteristics?.length === 0) return true;
    const ccIsNarrowStreet = cc.characteristics?.includes(ECollectCharacteristic.NARROW_STREET);
    const ccIsAmpliroll = cc.characteristics?.includes(ECollectCharacteristic.DUMPSTER_AMPLIROLL);
    const ccIsChain = cc.characteristics?.includes(ECollectCharacteristic.DUMPSTER_CHAIN);
    if (ccIsNarrowStreet && !truck?.type.includes(ETruckType.NARROW_STREET)) {
        if (throwOnError) {
            throw new DtoValidationError({
                httpCode       : StatusCodes.BAD_REQUEST,
                responsability : ErrorResponsability.Client,
                dataType       : EDataType.COLLECT_CONFIG,
                code           : 'ccNotCompatibleWithTruckCollector',
                params         : {
                    order_number : cc.order_number,
                    cc_number    : cc.number
                }
            });
        } else {
            return false;
        }
    }
    if (
        ccIsAmpliroll &&
        (!truck?.type.includes(ETruckType.DUMPSTER_AMPLIROLL) ||
            !collector?.skills?.includes(ECollectorSkill.DUMPSTER_AMPLIROLL))
    ) {
        if (throwOnError) {
            throw new DtoValidationError({
                httpCode       : StatusCodes.BAD_REQUEST,
                responsability : ErrorResponsability.Client,
                dataType       : EDataType.COLLECT_CONFIG,
                code           : 'ccNotCompatibleWithTruckCollector',
                params         : {
                    order_number : cc.order_number,
                    cc_number    : cc.number
                }
            });
        } else {
            return false;
        }
    }
    if (
        ccIsChain &&
        (!truck?.type.includes(ETruckType.DUMPSTER_CHAIN) ||
            !collector?.skills?.includes(ECollectorSkill.DUMPSTER_CHAIN))
    ) {
        if (throwOnError) {
            throw new DtoValidationError({
                httpCode       : StatusCodes.BAD_REQUEST,
                responsability : ErrorResponsability.Client,
                dataType       : EDataType.COLLECT_CONFIG,
                code           : 'ccNotCompatibleWithTruckCollector',
                params         : {
                    order_number : cc.order_number,
                    cc_number    : cc.number
                }
            });
        } else {
            return false;
        }
    }
    return true;
};

/**
 * Inject appropriate dumpster type in characteristics if family is DUMPSTER
 * @param {CC_FAMILY} family
 * @param {ECollectCharacteristic[]} characteristics
 * @param {ProductInCCOrCO[]} products
 *
 * @returns {ECollectCharacteristic[]}
 */
export const handleDumpsterTypeInCharacteristics = ({
    family,
    characteristics,
    products
}: {
    family: CC_FAMILY;
    characteristics: ECollectCharacteristic[];
    products: ProductInCCOrCO[];
}): ECollectCharacteristic[] => {
    /**
     * Remove dumpster related characteristics as we will add them later.
     */
    const newCharacteristics = characteristics.filter(
        (char) =>
            [ECollectCharacteristic.DUMPSTER_AMPLIROLL, ECollectCharacteristic.DUMPSTER_CHAIN].includes(char) === false
    );
    if (family.includes('DUMPSTER')) {
        const sharedDumpsterTypes = products.reduce((acc, product) => {
            if (!product.dumpster_type) return acc;
            if (acc.length === 0) return product.dumpster_type;
            return acc.filter((type) => product.dumpster_type.includes(type));
        }, [] as PRODUCT_DUMPSTER_TYPE[]);
        if (sharedDumpsterTypes.length === 1) {
            newCharacteristics.push(sharedDumpsterTypes[0] as ECollectCharacteristic);
        } else {
            /**
             * This case should not happen but if there are none or several dumpster types, we add the AMPLIROLL characteristic by default.
             * There could have no dumpster type for legacy data whose product has no dumpster_type field yet.
             */
            newCharacteristics.push(ECollectCharacteristic.DUMPSTER_AMPLIROLL);
        }
    }

    return newCharacteristics;
}

/*
 * Check if orderer and collected products differ.
 * @param {ProductInCCOrCO[]} collectedProducts
 * @param {ProductInCCOrCO[]} orderedProducts
 * @returns {boolean} True if difference.
 */
export const orderedAndCollectedProductsDiffer = ({
    collectedProducts,
    orderedProducts
}: {
    orderedProducts: ProductInCCOrCO[];
    collectedProducts: ProductInCCOrCO[];
}): boolean => {
    if (orderedProducts.length !== collectedProducts.length) return true;

    return collectedProducts.some((collectedProduct) => {
        const orderedProduct = orderedProducts.find((p) => p.id === collectedProduct.id);
        if (!orderedProduct || orderedProduct.quantity !== collectedProduct.quantity) return true;
        return false;
    });
};
