import equal from 'fast-deep-equal/react';
import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import {
    CollectorRo,
    ConstructionSiteRo,
    ConstructionSiteWithDocuments,
    CustomerRo,
    DocumentCreateDto,
    DocumentRo,
    EDocumentType,
    PrestaRo
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { ActionsFormContainer, TDocument, mapFrontDocumentToDocumentDto, uploadFiles } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { toast } from '../../components/Toast';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import { Address, AddressState } from '../../modules/common/Address';
import ConstructionSite from '../../modules/construction-site';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    ConstructionSiteFormErrorState,
    ConstructionSiteFormState,
    ConstructionSiteModulesErrorStates,
    ConstructionSiteModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData,
    useHandleExisting
} from './helpers';
import { PageForm, PageFormLine, SubmitButton } from './style';

type ConstructionSiteFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const ConstructionSiteForm: React.FC<ConstructionSiteFormProps> = ({
    edit = false,
    readOnly = false
}: ConstructionSiteFormProps): JSX.Element => {
    const { query } = useQuery<{ on_success?: 'close' }>();
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: ConstructionSiteFormState; db: ConstructionSiteRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<ConstructionSiteFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<ConstructionSiteFormState>['onSubmit']>(
        async (data: ConstructionSiteFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            const documents = {
                add    : [] as DocumentCreateDto[],
                remove : [] as string[]
            };
            if (data?.uploads?.documents && data?.uploads?.documents?.length > 0) {
                const files: TDocument[] = data.uploads.documents
                    .filter((f) => f.type === 'local')
                    .map((doc: TDocument) => {
                        if (doc.local) {
                            return {
                                local: {
                                    ...doc.local,
                                    type: EDocumentType.ORDER_SHEET
                                },
                                type: 'local'
                            };
                        }
                        return {
                            ...doc
                        };
                    });
                const document_add = await uploadFiles(files, `construction-site`);
                if (!documents) {
                    toast({
                        severity : 'error',
                        summary  : 'Téléversement des documents échoué',
                        detail   : 'Le téléversement des documents a échoué, veuillez réessayer.'
                    });
                    return;
                }
                documents.add = document_add?.[0]
                    ? [mapFrontDocumentToDocumentDto(document_add[0])]
                    : ([] as DocumentCreateDto[]);
            } else if (
                data?.uploads?.documents.length === 0 &&
                apiState?.db?.document_id &&
                apiState?.db?.document_id?.length > 0
            ) {
                documents.remove = [apiState?.db?.document_id[0]];
            }

            if (data.contacts.construction_site.length === 0 && data.contacts.logistic.length === 0) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : "Aucun contact n'a été renseigné."
                });
                return;
            }

            const dataWithUploadedDocuments: ConstructionSiteFormState = {
                ...data,
                documents: {
                    documents          : documents.add ?? [],
                    order_sheet_number : data?.documents?.order_sheet_number ?? ''
                },
                document_removed: documents.remove ?? []
            };

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.constructionSiteEdit(dataId) : urlApiBuilder.constructionSiteCreate();
            const body = edit
                ? mapStateToApiEditData(dataWithUploadedDocuments, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(dataWithUploadedDocuments);
            setLoading(true);
            setOnRequestType('create');

            const response = await bbngRequest<ConstructionSiteRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);

            if (query?.on_success === 'close') {
                window.close();
            }
            if (response.success) {
                navigate(urlBuilder.constructionSiteList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<ConstructionSiteFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const { isLoading: checkExistingIsLoading } = useHandleExisting(formik.values);

    const handleChange = React.useCallback(
        (
            value: ConstructionSiteModulesStates | AddressState,
            errors: ConstructionSiteModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<ConstructionSiteRo>({
            method  : 'GET',
            url     : urlApiBuilder.constructionSiteGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const relation = await fetchDataRelation<{
                document_id: DocumentRo[];
                customer_id: CustomerRo[];
                collector_id: CollectorRo[];
                presta_id: PrestaRo[];
            }>(
                {
                    customer_id  : response.response?.data.ro?.customer_id,
                    document_id  : response.response?.data.ro?.document_id,
                    collector_id : response.response?.data.ro?.list_collector_id,
                    presta_id    : response.response?.data.ro?.presta_id
                },
                {
                    customer_id  : true,
                    document_id  : true,
                    collector_id : true,
                    presta_id    : true
                }
            );

            const ro: ConstructionSiteWithDocuments = {
                ...response.response?.data.ro,
                customer_id       : relation.customer_id,
                document_id       : relation.document_id,
                list_collector_id : relation.collector_id,
                presta_id         : relation.presta_id
            };

            const formikState: ConstructionSiteFormState = mapApiDataToState({
                ...ro
            }) as ConstructionSiteFormState;
            setApiState({
                form : formikState,
                db   : {
                    ...response.response?.data.ro,
                    customer_id : ro.customer_id[0]?.id ? [ro.customer_id[0].id] : [],
                    document_id : ro.document_id && ro.document_id?.length > 0 ? ro.document_id.map((doc) => doc.id) : []
                }
            });

            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.constructionSiteArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.constructionSiteList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.constructionSiteList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Chantier{edit || readOnly ? `: ${apiState?.form?.general.label}` : ''}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.constructionSiteEdit(dataId)}
                historyUrl={urlApiBuilder.constructionSiteHistory(dataId)}
            />
            <PageFormLine>
                <ConstructionSite.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
                <ConstructionSite.Customer
                    id="customer"
                    result={handleChange}
                    value={formik.values.customer}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            <Address
                displayError={formik.submitCount > 0}
                readOnly={readOnly}
                id="address"
                result={handleChange}
                value={formik.values.address}
            />
            <ConstructionSite.Contacts
                id="contacts"
                result={handleChange}
                value={formik.values.contacts}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
            />
            <ConstructionSite.CollectorList
                id="collectorList"
                result={handleChange}
                value={formik.values.collectorList}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
            />
            <ConstructionSite.Document
                id="uploads"
                DisplayLabel="Documents"
                result={handleChange}
                value={formik.values.uploads}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
            />
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit) ||
                        checkExistingIsLoading
                    }
                    loading={checkExistingIsLoading}
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={!equal(apiState?.form, formik.values)}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default ConstructionSiteForm;
