import { Accordion } from 'primereact/accordion';
import styled from 'styled-components';

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

export const TooltipEntry = styled.div<{ margin?: string }>`
    margin: ${(props) => props.margin || 0};
`;

export const StyledSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const StyledAccordion = styled(Accordion)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    & .p-accordion {
        width: 100%;
    }
    & .p-accordion-header-link {
        padding: 0 1.25em !important;
    }
    & .p-accordion-content {
        background: transparent;
        display: flex;
        flex-wrap: wrap;
        padding: 8px;
    }
    & .p-accordion-tab {
        width: 100%;
        background: transparent;
        margin: 0;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyleSpan = styled.span`
    padding: 12px;
    width: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
`;

export const MaxExportContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SliderContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-self: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
`;

export const AnomalyRangeContainer = styled.div`
    display: flex;
    gap: 16px;
`;
