import styled from 'styled-components';

import { CardErrors, CollectAnomalies, CollectAnomalyEntry, COLLECT_ANOMALIES } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import Button from '../../components/Button';
import { mapAnomalyText } from '../../common/enumMapper';
import Input from '../../components/Inputs';

export type CollectAnomaliesFormProps = {
    value?: CollectAnomaliesFormState;
    id: string;
    result: (
        value: CollectAnomaliesFormState,
        errors: null | string[] | CardErrors<CollectAnomaliesFormState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type CollectAnomaliesFormState = CollectAnomalies;

export type CollectAnomaliesFormErrorState = CardErrors<CollectAnomaliesFormState>;

export const initialState: CollectAnomaliesFormState = {
    differFromOrder: {
        confidence : 100,
        value      : false
    },
    missingContainer: {
        confidence : 100,
        value      : false
    },
    // missingDepositContainer: {
    //     confidence : 100,
    //     value      : false
    // },
    // missingRetrievalContainer: {
    //     confidence : 100,
    //     value      : false
    // },
    failedSorting: {
        confidence : 100,
        value      : false
    },
    overflowVolume: {
        confidence : 100,
        value      : 0
    }
};

export const initialErrorState: CollectAnomaliesFormErrorState = generateInitialErrorState(initialState);

export const CollectAnomaliesForm = ({ id, result, value = initialState }: CollectAnomaliesFormProps) => {
    const { val, setVal, err, setErr } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    const handleChange = (
        value: CollectAnomalyEntry<unknown> | undefined | null,
        errors: string[] | null,
        childId: string
    ) => {
        setVal((prev) => {
            if (prev[childId as keyof typeof prev] === value) return prev;
            const data = { ...prev, [childId]: value };
            setErr((old) => {
                return {
                    ...old,
                    [childId]: errors
                };
            });
            return data;
        });
    };

    return (
        <StyledCard title="Anomalies">
            <FullLineInput>
                {Object.values(COLLECT_ANOMALIES).map((key) => {
                    const value = val[key]?.value;
                    const confidence = val[key]?.confidence;
                    return (
                        <div key={key} style={{ display: 'flex', gap: '8px' }}>
                            <Button.Checkbox
                                id={key}
                                result={(val) =>
                                    handleChange(
                                        {
                                            confidence : 100,
                                            value      : key === 'overflowVolume' ? Number(val) : val
                                        },
                                        null,
                                        key
                                    )
                                }
                                value={!!value}
                                label={`${mapAnomalyText(key)} ${
                                    typeof confidence === 'number' && value ? `(${confidence}% de certitude)` : ''
                                }`}
                                readOnly={false}
                                labelPosition="right"
                            />
                            {key === 'overflowVolume' && (
                                <Input.Number
                                    id={`${key}.value`}
                                    result={(val) =>
                                        handleChange(
                                            {
                                                confidence : 100,
                                                value      : val
                                            },
                                            null,
                                            key
                                        )
                                    }
                                    value={(value as number) ?? 0}
                                    label="Volume"
                                    required={false}
                                    errors={err[key]}
                                    displayError={false}
                                    suffix="m³"
                                />
                            )}
                        </div>
                    );
                })}
                <Helper>
                    <i className="pi pi-info-circle" />
                    Modifier manuellement une anomalie forcera son niveau de certitude à 100%.
                </Helper>
            </FullLineInput>
        </StyledCard>
    );
};

const StyledCard = styled(Card)``;

const FullLineInput = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
`;

const Helper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: italic;
    font-weight: 200;
    margin-top: 8px;
`;
