import moment from 'moment';

import {
    convertHHMMToDate,
    dateToSlotMapper,
    formatPhoneE164ToPhoneValue,
    getMiddleOfTheDay,
    getMinutesFromDate,
    mergeDates,
    slotToDateMapper
} from '@bbng/util/misc';
import {
    AdminRo,
    CCServiceRoFront,
    CollectConfigDetails,
    CollectConfigZone,
    ConstructionSiteRo,
    CustomerRo,
    DocumentRo,
    ELandfillDangerousTrashType,
    ESlot,
    IContact,
    OrderCreateDto,
    OrderRoFront,
    CREATED_FROM,
    UserRo,
    ZoneRo,
    PrestaRo
} from '@bbng/util/types';

import { TDocument, mapFrontContactToBackContact, mapFrontDocumentToDocumentDto } from '../../common/form';
import {
    AddressErrorState,
    AddressState,
    initialErrorState as addressInitialErrorState,
    initialState as addressInitialState
} from '../../modules/common/Address';
import {
    ContactsErrorState,
    ContactsState,
    initialErrorState as contactsInitialErrorState,
    initialState as contactsInitialState
} from '../../modules/common/Contacts';
import {
    OrderCollectInfoErrorState,
    OrderCollectInfoState,
    initialErrorState as orderCollectInfoInitialErrorState,
    initialState as orderCollectInfoInitialState
} from '../../modules/order/CollectInfo';
import {
    OrderConstructionSiteErrorState,
    OrderConstructionSiteState,
    initialErrorState as orderConstructionSiteInitialErrorState,
    initialState as orderConstructionSiteInitialState
} from '../../modules/order/ConstructionSite';
import {
    OrderCustomerErrorState,
    OrderCustomerState,
    initialErrorState as orderCustomerInitialErrorState,
    initialState as orderCustomerInitialState
} from '../../modules/order/Customer';
import {
    OrderDocumentsErrorState,
    OrderDocumentsState,
    initialErrorState as orderDocumentsInitialErrorState,
    initialState as orderDocumentsInitialState
} from '../../modules/order/Documents';
import {
    EOrderDumpsterServiceForm,
    OrderDumpsterServiceErrorState,
    OrderDumpsterServiceState,
    mapCollectConfigFamilyToService,
    initialErrorState as orderServiceInitialErrorState,
    initialState as orderServiceInitialState
} from '../../modules/order/DumpsterService';
import {
    OrderMiscErrorState,
    OrderMiscState,
    initialErrorState as orderMiscInitialErrorState,
    initialState as orderMiscInitialState,
    orderCreatedFromOptions
} from '../../modules/order/Misc';
import {
    OrderProductsErrorState,
    OrderProductsState,
    ProductRoWithQuantity,
    initialErrorState as orderProductsInitialErrorState,
    initialState as orderProductsInitialState
} from '../../modules/order/Products';
import { mapProductFamilyToOrderTypeForm } from '../../modules/order/Products/helpers';
import {
    OrderTrashTypeErrorState,
    OrderTrashTypeState,
    initialErrorState as orderTrashTypeInitialErrorState,
    initialState as orderTrashTypeInitialState
} from '../../modules/order/TrashType';
import {
    EOrderTypeForm,
    OrderTypeErrorState,
    OrderTypeState,
    initialErrorState as orderTypeInitialErrorState,
    initialState as orderTypeInitialState
} from '../../modules/order/Type';
import {
    OrderZoneErrorState,
    OrderZoneState,
    initialErrorState as orderZoneInitialErrorState,
    initialState as orderZoneInitialState
} from '../../modules/order/Zone';
import {
    DumpsterOnSiteSelectErrorState,
    DumpsterOnSiteSelectState,
    initialErrorState as dumpsterOnSiteSelectInitialErrorState,
    initialState as dumpsterOnSiteSelectInitialState
} from '../../modules/collect-config/DumpsterOnSiteSelect';

export type OrderModulesStates =
    | OrderCustomerState
    | OrderConstructionSiteState
    | OrderDumpsterServiceState
    | OrderTrashTypeState
    | OrderCollectInfoState
    | OrderProductsState
    | OrderMiscState
    | OrderDocumentsState
    | ContactsState
    | AddressState
    | OrderZoneState
    | DumpsterOnSiteSelectState
    | OrderTypeState;

export type OrderModulesErrorStates =
    | OrderCustomerErrorState
    | OrderConstructionSiteErrorState
    | OrderDumpsterServiceErrorState
    | OrderCollectInfoErrorState
    | OrderTrashTypeErrorState
    | OrderProductsErrorState
    | OrderMiscErrorState
    | OrderDocumentsErrorState
    | ContactsErrorState
    | AddressErrorState
    | OrderZoneErrorState
    | DumpsterOnSiteSelectErrorState
    | OrderTypeErrorState;

export type OrderFormState = {
    customer: OrderCustomerState;
    constructionSite?: OrderConstructionSiteState;
    type: OrderTypeState;
    dumpsterService?: OrderDumpsterServiceState;
    collectInfo?: OrderCollectInfoState;
    trashType?: OrderTrashTypeState;
    deliveryAddress?: AddressState;
    contacts: ContactsState;
    misc: OrderMiscState;
    products: OrderProductsState;
    documents: OrderDocumentsState;
    zone: OrderZoneState;
    dumpsterOnSiteSelect: DumpsterOnSiteSelectState;
};

export type OrderFormErrorState = {
    customer: OrderCustomerErrorState;
    constructionSite?: OrderConstructionSiteErrorState;
    type: OrderTypeErrorState;
    dumpsterService?: OrderDumpsterServiceErrorState;
    collectInfo?: OrderCollectInfoErrorState;
    trashType?: OrderTrashTypeErrorState;
    deliveryAddress?: AddressErrorState;
    contacts: ContactsErrorState;
    misc: OrderMiscErrorState;
    products: OrderProductsErrorState;
    documents: OrderDocumentsErrorState;
    zone: OrderZoneErrorState;
    dumpsterOnSiteSelect: DumpsterOnSiteSelectErrorState;
};

export const initialState: OrderFormState = {
    customer             : orderCustomerInitialState,
    constructionSite     : orderConstructionSiteInitialState,
    type                 : orderTypeInitialState,
    dumpsterService      : orderServiceInitialState,
    collectInfo          : orderCollectInfoInitialState,
    trashType            : orderTrashTypeInitialState,
    deliveryAddress      : addressInitialState,
    contacts             : contactsInitialState,
    misc                 : orderMiscInitialState,
    products             : orderProductsInitialState,
    documents            : orderDocumentsInitialState,
    zone                 : orderZoneInitialState,
    dumpsterOnSiteSelect : dumpsterOnSiteSelectInitialState
};

export const initialErrorState: OrderFormErrorState = {
    customer             : orderCustomerInitialErrorState,
    constructionSite     : orderConstructionSiteInitialErrorState,
    type                 : orderTypeInitialErrorState,
    dumpsterService      : orderServiceInitialErrorState,
    collectInfo          : orderCollectInfoInitialErrorState,
    trashType            : orderTrashTypeInitialErrorState,
    deliveryAddress      : addressInitialErrorState,
    contacts             : contactsInitialErrorState,
    misc                 : orderMiscInitialErrorState,
    products             : orderProductsInitialErrorState,
    documents            : orderDocumentsInitialErrorState,
    zone                 : orderZoneInitialErrorState,
    dumpsterOnSiteSelect : dumpsterOnSiteSelectInitialErrorState
};

export const mapApiDataToState = (order: OrderRoFront, duplicate?: boolean): OrderFormState => {
    const cc = order.collect_config_id[0] as CCServiceRoFront;

    const taken_from = orderCreatedFromOptions.includes(order.taken_from as any)
        ? order.taken_from
        : CREATED_FROM.INBOUND_CALL;

    return {
        customer: {
            customer: {
                ...(order.customer_id as CustomerRo[])[0],
                admin_id: [(order.admin_id[0] as AdminRo)['id']]
            } as CustomerRo,
            user: order.user_id ? (order.user_id[0] as UserRo) : undefined
        },
        type: {
            type: mapProductFamilyToOrderTypeForm(cc.products[0]['family'])
        },
        dumpsterService: {
            service: mapCollectConfigFamilyToService(cc.family)
        },
        trashType: {
            trashType: cc.products[0]['trash_type']
        },
        contacts: {
            construction_site: (cc.construction_site_contact as IContact[]).map((e) => ({
                ...e,
                phone_number: formatPhoneE164ToPhoneValue(e.phone_number)
            })),
            logistic: (cc.log_contact as IContact[]).map((e) => ({
                ...e,
                phone_number: formatPhoneE164ToPhoneValue(e.phone_number)
            }))
        },
        misc: {
            prepaid : cc.prepaid,
            comment : cc.comment || '',
            taken_from
        },
        products: duplicate
            ? {}
            : Object.fromEntries(
                  cc.products.map((product) => [product.id, product as unknown as ProductRoWithQuantity])
              ),
        constructionSite: {
            constructionSite: cc.construction_site_id[0] as ConstructionSiteRo
        },
        deliveryAddress: {
            address_google        : undefined,
            address_street_number : cc.address['components']['street_number'],
            address_street_name   : cc.address['components']['route'],
            address_city          : cc.address['components']['locality'],
            address_zip_code      : cc.address['components']['postal_code'],
            address_country       : cc.address['components']['country'],
            address_complement    : cc.address['components']['complement'] ?? '',
            address_lat           : cc.address['coordinates'].latitude,
            address_lng           : cc.address['coordinates'].longitude
        },
        collectInfo: {
            collect_day : moment.utc(cc.from_date).toISOString(),
            from_date   : moment(cc.from_date).toDate(),
            to_date     : moment(cc.to_date).toDate(),
            slot        : {
                slot           : dateToSlotMapper(cc.from_date, cc.to_date),
                slot_from_date : moment(cc.from_date).toISOString(),
                slot_to_date   : moment(cc.to_date).toISOString()
            },
            characteristics   : cc.characteristics,
            already_available : !!cc.already_available_date,
            ...(cc.already_available_date && {
                already_available_from_date : moment.utc(cc.already_available_date.from_date).toISOString(),
                already_available_to_date   : moment.utc(cc.already_available_date.to_date).toISOString()
            }),
            retrieval_date       : cc.retrieval_date ?? undefined,
            waiting_time_minutes : convertHHMMToDate(
                moment()
                    .startOf('day')
                    .add(cc?.waiting_time_minutes ?? 0, 'minute')
                    .format('HH:mm')
            ),
            execution_time_minutes: convertHHMMToDate(
                moment()
                    .startOf('day')
                    .add(cc?.execution_time_minutes ?? 0, 'minute')
                    .format('HH:mm')
            ),
            by_presta : cc.presta_id?.length > 0,
            presta    : cc.presta_id[0] as PrestaRo
        },
        documents: {
            documents: duplicate
                ? []
                : (order.document_id as unknown as DocumentRo[]).map<TDocument>((doc) => ({
                      type   : 'online',
                      online : doc
                  })),
            order_sheet_number: duplicate ? '' : order.order_sheet_number || ''
        },
        zone: {
            zone: cc.zone as ZoneRo
        },
        dumpsterOnSiteSelect: {
            dumpster_on_site : undefined,
            collect_config   : undefined
        }
    };
};

export const mapStateToApiCreateData = (state: OrderFormState): OrderCreateDto => {
    const collectHasDangerousTrash =
        state.type.type !== EOrderTypeForm.DELIVERY &&
        Object.values(ELandfillDangerousTrashType).includes(
            (state.trashType?.trashType || '') as unknown as ELandfillDangerousTrashType
        );

    const { from_date, to_date } = slotToDateMapper(
        state.collectInfo?.slot?.slot as ESlot,
        state.collectInfo?.slot?.slot_from_date,
        state.collectInfo?.slot?.slot_to_date
    );

    const presta_id = state.collectInfo?.by_presta
        ? [state.constructionSite?.constructionSite?.presta_id?.[0] ?? state.collectInfo?.presta?.id]
        : [];

    const data: OrderCreateDto = {
        customer_id                   : state.customer.customer?.id ? [state.customer.customer.id] : [],
        user_id                       : state.customer.user ? [state.customer.user.id] : [],
        confirmation_email_recipients : state.customer.confirmationEmail ? [state.customer.confirmationEmail] : [],
        collect_configs               : [
            {
                lines: Object.values(state.products)
                    .filter((product) => product.quantity > 0)
                    .map((product) => ({
                        id       : product.id,
                        quantity : product.quantity
                    })),
                details: {
                    presta_id,
                    construction_site_id: state.constructionSite?.constructionSite?.id
                        ? [state.constructionSite.constructionSite?.id]
                        : undefined,
                    address_shipping:
                        state.type.type === EOrderTypeForm.DELIVERY
                            ? state.constructionSite?.constructionSite?.address
                            : undefined,
                    construction_site_contact: state.contacts.construction_site.map((contact) =>
                        mapFrontContactToBackContact(contact)
                    ),
                    log_contact : state.contacts.logistic.map((contact) => mapFrontContactToBackContact(contact)),
                    from_date   : !collectHasDangerousTrash
                        ? mergeDates(state.collectInfo?.collect_day, from_date)
                        : undefined,
                    to_date: !collectHasDangerousTrash
                        ? mergeDates(state.collectInfo?.collect_day, to_date)
                        : undefined,
                    already_available_date:
                        !collectHasDangerousTrash && state.collectInfo?.already_available
                            ? {
                                  from_date: mergeDates(
                                      getMiddleOfTheDay(),
                                      state.collectInfo?.already_available_from_date as string
                                  ),
                                  to_date: mergeDates(
                                      getMiddleOfTheDay(),
                                      state.collectInfo?.already_available_to_date as string
                                  )
                              }
                            : undefined,
                    characteristics : state.collectInfo?.characteristics,
                    prepaid         : state.misc.prepaid,
                    comment         : state.misc.comment ?? undefined,
                    retrieval_date  :
                        state.type?.type === EOrderTypeForm.COLLECT_DUMPSTER &&
                        state.dumpsterService?.service === EOrderDumpsterServiceForm.DEPOSIT
                            ? state.collectInfo?.retrieval_date
                            : undefined,
                    waiting_time_minutes:
                        state.type.type === EOrderTypeForm.COLLECT_DUMPSTER &&
                        state.dumpsterService?.service === EOrderDumpsterServiceForm.LOAD_WAIT
                            ? getMinutesFromDate(state.collectInfo?.waiting_time_minutes)
                            : undefined,
                    zone                   : state.zone?.zone ? mapZoneToCcZone(state.zone?.zone) : undefined,
                    execution_time_minutes :
                        getMinutesFromDate(state.collectInfo?.execution_time_minutes) === 0
                            ? 10
                            : getMinutesFromDate(state.collectInfo?.execution_time_minutes),
                    dumpster_on_site_id: state.dumpsterOnSiteSelect?.dumpster_on_site
                        ? [state.dumpsterOnSiteSelect?.dumpster_on_site.id]
                        : undefined,
                    deposit_collect_config_id: state.dumpsterOnSiteSelect?.collect_config
                        ? [state.dumpsterOnSiteSelect?.collect_config.id]
                        : undefined
                } as CollectConfigDetails
            }
        ],
        taken_from          : state.misc.taken_from ?? CREATED_FROM.INBOUND_CALL,
        order_sheet_number  : state.documents.order_sheet_number ?? undefined,
        conditions_accepted : true,
        admin_id            : state.customer.customer?.admin_id || [],
        documents           : state.documents.documents
            .filter((doc) => !!doc.local)
            .map((doc) => mapFrontDocumentToDocumentDto(doc)),
        existing_document_id: state.documents.documents
            .filter((doc) => !doc.local)
            .map((doc) => doc.online?.id as string)
    };

    return data;
};

const mapZoneToCcZone = (zone: ZoneRo): CollectConfigZone => ({
    id        : zone.id,
    name      : zone.name,
    metropole : zone.metropole
});
