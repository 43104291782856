import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

export const InputSwitchContainer = styled.div<{ labelOnLeft: boolean }>`
    display: flex;
    justify-content: left;
    flex-direction: ${({ labelOnLeft }) => (labelOnLeft ? 'row' : 'row-reverse')};
    align-items: center;
    gap: 16px;
`;

export type SwitchButtonProps = {
    label?: string;
    labelPosition?: 'left' | 'right';
    value: boolean;
    id: string;
    result: (value: boolean, errors: null | string[], id: string) => void;
    readOnly?: boolean;
};

export const SwitchButton: React.FC<SwitchButtonProps> = ({
    label,
    labelPosition = 'left',
    value,
    id,
    result,
    readOnly
}: SwitchButtonProps): JSX.Element => {
    const [val, setVal] = useState<boolean>(value);
    const labelOnLeft = labelPosition === 'left';

    React.useEffect(() => {
        if (value !== val) {
            setVal(value);
        }
    }, [value]);

    const handleOnChange = useCallback(
        (e: InputSwitchChangeParams): void => {
            setVal(e.target.value);
            result(e.target.value, null, id);
        },
        [setVal, result, id]
    );

    return (
        <InputSwitchContainer labelOnLeft={labelOnLeft}>
            {label && <span>{label}</span>}
            <InputSwitch onChange={handleOnChange} checked={val} disabled={readOnly} />
        </InputSwitchContainer>
    );
};
