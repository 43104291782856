import Joi from 'joi';

import { CollectorListType, ConstructionSiteCharacteristic } from '.';
import { AddressCreateSchema, AddressEditSchema } from '../address';
import { ContactDtoSchema } from '../contact';
import { DocumentCreateDtoSchema } from '../document';
import { CREATED_FROM } from '../misc';

export const ConstructionSiteCreateDtoSchema = Joi.object({
    label       : Joi.string().required(),
    address     : AddressCreateSchema.required(),
    /**
     * A construction site must have one contact at least (log or construction_site)
     */
    log_contact : Joi.when('construction_site_contact', {
        is        : Joi.array().length(0),
        then      : Joi.array().items(ContactDtoSchema).min(1).required(),
        otherwise : Joi.array().items(ContactDtoSchema)
    }),
    construction_site_contact : Joi.array().items(ContactDtoSchema),
    additional_info           : Joi.string().allow(''),
    order_sheet_number        : Joi.string().allow(''),
    characteristics           : Joi.array().items(...Object.values(ConstructionSiteCharacteristic)),
    customer_id               : Joi.array().items(Joi.string()).length(1).required(),
    document_id               : Joi.array().items(DocumentCreateDtoSchema),
    presta_id                 : Joi.array().items(Joi.string()),
    list_collector_type       : Joi.string<CollectorListType>()
        .valid(...Object.values(CollectorListType))
        .optional(),
    list_collector_id : Joi.array().items(Joi.string()).optional(),
    created_from      : Joi.string()
        .valid(...Object.values(CREATED_FROM))
        .optional()
});

export const ConstructionSiteUpdateDtoSchema = Joi.object({
    log_contact               : Joi.array().items(ContactDtoSchema),
    construction_site_contact : Joi.array().items(ContactDtoSchema),
    label                     : Joi.string(),
    order_sheet_number        : Joi.string().allow(''),
    additional_info           : Joi.string().allow(''),
    hubspot_id                : Joi.string().allow(null),
    presta_id                 : Joi.array().items(Joi.string()),
    address                   : AddressEditSchema,
    document_id               : Joi.object({
        add    : Joi.array().items(DocumentCreateDtoSchema),
        remove : Joi.array().items(Joi.string())
    }),
    characteristics     : Joi.array().items(...Object.values(ConstructionSiteCharacteristic)),
    list_collector_type : Joi.string<CollectorListType>()
        .valid(...Object.values(CollectorListType))
        .optional(),
    list_collector_id: Joi.array().items(Joi.string()).optional()
});
