import { FormikErrors } from 'formik';
import { Tag } from 'primereact/tag';
import React from 'react';
import styled from 'styled-components';

import {
    CardErrors,
    ConstructionSiteCharacteristic,
    ConstructionSiteRo,
    ECollectCharacteristic,
    DocumentRo,
    PrestaRo
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';

import { generateInitialErrorState, mapBackContactToFrontContact, TDocument } from '../../common/form';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import { OrderFormState } from '../../pages/OrderForm/helpers';
import RelationAutocomplete from '../common/RelationAutocomplete';
import { OrderDocumentsState } from './Documents';

export type OrderConstructionSiteProps = {
    readOnly?: boolean;
    value?: OrderConstructionSiteState;
    id: string;
    result: (
        value: OrderConstructionSiteState,
        errors: null | string[] | CardErrors<OrderConstructionSiteState>,
        id: string
    ) => void;
    displayError?: boolean;
    customerId?: string;
    globalSetter: (
        values: React.SetStateAction<OrderFormState>,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<OrderFormState>>;
    documents: OrderDocumentsState;
};

export type OrderConstructionSiteState = {
    constructionSite?: ConstructionSiteRo;
};
export type OrderConstructionSiteErrorState = CardErrors<OrderConstructionSiteState>;

export const initialState: OrderConstructionSiteState = {
    constructionSite: undefined
};
export const initialErrorState: OrderConstructionSiteErrorState = generateInitialErrorState(initialState);

export const OrderConstructionSite: React.FC<OrderConstructionSiteProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError,
    customerId,
    globalSetter,
    documents
}: OrderConstructionSiteProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    React.useEffect(() => {
        if (readOnly) return;

        const error = {
            constructionSite: val.constructionSite ? null : ['Le chantier est requis']
        };
        setErr(error);
    }, []);

    React.useEffect(() => {
        const handle = async () => {
            if (val.constructionSite) {
                const consSiteDocuments: DocumentRo[] = [];
                /**
                 * If there is no documents in the form, fetch the documents from the construction site
                 */
                const relation = await fetchDataRelation<{
                    document_id: DocumentRo[];
                    presta_id: PrestaRo[];
                }>(
                    {
                        document_id : val?.constructionSite?.document_id,
                        presta_id   : val?.constructionSite?.presta_id
                    },
                    {
                        document_id : true,
                        presta_id   : true
                    }
                );
                consSiteDocuments.push(...relation.document_id);

                const presta = relation.presta_id?.[0];

                globalSetter((values) => ({
                    ...values,
                    collectInfo: {
                        ...values.collectInfo,
                        ...(val.constructionSite?.presta_id?.[0]
                            ? { by_presta: true }
                            : { by_presta: false, presta: undefined }),
                        characteristics: val.constructionSite?.characteristics.includes(
                            ConstructionSiteCharacteristic.NARROW_STREET
                        )
                            ? [ECollectCharacteristic.NARROW_STREET]
                            : [],
                        by_presta: presta ? true : false,
                        presta
                    },
                    contacts: {
                        construction_site:
                            val.constructionSite?.construction_site_contact?.map((c) =>
                                mapBackContactToFrontContact(c)
                            ) || [],
                        logistic: val.constructionSite?.log_contact?.map((c) => mapBackContactToFrontContact(c)) || []
                    },
                    documents: {
                        ...values.documents,
                        documents:
                            consSiteDocuments.length > 0
                                ? consSiteDocuments.map<TDocument>((doc: DocumentRo) => ({
                                      type   : 'online',
                                      online : doc
                                  }))
                                : values.documents.documents,
                        /**
                         * If there is no order sheet number in the form, set the order sheet number from the construction site
                         */
                        ...(!documents.order_sheet_number && {
                            order_sheet_number: val.constructionSite?.order_sheet_number
                        })
                    },
                    misc: {
                        ...values.misc,
                        comment: val.constructionSite?.additional_info ?? ''
                    }
                }));
            }
        };
        handle();
    }, [val.constructionSite, globalSetter]);

    return (
        <StyledCard title="Chantier">
            {customerId ? (
                <FullLineInput>
                    <div>Adresse:</div>
                    <RelationAutocomplete.ConstructionSite
                        readOnly={readOnly}
                        baseValue={val.constructionSite}
                        errors={err.constructionSite}
                        displayError={displayError}
                        onSelect={(value) => {
                            handleChange(value, null, 'constructionSite');
                        }}
                        onUnselect={() => {
                            handleChange(undefined, ['Le chantier est requis'], 'constructionSite');
                        }}
                        customerId={customerId}
                    />
                </FullLineInput>
            ) : (
                <>
                    {displayError && (err.constructionSite ?? []).length > 0 && (
                        <Errors>
                            {(err.constructionSite ?? []).map((e, i) => (
                                <li key={e + i}>{e}</li>
                            ))}
                        </Errors>
                    )}
                    <Tag
                        value="Veuillez renseigner un client pour pouvoir sélectionner un chantier."
                        icon="pi pi-info-circle"
                    />
                </>
            )}
        </StyledCard>
    );
};

const Errors = styled.div`
    color: #d22020;
`;

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;
