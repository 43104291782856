import { ISODate } from '../misc';
import { EPlanningType } from '../planning';
import { EVRPNodeType } from './vrp.response';

export type VRPInstance = VRPConstant &
    (VRPBBConstant | VRPDumpsterConstant) & {
        CTG_CAMION_TYPE: Record<string, VRPBBTruckType | VRPDumpsterTruckType>;
        CTG_COMPETENCES: VRPBBSkills[] | VRPDumpsterSkills[];
        Clients_Commandes: Record<string, VRPOrderClientBB | VRPOrderClientDumpster>;
        Missions_Administratives: Record<string, VRPAdminCollect>;
        Chauffeurs: Record<string, VRPDriverBB | VRPDriverDumpster>;
        Decheteries: Record<string, VRPLandfillBB | VRPLandfillDumpster>;
        Distance_Matrice: Record<number, VRPDistanceMatrix>;
    };

/**
 * We need to omit some fields as they are not present as constant.
 * They change according to the planning config.
 */
// export type VRPConstantServiceDTO = Omit<VRPDumpsterConstant, 'CTG_TAUX_GAIN_DEPOT_BENNE' | 'CTG_TAUX_GAIN_ENLEVEMENT_BENNE'> &
//     Omit<VRPBBConstant, 'CTG_COMMANDE_CAPACITE_FLOTTE_M3'>;

export type VRPCreateInstanceDto = {
    input: VRPInstance;
    type: EPlanningType;
    isChainPlanningDumpster: boolean;
};

export type VRPConstantByTypeQuery = { type: EPlanningType };
export type VRPConstantEditDto<T extends EPlanningType> = Partial<VRPConstant> &
    (T extends EPlanningType.BIG_BAG
        ? Partial<Omit<VRPBBConstant, 'CTG_COMMANDE_CAPACITE_FLOTTE_M3'>>
        : Partial<Omit<VRPDumpsterConstant, 'CTG_TAUX_GAIN_DEPOT_BENNE' | 'CTG_TAUX_GAIN_ENLEVEMENT_BENNE'>>) & {
        type: EPlanningType;
    };

export type VRPConstantRo<T extends EPlanningType> = (T extends EPlanningType.BIG_BAG
    ? VRPConstant & Omit<VRPBBConstant, 'CTG_COMMANDE_CAPACITE_FLOTTE_M3'> /* it's send on calulate */
    : VRPConstant &
          Omit<
              VRPDumpsterConstant,
              'CTG_TAUX_GAIN_DEPOT_BENNE' | 'CTG_TAUX_GAIN_ENLEVEMENT_BENNE'
          >) /* it's send on calulate */ & {
    id: string;
    type: EPlanningType;
    created_at: ISODate;
    updated_at: ISODate;
};

export const PRIX_DE_REVIENT_ENDLESS = 23;
export const PRIX_DE_REVIENT_NON_ENDLESS = 34;

//✅
export type VRPConstant = {
    CTG_LONG_POLLING_AVEC_REDIS: boolean;
    CTG_SOLVER_NOMBRE_MAX_THREADS: number;
    CTG_SOLVER_DUREE_OPTIMISATION_EN_SECONDES: number;
    CTG_SOLVER_VERBOSE_DISPLAY_MODE: number;
    CTG_OSM_ZOOM_LEVEL: number;
    CTG_OSM_DISTANCE_CORRECTION: number;
    CTG_NB_UNITE_PAR_HEURE: number;
    CTG_UNITE_TEMPORELLE_MINUTE: number;
    CTG_CHAUFFEUR_MAX_HEURE_DE_TRAVAIL: string; //HH:mm
    CTG_CHAUFFEUR_MAX_NB_COMMANDE: number;
    CTG_CHAUFFEUR_MAX_STEPS: number;
    CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_COMMANDES_PAR_JOUR: number;
    CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_DISTANCES_COMMANDES_CONSECUTIVES: number;
    CTG_CHAUFFEUR_MAX_HEURE_GLISSANTE_REGLEMENTATION: string; //HH:mm
    CTG_CHAUFFEUR_PAUSE: number;
    CTG_CHAUFFEUR_ATTENTE: number;
    CTG_CHAUFFEUR_TRAITEMENT: number;
    CTG_CHAUFFEUR_ROUTES: number;
    CTG_JOURS_DE_COLLECTE: string[];
};

//✅
export type VRPDumpsterConstant = {
    CTG_COMMANDE_CAPACITE_FLOTTE_TOURS: number;
    CTG_CAMION_DUREE_LEVAGE_BENNE_VIDE: string; //HH:mm
    CTG_CAMION_DUREE_DEPOT_BENNE_VIDE: string; //HH:mm
    CTG_CAMION_DUREE_LEVAGE_BENNE_PLEINE: string; //HH:mm
    CTG_CAMION_DUREE_DEPOT_BENNE_PLEINE: string; //HH:mm
    CTG_CAMION_DUREE_DECHARGEMENT_BENNE_PLEINE: string; //HH:mm
    CTG_CAMION_DUREE_TRAITEMENT_BENNE: string; //HH:mm
    CTG_TAUX_GAIN_DEPOT_BENNE: number; //NOT PRESENT IN CONSTANT DB
    CTG_TAUX_GAIN_ENLEVEMENT_BENNE: number; //NOT PRESENT IN CONSTANT DB
};

//✅
export type VRPBBConstant = {
    CTG_DDXXSBIGM: number;
    CTG_DXXSBIGM: number;
    CTG_XXSBIGM: number;
    CTG_XSBIGM: number;
    CTG_SBIGM: number;
    CTG_MBIGM: number;
    CTG_LBIGM: number;
    CTG_XBIGM: number;
    CTG_COMMANDE_CAPACITE_FLOTTE_M3: number; //NOT PRESENT IN CONSTANT DB
    CTG_CAMION_DUREE_SET_UP: string; //HH:mm
    CTG_CAMION_DUREE_SET_DOWN: string; //HH:mm
    CTG_CAMION_DUREE_TRAITEMENT_BB: string; //HH:mm
    CTG_CAMION_MIN_VOLUME_UTILE_M3: number;
    CTG_CAMION_MIN_VOLUME_UTILE_KG: number;
    CTG_CAMION_MAX_VOLUME_UTILE_M3: number;
    CTG_CAMION_MAX_VOLUME_UTILE_KG: number;
    CTG_BIG_BAG_GAIN_B0: number;
    CTG_BIG_BAG_DEMI_GAIN_B0: number;
    CTG_BIG_BAG_GAIN_B1: number;
    CTG_BIG_BAG_DEMI_GAIN_B1: number;
    CTG_BIG_BAG_GAIN_B2: number;
    CTG_BIG_BAG_DEMI_GAIN_B2: number;
    CTG_BIG_BAG_TYPE: {
        B250L: BBType;
        B1M3: BBType;
        B2M3: BBType;
    };
};

//✅
export type VRPCommonTruckType = {
    type_carburant: string;
    autonomie_max: number;
    autonomie_restante: number;
    duree_rechargement_carburant: string; //HH:mm
    metadata: VRPMetadataTruck;
};
export type VRPMetadataTruck = {
    truck_id: string;
    [key: string]: any;
};

//✅
export type VRPDumpsterTruckType = VRPCommonTruckType & {
    capacite_nb_bennes: number;
    limite_rentabilite_nb_bennes: number;
};

//✅
export type VRPBBTruckType = VRPCommonTruckType & {
    charge_utile_kg: number;
    volume_utiles_m3: number;
    duree_set_up: string; //HH:mm
    duree_set_down: string; //HH:mm
    limite_rentabilite_nb_sacs: number;
};

//✅
export type BBType = {
    volume_big_bag_m3: number;
    charge_utile_big_bag_kg: number;
    duree_traitement_big_bag: string; //HH:mm
};

//✅
export enum VRPBBSkills {
    ADR = 'ADR',
    BENNES = 'Bennes',
    GRUES = 'Grues'
}

//✅
export enum VRPDumpsterSkills {
    BENNE_D = 'BENNE_D',
    BENNE_E = 'BENNE_E',
    BENNE_F = 'BENNE_F'
}

//✅
export type VRPOrderClientCommon = {
    zone: string;
    statut_client: number; // 1: normal, 2: important, 3: impératif
    jour_du_service: string;
    types_de_camions_admissibles: string[];
    date_de_debut_au_plus_tot: string; //HH:mm
    date_de_fin_au_plus_tard: string; //HH:mm
    position_grid_X: number; // longitude
    position_grid_Y: number; // latitude

    nb_heures_depuis_commande: string; //HH:mm ////////////////////////////////////////////////////////////// PASSER DANS COMMON
    metadata: VRPMetadataOrder;
};
export type VRPMetadataOrder = {
    customer_id: string;
    cc_id: string;
    is_collect: boolean;
    collect_id?: string;
    is_benne_F?: true;
    is_splitted?: true;
    splitted_idx?: number;
    [key: string]: any;
};

//✅
export type VRPOrderClientDumpster = VRPOrderClientCommon & {
    prix_facture: number;
    duree_attente: string; // en + de l'attente normale (att chargement)
    commande_successeur: string[]; /////////////
    decheterie_successeur: any[];
    type_benne_8m3: number; /////////////
    type_benne_12m3: number; /////////////
    type_benne_15m3: number; /////////////
    type_benne_20m3: number; /////////////
    type_benne_30m3: number; /////////////
    mission: VRPDumpsterSkills; // nature de la collecte /////////////
    duree_traitement_benne: string;
    espace_multiple_bennes: number; // booléen (0 ou 1) à ajouter dans commande
    competences: VRPDumpsterSkills[]; /////////////
};

//✅
export type VRPOrderClientBB = VRPOrderClientCommon & {
    competences: VRPBBSkills[];
    nb_de_BBB0: number;
    nb_de_BBB1: number;
    nb_de_BBB2: number;
};

//✅
export type VRPDriverCommon = {
    lenteur_chauffeur: number; // 1: best, 3: pire
    nom_type_camion: string;
    cout_du_chauffeur: number; // coût horaire à rajouter dans les infos collector
    heure_de_debut_journee_au_plus_tot: string; //HH:mm
    heure_de_fin_journee_au_plus_tard: string; //HH:mm
    zones_de_chalandise: string[];
    etapes_deja_realisees: Record<string, VRPDoneSteps>;
    [key: `${string}_START`]: {
        position_grid_X_debut: number; // longitude
        position_grid_Y_debut: number; // latitude
    };
    [key: `${string}_END`]: {
        position_grid_X_fin: number; // longitude
        position_grid_Y_fin: number; // latitude
    };
    metadata: VRPMetadataDriver;
};
export type VRPMetadataDriver = {
    collector_id: string;
    is_collect: false;
};

//✅
export type VRPDoneSteps = {
    debug_driver_id: string;
    remaining_time_before_break?: string; //HH:mm
    break_done_15?: boolean;
    nodes: VRPDoneStepsNode[];
};
//✅
export type VRPDoneStepsNode = {
    node_type: EVRPNodeType;
    debug_node_id: string;
    node_name: string;
    order_volume_in_m3: string | undefined;
    arrival_time: string | undefined; //HH:mm
    departure_time: string; //HH:mm
    service_duration: string | undefined; //HH:mm
    remaining_capacity_in_m3: string | undefined;
    metadata: VRPMetadataDone;
};
export type VRPMetadataDone = {
    collect_id: string;
    type: EVRPNodeType;
    is_collect: true;
    cc_id: string;
    customer_id: string;
    landfill_id: string;
    is_fake?: true;
    truck_id: string;
    collector_id: string;
    is_benne_F?: true;
    is_splitted?: true;
    splitted_idx?: number;
    [key: string]: any;
};

//✅
export type VRPDriverDumpster = VRPDriverCommon & {
    zone_de_parking: string; ////////////////////////////////////////////////////////////// PASSER DANS DUMP,
    competences: VRPDumpsterSkills[];
};

//✅
export type VRPDriverBB = VRPDriverCommon & {
    volume_occupe_m3: number;
    competences: VRPBBSkills[];
};

//✅
export type VRPLandfillCommon = {
    decheterie_proprietaire: boolean;
    position_grid_X: number;
    position_grid_Y: number;
    horaire_ouverture_matin: string;
    horaire_fermeture_matin: string;
    horaire_ouverture_apres_midi: string;
    horaire_fermeture_apres_midi: string;
    cout_traitement_m3: number;
    capacite_max_journaliere_m3?: number;
    metadata: VRPMetadataDecheterie;
    disponibilite_pour_optimisation: boolean;
};
export type VRPMetadataDecheterie = {
    landfill_id: string;
    is_collect: false;
};

//✅
export type VRPLandfillDumpster = VRPLandfillCommon & {
    duree_traitement: string;
    nb_bennes_8m3: number;
    nb_bennes_15m3: number;
    nb_bennes_20m3: number;
    nb_bennes_30m3: number;
};

//✅
export type VRPLandfillBB = VRPLandfillCommon & {
    duree_attente: string; //HH:mm
    duree_pesee: string; //HH:mm
    duree_dechargement: string; //HH:mm
};

//✅
export enum EVrpOrderIdPrefix {
    DEPOSIT = 'deposit',
    RETRIEVAL = 'retrieval'
}

//✅
export type VRPAdminCollect = {
    nom_chauffeur: string;
    date_de_debut_au_plus_tot: string; //HH:mm
    duree_mission_administrative: string; //HH:mm
    date_de_fin_au_plus_tard: string; //HH:mm
    position_grid_X: number;
    position_grid_Y: number;
    metadata: VRPMetadataAdmin;
};
export type VRPMetadataAdmin = {
    cc_id: string;
    is_collect: boolean;
    collect_id?: string;
    [key: string]: any;
};

//✅
export type VRPDistanceMatrix = {
    depart: string; //key_lieu_A
    arrivee: string; //key_lieu_B
    distance: number; //meters
    duree: string; //HH:mm
};
