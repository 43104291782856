import {
    CC_STATUS,
    CollectAnomalies,
    CollectEditServiceDto,
    CollectRo,
    CollectServiceFront,
    DocumentRo,
    ECollectHazardReason,
    EPlanningType,
    ProductInCCOrCO,
    PRODUCT_FAMILY
} from '@bbng/util/types';

import {
    CollectInfoState,
    CollectInfoErrorState,
    initialErrorState as collectInfoInitialErrorState,
    initialState as collectInfoInitialState
} from '../../modules/collect/Info';

import {
    OrderProductsState,
    OrderProductsErrorState,
    initialErrorState as productInfoInitialErrorState,
    initialState as productInfoInitialState,
    ProductRoWithQuantity
} from '../../modules/order/Products';

import {
    OrderDumpsterServiceErrorState,
    OrderDumpsterServiceState,
    initialErrorState as orderDumpsterServiceInitialErrorState,
    initialState as orderDumpsterServiceInitialState,
    EOrderDumpsterServiceForm
} from '../../modules/order/DumpsterService';

import {
    OrderTrashTypeErrorState,
    OrderTrashTypeState,
    initialErrorState as orderTrashTypeInitialErrorState,
    initialState as orderTrashTypeInitialState
} from '../../modules/order/TrashType';

import {
    CollectAnomaliesFormErrorState,
    CollectAnomaliesFormState,
    initialErrorState as collectAnomaliesInitialErrorState,
    initialState as collectAnomaliesInitialState
} from '../../modules/collect/Anomalies';

import {
    DocumentsErrorState,
    DocumentsState,
    initialErrorState as documentsInitialErrorState,
    initialState as documentsInitialState
} from '../../modules/common/Documents';
import { getRelationsDto, mapFrontDocumentToDocumentDto, TDocument } from '../../common/form';

export type CollectModulesStates =
    | CollectInfoState
    | OrderDumpsterServiceState
    | OrderTrashTypeState
    | DocumentsState
    | CollectAnomaliesFormState
    | OrderProductsState;

export type CollectModulesErrorStates =
    | CollectInfoErrorState
    | OrderDumpsterServiceErrorState
    | OrderTrashTypeErrorState
    | DocumentsErrorState
    | CollectAnomaliesFormErrorState
    | OrderProductsErrorState;

export type CollectFormState = {
    info: CollectInfoState;
    products: OrderProductsState;
    productsOperational?: OrderProductsState | null;
    productsDelivery?: OrderProductsState | null;
    dumpsterService: OrderDumpsterServiceState;
    trashType: OrderTrashTypeState;
    documents: DocumentsState;
    anomalies: CollectAnomaliesFormState;
};

export type CollectFormErrorState = {
    info: CollectInfoErrorState;
    products: OrderProductsErrorState;
    productsOperational?: OrderProductsErrorState | null;
    productsDelivery?: OrderProductsErrorState | null;
    dumpsterService: OrderDumpsterServiceErrorState;
    trashType: OrderTrashTypeErrorState;
    documents: DocumentsErrorState;
    anomalies: CollectAnomaliesFormErrorState;
};

export const initialState = (day?: string): CollectFormState => ({
    info                : collectInfoInitialState(day),
    products            : productInfoInitialState,
    productsOperational : productInfoInitialState,
    productsDelivery    : productInfoInitialState,
    dumpsterService     : orderDumpsterServiceInitialState,
    trashType           : orderTrashTypeInitialState,
    documents           : documentsInitialState,
    anomalies           : collectAnomaliesInitialState
});

export const initialErrorState = (day?: string): CollectFormErrorState => ({
    info                : collectInfoInitialErrorState(day),
    products            : productInfoInitialErrorState,
    productsOperational : productInfoInitialErrorState,
    productsDelivery    : productInfoInitialErrorState,
    dumpsterService     : orderDumpsterServiceInitialErrorState,
    trashType           : orderTrashTypeInitialErrorState,
    documents           : documentsInitialErrorState,
    anomalies           : collectAnomaliesInitialErrorState
});

export const mapApiDataToState = (collect: CollectServiceFront): CollectFormState => {
    let initialDumpsterService = undefined;
    let initialTrashType = undefined;
    if (collect.type === EPlanningType.DUMPSTER) {
        const deposit = collect.informations.collected_items.find(
            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT
        );
        const retrieval = collect.informations.collected_items.find(
            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL
        );
        const loadWait = collect.informations.collected_items.find(
            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT
        );

        if (deposit && retrieval) {
            initialDumpsterService = EOrderDumpsterServiceForm.ROTATION;
        } else if (deposit) {
            initialDumpsterService = EOrderDumpsterServiceForm.DEPOSIT;
            initialTrashType = deposit.trash_type;
        } else if (retrieval) {
            initialDumpsterService = EOrderDumpsterServiceForm.RETRIEVAL;
            initialTrashType = retrieval.trash_type;
        } else if (loadWait) {
            initialDumpsterService = EOrderDumpsterServiceForm.LOAD_WAIT;
            initialTrashType = loadWait.trash_type;
        }
    }

    const operationalProducts = collect.informations.collected_items.filter((product) => product.operational === true);

    const nonOperationalProducts = collect.informations.collected_items.filter(
        (product) => product.operational === false
    );

    const collectProducts = nonOperationalProducts.filter(
        (product) => product.family !== PRODUCT_FAMILY.DELIVERY_BIG_BAG
    );
    const deliveryProducts = nonOperationalProducts.filter(
        (product) => product.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG
    );

    return {
        info: {
            collect_day    : collect.day,
            arrived_at     : collect.arrived_at,
            completed_at   : collect.completed_at,
            collector      : Object.keys(collect.collector).length > 0 ? collect.collector : undefined,
            truck          : Object.keys(collect.truck).length > 0 ? collect.truck : undefined,
            hazard_comment : collect.hazard_comment,
            hazard_reason  : collect.hazard_reason,
            has_hazard     : collect.status === CC_STATUS.HAZARD
            // document       : null,
        },
        products            : mapProductInCCOrCOToOrderProductState(collectProducts),
        productsOperational : mapProductInCCOrCOToOrderProductState(operationalProducts),
        productsDelivery    : mapProductInCCOrCOToOrderProductState(deliveryProducts),
        dumpsterService     : {
            service: initialDumpsterService
        },
        trashType: {
            trashType: initialTrashType
        },
        documents: {
            documents: (collect.document_id as unknown as DocumentRo[]).map<TDocument>((doc) => ({
                type   : 'online',
                online : doc
            }))
        },
        anomalies: collect.informations.anomalies
    } as CollectFormState;
};

export const mapStateToApiEditData = ({
    collect,
    state
}: {
    collect: CollectRo;
    state: CollectFormState;
}): CollectEditServiceDto => {
    let newStatus = collect.status;
    if (state.info.has_hazard) {
        newStatus = CC_STATUS.HAZARD;
    } else {
        newStatus = CC_STATUS.FINISHED;
    }

    /**
     * Remove falsy values from anomalies
     */
    const formattedAnomalies: CollectAnomalies = Object.entries(state.anomalies ?? {}).reduce((acc, [key, value]) => {
        if (value?.value) {
            acc[key as keyof CollectAnomalies] = value as any;
        }
        return acc;
    }, {} as CollectAnomalies);

    const dto = {
        delivery_number : state.info.delivery_number,
        dumpster_weight : state.info.dumpster_weight,
        collected_items : Object.values(state.products)
            .filter((product) => product.quantity > 0)
            .map((product) => ({
                id       : product.id,
                quantity : product.quantity
            })),
        ...(newStatus === CC_STATUS.HAZARD
            ? {
                  status         : newStatus,
                  hazard_reason  : state.info?.hazard_reason as ECollectHazardReason,
                  hazard_comment : state.info?.hazard_comment as string
              }
            : {
                  status: newStatus
              }),
        documents: state.documents.documents
            .filter((doc) => !!doc.local)
            .map((doc) => mapFrontDocumentToDocumentDto(doc)),
        anomalies: formattedAnomalies
    } as CollectEditServiceDto;

    const relationState = {
        // only online documents are kept in relations (other are to be created, through documents key)
        document_id: state.documents.documents.filter((f) => f.type === 'online').map((e) => e.online?.id)
    };

    const relations = getRelationsDto<CollectRo>('document_id')(relationState, collect);

    return {
        ...dto,
        ...relations
    };
};

export const mapProductInCCOrCOToOrderProductState = (products: ProductInCCOrCO[]): OrderProductsState => {
    return products.reduce((acc, product) => {
        acc[product.id] = {
            ...product,
            quantity: product.quantity
        } as unknown as ProductRoWithQuantity;
        return acc;
    }, {} as OrderProductsState);
};
