import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { deepCopy, mergeDates } from '@bbng/util/misc';
import {
    CardErrors,
    CollectorRo,
    ECollectHazardReason,
    EDocumentType,
    EPlanningRegion,
    EPlanningType,
    PlanningShiftStepCategory,
    PrestaRo,
    TruckRo
} from '@bbng/util/types';

import { mapCollectHazardReason } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import Calendar from '../../components/Calendar';
import { Card } from '../../components/Card';
import { Dropzone } from '../../components/Dropzone';
import Input from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';
import RelationAutocomplete from '../common/RelationAutocomplete';
import equal from 'fast-deep-equal/react';
import { Tag } from 'primereact/tag';
import { Link } from 'react-router-dom';
import { urlBuilder } from '../../common/urlBuilder';

export type CollectInfoProps = {
    readOnly?: boolean;
    value?: CollectInfoState;
    id: string;
    result: (value: CollectInfoState, errors: null | string[] | CardErrors<CollectInfoState>, id: string) => void;
    displayError?: boolean;
    region?: EPlanningRegion;
    hideHazard: boolean;
    hidePhoto: boolean;
    category: PlanningShiftStepCategory;
    day: string;
    editableDay?: boolean;
    disableMaxDay?: boolean;
    type: EPlanningType;
    terminated?: boolean;
    isInternalLandfill?: boolean;
    presta?: PrestaRo;
    allowEmptyCollector?: boolean;
    allowEmptyTruck?: boolean;
};

export type CollectInfoState = {
    collect_day?: string;
    completed_at?: string | Date;
    arrived_at?: string | Date;
    has_hazard: boolean;
    hazard_reason?: string;
    hazard_comment?: string;
    delivery_number: string;
    document: File | null;
    collector?: CollectorRo;
    truck?: TruckRo;
    duration?: number;
    dumpster_weight?: number;
};

export type CollectInfoErrorState = CardErrors<CollectInfoState>;

export const initialState = (day?: string): CollectInfoState => {
    const baseTime = day ? moment.utc(day) : moment();

    return {
        collect_day     : baseTime.toISOString(),
        completed_at    : baseTime.toISOString(),
        arrived_at      : baseTime.subtract(10, 'minutes').toDate(),
        duration        : 45,
        has_hazard      : false,
        hazard_reason   : ECollectHazardReason.NO_BAG,
        hazard_comment  : '',
        delivery_number : '',
        document        : null,
        collector       : undefined,
        truck           : undefined,
        dumpster_weight : undefined
    };
};

export const initialErrorState = (day?: string): CollectInfoErrorState => generateInitialErrorState(initialState(day));

export const CollectInfo = ({
    readOnly = false,
    id,
    result,
    displayError = false,
    region,
    hideHazard,
    hidePhoto,
    category,
    day,
    editableDay = false,
    disableMaxDay = false,
    value = initialState(day),
    type,
    terminated = false,
    isInternalLandfill = true, //true by default because it's 90% of the time the case,
    presta,
    allowEmptyCollector = false,
    allowEmptyTruck = false
}: CollectInfoProps) => {
    const [isOtherHazard, setIsOtherHazard] = React.useState(false);
    const { val, setVal, err, setErr } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState(day),
        result
    });

    const handleChange = (
        value: CollectorRo | TruckRo | boolean | number | Date | File | Date[] | string | string[] | undefined | null,
        errors: string[] | null,
        childId: string
    ) => {
        if (childId === 'completed_at' || childId === 'arrived_at') {
            const isoValue = moment(value as Date).toISOString();
            if (equal(isoValue, val[childId as keyof CollectInfoState])) return;
            setVal((current) => ({ ...current, [childId as keyof CollectInfoState]: isoValue }));
        } else {
            if (equal(value, val[childId as keyof CollectInfoState])) return;
            setVal((current) => ({ ...current, [childId as keyof CollectInfoState]: value }));
        }

        if (childId === 'completed_at' || childId === 'arrived_at') {
            const updatedValue = {
                ...deepCopy(val),
                [childId as keyof CollectInfoState]: moment(value as Date).toISOString()
            };
            const isValid = checkIntegrity(updatedValue, err, category);
            if (equal(isValid, err) === false) {
                setErr(isValid);
            }
            return;
        } else {
            if (equal(errors, err[childId as keyof CollectInfoState])) return;
            setErr((current) => ({ ...current, [childId]: errors }));
        }
    };

    useEffect(() => {
        if (val.hazard_reason !== ECollectHazardReason.OTHER) {
            err.hazard_comment = null;
            setIsOtherHazard(false);
        } else {
            setIsOtherHazard(true);
            displayError = true;
        }
    }, [val.hazard_reason]);

    useEffect(() => {
        if (!val.has_hazard) {
            setErr((old) => ({
                ...old,
                hazard_reason  : null,
                hazard_comment : null
            }));
        }
    }, [val.has_hazard]);

    React.useEffect(() => {
        if (presta) {
            // if presta collect, we don't need to check for collector and truck
            return;
        }
        if (value.collector === undefined && !allowEmptyCollector) {
            setErr((old) => ({ ...old, collector: ['Collecteur obligatoire'] }));
        }
        if (value.truck === undefined && !allowEmptyTruck) {
            setErr((old) => ({ ...old, truck: ['Camion obligatoire'] }));
        }
    }, []);

    return (
        <StyledCard title="Informations de l'étape">
            {presta && (
                <FullLineInput>
                    <div>
                        Prise en charge par le prestataire{' '}
                        <Link to={urlBuilder.prestaView(presta.id)} target="_blank">
                            <strong>{presta.name}</strong>
                        </Link>
                    </div>
                </FullLineInput>
            )}
            <FullLineInput>
                <TimeWithLabel>
                    <span>Étape faite le</span>
                    <Calendar.DayMonthYear
                        required={true}
                        id="collect_day"
                        readOnly={editableDay === false}
                        result={handleChange}
                        value={val.collect_day}
                        errors={err.collect_day}
                        displayError={displayError}
                        maxDate={disableMaxDay ? undefined : moment.utc().toISOString()}
                    />
                </TimeWithLabel>
                <TimeWithLabel>
                    <span>Heure de début</span>
                    <Calendar.HoursMins
                        required={true}
                        id="arrived_at"
                        readOnly={readOnly || terminated}
                        result={handleChange}
                        value={val.arrived_at}
                        errors={err.arrived_at}
                        displayError={displayError}
                    />
                </TimeWithLabel>
                {category === PlanningShiftStepCategory.BREAK ? (
                    <TimeWithLabel>
                        <Button.Select
                            id="duration"
                            value={`${val.duration}`}
                            errors={err.duration}
                            displayError={displayError}
                            result={(value, error, id) => handleChange(parseInt(value as string), error, id)}
                            options={['15', '30', '45']}
                            readOnly={readOnly}
                            required={category === PlanningShiftStepCategory.BREAK}
                        />
                        <span>minutes</span>
                    </TimeWithLabel>
                ) : (
                    <TimeWithLabel>
                        <span>Heure de fin</span>
                        <Calendar.HoursMins
                            required={true}
                            id="completed_at"
                            readOnly={readOnly || terminated}
                            result={handleChange}
                            value={val.completed_at}
                            errors={err.completed_at}
                            displayError={displayError}
                        />
                    </TimeWithLabel>
                )}
            </FullLineInput>
            <RelationsContainer>
                <FullLineInput>
                    {presta ? (
                        <Tag
                            style={{ backgroundColor: '#444444', color: 'white', height: '40px', padding: '0 40px' }}
                            value="Aucun camion ou collecteur n'est à sélectionner pour une collecte de prestataire"
                            icon="pi pi-info-circle"
                        />
                    ) : (
                        <>
                            <RelationAutocomplete.Collector
                                id="collector"
                                label="Collecteur"
                                readOnly={readOnly || terminated}
                                baseValue={val.collector}
                                errors={err.collector}
                                displayError={displayError}
                                onSelect={(value) => {
                                    handleChange(value, null, 'collector');
                                }}
                                onUnselect={() => {
                                    handleChange(undefined, ['Aucun collecteur sélectionné'], 'collector');
                                }}
                            />
                            <RelationAutocomplete.Truck
                                region={region}
                                id="truck"
                                label="Camion"
                                readOnly={readOnly || terminated}
                                baseValue={val.truck}
                                errors={err.truck}
                                displayError={displayError}
                                onSelect={(value) => {
                                    handleChange(value, null, 'truck');
                                }}
                                onUnselect={() => {
                                    handleChange(undefined, ['Aucun camion sélectionné'], 'truck');
                                }}
                            />
                        </>
                    )}
                </FullLineInput>
            </RelationsContainer>
            {category === PlanningShiftStepCategory.SERVICE && type === EPlanningType.DUMPSTER && (
                <FullLineInputWithPadding>
                    <Input.Text
                        required={false}
                        label="Bon de livraison"
                        id="delivery_number"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.delivery_number}
                        errors={err.delivery_number}
                        displayError={displayError}
                    />
                    <Input.Number
                        id="dumpster_weight"
                        label={isInternalLandfill ? 'Poids BRUT benne (kg)' : 'Poids NET benne (kg)'}
                        readOnly={readOnly}
                        required={false}
                        min={0}
                        step={250}
                        result={handleChange}
                        displayError={displayError}
                        value={val.dumpster_weight || 0}
                        errors={err.dumpster_weight}
                    />
                </FullLineInputWithPadding>
            )}
            {!hideHazard && (
                <FullLineInput>
                    <h4>
                        <Button.Switch
                            id="has_hazard"
                            label="Aléa à signaler"
                            result={handleChange}
                            value={val.has_hazard}
                            readOnly={readOnly}
                        />
                    </h4>
                </FullLineInput>
            )}
            {val.has_hazard && (
                <FullLineInput>
                    <Button.Select
                        forceSelection
                        id="hazard_reason"
                        readOnly={readOnly || !val.has_hazard}
                        required={true}
                        displayError={displayError}
                        result={handleChange}
                        options={Object.values(ECollectHazardReason)}
                        value={val.hazard_reason}
                        errors={err.hazard_reason}
                        labelMapper={(value: string) => mapCollectHazardReason(value as ECollectHazardReason)}
                    />
                    <Input.Textarea
                        label="Détails de l'aléa"
                        required={isOtherHazard}
                        id="hazard_comment"
                        readOnly={readOnly || !val.has_hazard}
                        result={handleChange}
                        value={val.hazard_comment || ''}
                        errors={err.hazard_comment}
                        displayError={displayError}
                    />
                </FullLineInput>
            )}
            {!hidePhoto && (
                <div>
                    <h4>Photo complémentaire</h4>
                    <FullLineInput>
                        <Dropzone
                            id="document"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.document}
                            errors={err.document}
                            type={EDocumentType.COLLECT_PHOTO}
                            required={false}
                        />
                    </FullLineInput>
                </div>
            )}
        </StyledCard>
    );
};

const StyledCard = styled(Card)``;

const FullLineInput = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
`;

const FullLineInputWithPadding = styled(FullLineInput)`
    padding-top: 16px;
`;

const TimeWithLabel = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const RelationsContainer = styled.div`
    margin-top: 16px;
`;

const checkIntegrity = (
    data: CollectInfoState,
    errors: CollectInfoErrorState,
    category: PlanningShiftStepCategory
): CollectInfoErrorState => {
    if (category === PlanningShiftStepCategory.BREAK) return errors; // no need to check integrity for break (completed_at is set manually)
    let errorText = '';
    const arrived_at = mergeDates(data.collect_day, data.arrived_at as string);
    const completed_at = mergeDates(data.collect_day, data.completed_at as string);
    if (moment(arrived_at).isAfter(moment(completed_at))) {
        errorText = `La date de fin doit être après la date de début`;
    }
    return {
        ...errors,
        completed_at: errorText ? [errorText] : null
    };
};
