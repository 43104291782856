import { CollectAnomalies, CollectRoFront } from '@bbng/util/types';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { useMemo } from 'react';
import styled from 'styled-components';
import { displayAnomalyText, mapAnomalyColor } from '../../common/enumMapper';
import { AnomaliesContainer } from './style';

export type CollectAnomaliesDisplayProps = {
    collect: CollectRoFront | undefined;
};

export const CollectAnomaliesDisplay: React.FC<CollectAnomaliesDisplayProps> = ({ collect }): JSX.Element | null => {
    const data = useMemo(() => {
        if (!collect || !('anomalies' in collect.informations)) {
            return [];
        }
        return Object.entries(collect.informations.anomalies ?? {})
            .filter(([_, value]) => !!value.value)
            .map(([key, value]) => {
                return {
                    label      : displayAnomalyText(key as keyof CollectAnomalies, value.value),
                    confidence : value.confidence,
                    color      : mapAnomalyColor(key as keyof CollectAnomalies)
                };
            });
    }, [collect]);

    if (!collect || !('anomalies' in collect.informations)) {
        return null;
    }

    return (
        <AnomaliesContainer>
            <h3>Anomalies</h3>
            <DataTable value={data} emptyMessage="Aucune anomalie détectée" style={{ width: '100%' }}>
                <Column field="label" header="Anomalie" />
                <Column
                    header="Certitude"
                    alignHeader="center"
                    body={(rowData) => <StyledProgressBar value={rowData.confidence} bgColor={rowData.color} />}
                    style={{ width: '100%' }}
                />
            </DataTable>
        </AnomaliesContainer>
    );
};

const StyledProgressBar = styled(ProgressBar)<{ bgColor: string }>`
    width: 100%;
    .p-progressbar-value {
        background: ${({ bgColor }) => `var(--${bgColor})`};
    }
`;
