import React, { useEffect } from 'react';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { Chart } from 'primereact/chart';
import { Endless3Stats, IMSResponse, ChartData, CC_FAMILY } from '@bbng/util/types';
import { mapCollectConfigFamilyText } from '../../common/enumMapper';
import { PageLoader } from '../../components/PageLoader';

export const StatistiquesEndless3Page = () => {
    const bbngRequest = useRequest({ toastifyError: false, toastifySuccess: false });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Endless3Stats>({} as Endless3Stats);

    const getData = async () => {
        setLoading(true);
        const response = await bbngRequest<IMSResponse<Endless3Stats>>({
            url    : urlApiBuilder.collectConfigStatsEndless3(),
            method : 'GET',
            sync   : true
        });

        if (response && response.success) {
            const responseData = response.response?.data.ro;
            setData(response.response?.data.ro as unknown as Endless3Stats);
        }
        setLoading(false);
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <PageLoader loading />;
    }

    return (
        // Grid of 3 columns
        <div style={{ marginLeft: '10%', width: '80%', marginTop: '16px' }}>
            <h3 style={{ textAlign: 'center' }}>Statistiques Endless 3</h3>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '16px' }}>
                <div
                    style={{
                        height         : '25vh',
                        display        : 'flex',
                        flexDirection  : 'column',
                        justifyContent : 'center',
                        alignItems     : 'center',
                        border         : '1px solid black',
                        padding        : '16px',
                        borderRadius   : '8px',
                        marginTop      : '16px'
                    }}
                >
                    <h3>Chiffre d'affaires</h3>
                    <Chart id="chiffre_affaire" type="bar" data={data.ca} options={options} />
                </div>

                <div
                    style={{
                        height         : '25vh',
                        display        : 'flex',
                        flexDirection  : 'column',
                        justifyContent : 'center',
                        alignItems     : 'center',
                        border         : '1px solid black',
                        padding        : '16px',
                        borderRadius   : '8px',
                        marginTop      : '16px'
                    }}
                >
                    <h3>Volumes</h3>
                    <Chart id="volumes" type="bar" data={data.volume} options={options} />
                </div>
                <div
                    style={{
                        height         : '25vh',
                        display        : 'flex',
                        flexDirection  : 'column',
                        justifyContent : 'center',
                        alignItems     : 'center',
                        border         : '1px solid black',
                        padding        : '16px',
                        borderRadius   : '8px',
                        marginTop      : '16px'
                    }}
                >
                    <h3>Nombre de commandes</h3>
                    <Chart id="number_order" type="bar" data={data.order} options={options} />
                </div>
            </div>
            <div
                style={{
                    height         : '25vh',
                    width          : '100%',
                    display        : 'flex',
                    flexDirection  : 'column',
                    justifyContent : 'center',
                    alignItems     : 'center',
                    border         : '1px solid black',
                    padding        : '16px',
                    borderRadius   : '8px',
                    marginTop      : '24px'
                }}
            >
                <h3>Répartition des prestations</h3>
                <div
                    style={{
                        display        : 'flex',
                        width          : '100%',
                        flexDirection  : 'row',
                        justifyContent : 'space-between',
                        alignItems     : 'center',
                        gap            : '16px'
                    }}
                >
                    {data.prestation?.map((prestation, index) => {
                        const frenchPrestation: ChartData = {
                            ...prestation,
                            labels: prestation?.labels?.map((label) => mapCollectConfigFamilyText(label as CC_FAMILY))
                        };
                        return (
                            <Chart
                                key={index}
                                style={{ width: '100%' }}
                                id={`prestation-${index}`}
                                type="bar"
                                data={frenchPrestation}
                                options={options}
                            />
                        );
                    })}
                </div>
            </div>
            <div
                style={{
                    height         : '25vh',
                    width          : '100%',
                    display        : 'flex',
                    flexDirection  : 'column',
                    justifyContent : 'center',
                    alignItems     : 'center',
                    border         : '1px solid black',
                    padding        : '16px',
                    borderRadius   : '8px',
                    marginTop      : '24px'
                }}
            >
                <h3>Répartition des type de bennes</h3>
                <div
                    style={{
                        display        : 'flex',
                        width          : '100%',
                        flexDirection  : 'row',
                        justifyContent : 'space-between',
                        alignItems     : 'center',
                        gap            : '16px'
                    }}
                >
                    {data.repartition?.map((repartition, index) => (
                        <Chart
                            key={index}
                            id={`repartition-${index}`}
                            type="bar"
                            data={repartition}
                            options={options}
                            style={{ width: '100%' }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
