import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { Divider } from 'primereact/divider';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

import {
    BSDManualGenerateDto,
    CCServiceRoFront,
    CC_STATUS,
    CollectRoFront,
    CollectService,
    ConstructionSiteRo,
    CustomerRo,
    DocumentRo,
    ECollectHazardReason,
    EDocumentType,
    EPlanningType,
    InvoiceRo,
    LandfillRo,
    PlanningShiftStepCategory
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import {} from '../../common/enumMapper';
import {
    mapCollectConfigFamilyText,
    mapCollectHazardReason,
    mapInvoiceToInvoiceUrl,
    mapCollectStatus
} from '../../common/enumMapper';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { AddressMapButton } from '../../components/AddressDisplayButton';
import { PageLoader } from '../../components/PageLoader';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { mapStatusToSeverity } from '../CollectConfigs';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import { displayAccurateTitle, displayAddress, isLateCancelation } from './helpers';
import { isEmptying, isEmptyingInformation, isService, isServiceInformation } from './helpers';
import {
    AddressContainer,
    AddressDetails,
    CollectedItemsContainer,
    ContentContainer,
    DescriptionContainer,
    DescriptionContent,
    DescriptionContentContainer,
    DescriptionDate,
    DescriptionDriverContainer,
    DescriptionTime,
    DescriptionTimeContainer,
    DriverData,
    FlexContainer,
    HazardContainer,
    HazardReason,
    OrderContainer,
    OrderItem,
    OrderItemData,
    OrderItemLabel,
    Page,
    ServiceContainer,
    StatusTag
} from './style';
import { Button } from 'primereact/button';
import { ActionsFormContainer } from '../../common/form';
import { ImageViewer } from '../../components/ImageViewer';
import { CollectProducts } from './products';
import { CollectAnomaliesDisplay } from './anomalies';

const Media: React.FC<{ b64?: string }> = ({ b64 }) => {
    return (
        <div style={{ height: '200px' }}>
            <img src={b64} alt="media" style={{ height: '100%', borderRadius: '8px' }} />
        </div>
    );
};

const CollectDetail: React.FC = (): JSX.Element => {
    const { id: paramsId } = useParams();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [data, setData] = React.useState<CollectRoFront>();

    const [showModalNoContent, setShowModalNoContent] = useState<boolean>(false);
    const [docMap, setDocMap] = useState<Record<string, string>>({});
    const [pictures, setPictures] = useState<string[]>([]);

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const isDesktop = window.innerWidth > 1024;

    const dataService = isService(data) ? data : undefined;
    const dataLateCancelation = isLateCancelation(data) ? data : undefined;
    const dataEmptying = isEmptying(data) ? data : undefined;
    const informationService = isServiceInformation(dataService?.informations) ? dataService?.informations : undefined;
    const cc = useMemo(() => {
        if (dataService) return dataService?.informations?.collect_config_id as CCServiceRoFront;
        if (dataLateCancelation)
            return dataLateCancelation?.informations?.collect_config_id as unknown as CCServiceRoFront;
        return undefined;
    }, [dataService, dataLateCancelation]);
    const customer = useMemo(() => {
        if (dataService) return dataService?.informations?.customer_id as unknown as CustomerRo;
        if (dataLateCancelation) return dataLateCancelation?.informations?.customer_id as unknown as CustomerRo;
        return undefined;
    }, [dataService, dataLateCancelation]);
    const consSite = useMemo(() => {
        if (cc) return cc.construction_site_id[0] as ConstructionSiteRo;
        return undefined;
    }, [cc]);
    const price = useMemo(() => {
        if (dataService) return dataService?.informations?.price;
        if (dataLateCancelation) return dataLateCancelation?.informations?.price;
        return undefined;
    }, [data]);
    const collectedItems = useMemo(() => {
        if (dataService) return dataService?.informations?.collected_items;
        if (dataLateCancelation) return dataLateCancelation?.informations?.collected_items;
        return undefined;
    }, [data]);
    const emptying = informationService?.emptied_at_landfill_id;
    const emptyingDesc =
        emptying && typeof emptying === 'object'
            ? `${emptying?.name} (${emptying?.address?.components['postal_code']})`
            : '';
    const presta = useMemo(() => {
        if (data && Object.keys(data.presta).length > 0) {
            return data?.presta;
        }
        return false;
    }, [data?.collector]);
    const collector = useMemo(() => {
        if (data && Object.keys(data.collector).length > 0) {
            return data?.collector;
        }
        return false;
    }, [data?.collector]);
    const truck = useMemo(() => {
        if (data && Object.keys(data.truck).length > 0) {
            return data?.truck;
        }
        return false;
    }, [data?.truck]);

    const informationEmptying = isEmptyingInformation(dataEmptying?.informations)
        ? dataEmptying?.informations
        : undefined;
    const landfill = informationEmptying?.landfill_id as unknown as LandfillRo;
    const invoices = data?.invoice_id as InvoiceRo[];

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(true);

    const urlToB64 = async (url: string): Promise<string> => {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });
    };

    const fetchAllDocumentImages = async (documents: string[]) => {
        const filteredDocuments = documents.filter(
            (docId) => docId.startsWith(`${EDocumentType.BSD}|`) === false && docId.endsWith(`.pdf`) === false
        );

        const responses = (
            await Promise.all(
                filteredDocuments.map(async (docId): Promise<[string, string] | null> => {
                    const response = await bbngRequest<string>({
                        url     : urlApiBuilder.documentGetSignedUrl(docId),
                        method  : 'GET',
                        options : {
                            toastifySuccess : false,
                            toastifyError   : false
                        }
                    });

                    if (response.success && response.response) {
                        const b64 = await urlToB64(response.response.data.ro!);

                        return [docId, b64];
                    }
                    return null;
                })
            )
        ).filter((f): f is [string, string] => f !== null);

        setDocMap(Object.fromEntries(responses));
    };

    const fetchData = React.useCallback(async () => {
        const response: StatelessResponse<CollectRoFront> = await fetchDataRelation(
            await fetchDataRelation(
                await bbngRequest<CollectRoFront>({
                    method  : 'GET',
                    url     : urlApiBuilder.collectGetById(dataId),
                    options : { toastifySuccess: false }
                }),
                {
                    collect_config_id      : true,
                    landfill_id            : true,
                    customer_id            : true,
                    emptied_at_landfill_id : true,
                    invoice_id             : true
                }
            ),
            {
                construction_site_id: true
            }
        );

        if (response.success && response.response?.data.ro) {
            const ro: CollectRoFront = response.response?.data.ro;
            setPictures(
                ro?.document_id?.filter(
                    (f) =>
                        f.endsWith('.pdf') === false &&
                        f.includes(EDocumentType.COLLECT_SIGNATURE) === false &&
                        f.includes(EDocumentType.COLLECT_LANDFILL_SIGNATURE) === false
                )
            );
            setData(ro);
            await fetchAllDocumentImages(ro.document_id);
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    const handleDownloadBSD = async (e: React.MouseEvent) => {
        e.preventDefault();

        const bsdId = data?.document_id.find((docId) => docId.includes(EDocumentType.BSD));
        if (!bsdId) return;

        setLoading(true);

        /**
         * Check that the document exists
         **/
        const response = await bbngRequest<DocumentRo[]>({
            url     : urlApiBuilder.documentGetMany(),
            method  : 'POST',
            payload : {
                body: {
                    ids: [bsdId]
                }
            },
            sync: true
        });
        if (response.success && response.response && response.response.data.ro) {
            if (response.response.data.ro.length > 0) {
                const signedUrl = await bbngRequest<string>({
                    url    : urlApiBuilder.documentGetSignedUrl(bsdId),
                    method : 'GET'
                });
                if (signedUrl.success && signedUrl.response && signedUrl.response.data.ro) {
                    window.open(signedUrl.response.data.ro, '_blank');
                }
            } else {
                setShowModalNoContent(true);
            }
        }

        setLoading(false);
    };

    const handleRegenerateBSD = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (!data) return;

        setLoading(true);

        const response = await bbngRequest<DocumentRo>({
            url     : urlApiBuilder.bsdGenerate(),
            method  : 'POST',
            sync    : true,
            payload : {
                body: {
                    cc_id      : cc?.id,
                    collect_id : data.id
                } as BSDManualGenerateDto
            }
        });

        if (response.success && response.response && response.response.data.ro) {
            const signedUrl = await bbngRequest<string>({
                url    : urlApiBuilder.documentGetSignedUrl(response.response.data.ro.id),
                method : 'GET'
            });
            if (signedUrl.success && signedUrl.response && signedUrl.response.data.ro) {
                window.open(signedUrl.response.data.ro, '_blank');
            }
        }

        setLoading(false);
    };

    const ModalNoBSD = () => {
        const footer = (
            <div>
                <Button
                    label="Regénérer le BSD"
                    icon="pi pi-refresh"
                    onClick={(e) => {
                        setShowModalNoContent(false);
                        handleRegenerateBSD(e);
                    }}
                />
            </div>
        );

        return (
            <Dialog
                header="Problème lors du téléchargement du BSD"
                visible={showModalNoContent}
                onHide={() => setShowModalNoContent(false)}
                footer={footer}
            >
                <p className="m-0">
                    Il semblerait qu'il n'y ait pas de BSD associé à cette collecte.
                    <br />
                    Cela est fait suite à un problème technique.
                    <Divider />
                    Nous vous conseillons de regénérer le BSD.
                </p>
            </Dialog>
        );
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <PageLoader loading actionType="read" />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.collectHistory()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <Page>
            <ModalNoBSD />
            <h1>{displayAccurateTitle(data)}</h1>
            <div
                style={{
                    display        : 'flex',
                    flexDirection  : isDesktop ? 'row' : 'column',
                    justifyContent : 'space-around',
                    gap            : '16px'
                }}
            >
                {pictures.length > 0 && <ImageViewer images={pictures.map((image) => docMap[image])} />}
                <div style={{ flexDirection: 'column' }}>
                    <DescriptionContainer>
                        <StatusTag
                            value={mapCollectStatus(data?.status)}
                            severity={mapStatusToSeverity(data?.status as CC_STATUS)}
                        />
                        <ActionsFormContainer
                            archived={false}
                            readOnly={true}
                            handleArchiveOnView={() => undefined}
                            editPageUrl={urlBuilder.collectEdit(dataId)}
                            historyUrl={urlApiBuilder.collectHistory(dataId)}
                            disableDeleteOnView={true}
                            disableEditOnView={data?.category !== PlanningShiftStepCategory.SERVICE}
                        />
                        <DescriptionContentContainer>
                            <DescriptionContent>
                                <DescriptionTimeContainer>
                                    <DescriptionTime>{moment(data?.arrived_at).format('HH:mm')}</DescriptionTime>
                                    <DescriptionTime>-</DescriptionTime>
                                    <DescriptionTime>{moment(data?.completed_at).format('HH:mm')}</DescriptionTime>
                                </DescriptionTimeContainer>
                                <DescriptionDate>
                                    {moment(data?.arrived_at).format('dddd DD MMMM YYYY')}
                                </DescriptionDate>
                            </DescriptionContent>
                            <DescriptionContent>
                                {presta && (
                                    <DescriptionDriverContainer>
                                        <label>Prestataire</label>
                                        <DriverData>
                                            <a
                                                href={urlBuilder.prestaView(presta.id as string)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {presta.name}
                                            </a>
                                        </DriverData>
                                    </DescriptionDriverContainer>
                                )}
                                {collector && (
                                    <DescriptionDriverContainer>
                                        <label>Conducteur</label>
                                        <DriverData>
                                            <a
                                                href={urlBuilder.collectorView(collector.id as string)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {collector.fullname}
                                            </a>
                                        </DriverData>
                                    </DescriptionDriverContainer>
                                )}
                                {truck && (
                                    <DescriptionDriverContainer>
                                        <label>Camion</label>
                                        <DriverData>
                                            <a
                                                href={urlBuilder.truckView(truck.id as string)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {truck.name} - {truck.weight_ton}T
                                            </a>
                                        </DriverData>
                                    </DescriptionDriverContainer>
                                )}
                                {customer && (
                                    <DescriptionDriverContainer>
                                        <label>Client</label>
                                        <DriverData>
                                            <a
                                                href={urlBuilder.customerView(customer.id)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {customer.name}
                                            </a>
                                        </DriverData>
                                    </DescriptionDriverContainer>
                                )}
                                {dataService && dataService.type === EPlanningType.DUMPSTER && (
                                    <DescriptionDriverContainer>
                                        <label>Poids de la benne</label>
                                        <DriverData>
                                            {dataService.informations?.dumpster_weight
                                                ? `${dataService.informations.dumpster_weight}kg`
                                                : 'Non renseigné'}
                                        </DriverData>
                                    </DescriptionDriverContainer>
                                )}
                            </DescriptionContent>
                        </DescriptionContentContainer>
                    </DescriptionContainer>
                    <Divider />
                    <ServiceContainer>
                        <FlexContainer style={{ alignItems: 'flex-start' }}>
                            {consSite && cc && (
                                <>
                                    <ContentContainer>
                                        <h3>Commande</h3>
                                        <OrderContainer>
                                            <OrderItem>
                                                <OrderItemLabel>Référence commande</OrderItemLabel>
                                                <OrderItemData>
                                                    <a
                                                        href={urlBuilder.orderView(cc.order_id[0] as string)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {cc.order_number}
                                                    </a>
                                                </OrderItemData>
                                            </OrderItem>
                                            <OrderItem>
                                                <OrderItemLabel>Référence collecte</OrderItemLabel>
                                                <OrderItemData>
                                                    <a
                                                        href={urlBuilder.collectConfigView(cc.id)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {cc.number}
                                                    </a>
                                                </OrderItemData>
                                            </OrderItem>
                                            <OrderItem>
                                                <OrderItemLabel>Type</OrderItemLabel>
                                                <OrderItemData>
                                                    {mapCollectConfigFamilyText(cc.family, true)}
                                                </OrderItemData>
                                            </OrderItem>
                                            <OrderItem>
                                                <OrderItemLabel>Commentaire</OrderItemLabel>
                                                <OrderItemData>{cc.comment || 'Aucun commentaire'}</OrderItemData>
                                            </OrderItem>
                                            {dataService && (
                                                <OrderItem>
                                                    <OrderItemLabel>Références factures</OrderItemLabel>
                                                    <OrderItemData>
                                                        {invoices.length > 0 ? (
                                                            <div>
                                                                {invoices.map((invoice, index) => (
                                                                    <div key={index}>
                                                                        <a
                                                                            href={mapInvoiceToInvoiceUrl(invoice)}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            {invoice.number_zoho_invoice}
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            'Aucune facture'
                                                        )}
                                                    </OrderItemData>
                                                </OrderItem>
                                            )}
                                        </OrderContainer>
                                    </ContentContainer>
                                    <ContentContainer>
                                        <h3>Chantier</h3>
                                        <AddressContainer>
                                            <div>
                                                <AddressMapButton
                                                    lat={consSite?.address?.coordinates.latitude || 0}
                                                    lng={consSite?.address?.coordinates.longitude || 0}
                                                />
                                            </div>
                                            <AddressDetails>
                                                <div>
                                                    <a
                                                        href={urlBuilder.constructionSiteView(consSite.id)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {consSite?.label}
                                                    </a>
                                                </div>
                                                <div>{displayAddress(consSite?.address)}</div>
                                                <div>
                                                    {cc?.zone?.metropole} - {cc?.zone?.name}
                                                </div>
                                            </AddressDetails>
                                        </AddressContainer>
                                    </ContentContainer>
                                </>
                            )}
                            {dataService && (
                                <ContentContainer>
                                    <h3>Evacuation</h3>
                                    {emptying && Object.keys(emptying).length > 0
                                        ? `Collecte évacuée à ${emptyingDesc}`
                                        : 'Non évacuée pour le moment'}
                                </ContentContainer>
                            )}
                        </FlexContainer>
                        <CollectAnomaliesDisplay collect={data} />
                        <CollectProducts cc={cc} collect={data} />
                        {[CC_STATUS.FINISHED, CC_STATUS.CANCELED].includes(data?.status as any) && (
                            <FlexContainer
                                style={{
                                    alignItems    : pictures.length > 0 ? 'center' : 'flex-start',
                                    flexDirection : pictures.length > 0 ? 'column' : 'row'
                                }}
                            >
                                <CollectedItemsContainer>
                                    <div style={{ display: 'flex' }}>
                                        {data?.document_id.find((docId) => docId.includes(EDocumentType.BSD)) && (
                                            <Button
                                                style={{ marginRight: '1rem' }}
                                                onClick={(e) => handleDownloadBSD(e)}
                                                icon="pi pi-download"
                                                label="Télécharger le BSD"
                                            />
                                        )}
                                        {(data as CollectService | null)?.informations.emptied_at_landfill_id && (
                                            <Button
                                                icon="pi pi-refresh"
                                                label="Regénérer le BSD"
                                                onClick={(e) => handleRegenerateBSD(e)}
                                            />
                                        )}
                                    </div>
                                </CollectedItemsContainer>
                            </FlexContainer>
                        )}
                        {dataService?.status === CC_STATUS.HAZARD && (
                            <HazardContainer>
                                <h3>Détails de l'aléa</h3>
                                <HazardReason>
                                    {mapCollectHazardReason(dataService.hazard_reason as ECollectHazardReason)}
                                </HazardReason>
                                <p>{dataService.hazard_comment}</p>
                            </HazardContainer>
                        )}
                    </ServiceContainer>
                    {dataEmptying && (
                        <ServiceContainer>
                            <FlexContainer>
                                <ContentContainer>
                                    <h3>Lieu d'évacuation</h3>
                                    <AddressContainer>
                                        <div>
                                            <AddressMapButton
                                                lat={landfill?.address?.coordinates.latitude || 0}
                                                lng={landfill?.address?.coordinates.longitude || 0}
                                            />
                                        </div>
                                        <AddressDetails>
                                            <div>{landfill?.name}</div>
                                            {landfill?.address && <div>{displayAddress(landfill?.address)}</div>}
                                        </AddressDetails>
                                    </AddressContainer>
                                </ContentContainer>
                                {dataEmptying?.status === CC_STATUS.FINISHED ? (
                                    <DescriptionContent>
                                        <DescriptionDriverContainer>
                                            <label>Collectes évacuées</label>
                                            <DriverData>
                                                {(informationEmptying?.list_of_cc || []).length > 0
                                                    ? informationEmptying?.list_of_cc?.join(' | ')
                                                    : 'Aucune'}
                                            </DriverData>
                                        </DescriptionDriverContainer>
                                        <DescriptionDriverContainer>
                                            <label>Numéro bon de pesée</label>
                                            <DriverData>{informationEmptying?.landfill_number}</DriverData>
                                        </DescriptionDriverContainer>
                                    </DescriptionContent>
                                ) : (
                                    <HazardContainer>
                                        <h3>Détails de l'aléa</h3>
                                        <HazardReason>
                                            {mapCollectHazardReason(
                                                dataEmptying?.hazard_reason as ECollectHazardReason
                                            )}
                                        </HazardReason>
                                        <p>{dataEmptying?.hazard_comment}</p>
                                    </HazardContainer>
                                )}
                            </FlexContainer>
                        </ServiceContainer>
                    )}

                    {data?.document_id?.some((s) => s.startsWith(`${EDocumentType.COLLECT_SIGNATURE}|`)) && (
                        <div
                            style={{
                                marginTop     : '0px',
                                fontWeight    : 'bold',
                                display       : 'flex',
                                flexDirection : 'column',
                                alignItems    : 'center'
                            }}
                        >
                            <div style={{ marginBottom: '16px' }}>{`Signé par ${
                                (data?.informations as { signed_by: string })?.signed_by || '"N/A"'
                            }`}</div>
                            <Media
                                b64={
                                    docMap[
                                        data?.document_id.find((f) => f.includes(EDocumentType.COLLECT_SIGNATURE)) ?? ''
                                    ]
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </Page>
    );
};

export default CollectDetail;
