import React from 'react';
import styled from 'styled-components';

import { CardErrors, ConstructionSiteCharacteristic, PrestaRo } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type ConstructionSiteGeneralProps = {
    readOnly?: boolean;
    value?: ConstructionSiteGeneralState;
    id: string;
    result: (
        value: ConstructionSiteGeneralState,
        errors: null | string[] | CardErrors<ConstructionSiteGeneralState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type ConstructionSiteGeneralState = {
    label: string;
    tag: Array<ConstructionSiteCharacteristic>;
    complement: string;
    by_presta?: boolean;
    presta?: PrestaRo;
};

export type ConstructionSiteGeneralErrorState = CardErrors<ConstructionSiteGeneralState>;

export const initialState: ConstructionSiteGeneralState = {
    label      : '',
    tag        : [],
    complement : ''
};

export const initialErrorState: ConstructionSiteGeneralErrorState = generateInitialErrorState(initialState);

const characteristicsMapper: Record<ConstructionSiteCharacteristic, string> = {
    [ConstructionSiteCharacteristic.LEED]          : 'Chantier Leed',
    [ConstructionSiteCharacteristic.NARROW_STREET] : 'Rue étroite'
};

export const ConstructionSiteGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: ConstructionSiteGeneralProps) => {
    const [val, setVal] = React.useState<ConstructionSiteGeneralState>(value);
    const [err, setErr] = React.useState<ConstructionSiteGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <Input.Text
                    required={false}
                    label="Nom"
                    id="label"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.label}
                    errors={err.label}
                    displayError={displayError}
                />
            </CardLine>
            <Button.Group
                required={false}
                id="tag"
                readOnly={readOnly}
                result={handleChange}
                options={Object.values(ConstructionSiteCharacteristic)}
                labelMapper={(value) => characteristicsMapper[value as ConstructionSiteCharacteristic]}
                value={val.tag}
                errors={err.tag}
                displayError={displayError}
            />
            <CardLine>
                <Input.Textarea
                    required={false}
                    label="Informations complémentaires"
                    id="complement"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.complement}
                    errors={err.complement}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <div>
                    <Button.Switch
                        id="by_presta"
                        readOnly={readOnly}
                        result={(value, error, childId) => {
                            handleChange(value, error, childId);
                            handleChange(undefined, value ? ['Le prestataire est requis'] : null, 'presta');
                        }}
                        value={val.by_presta ?? false}
                        label="Gestion par un prestataire"
                        labelPosition="left"
                    />
                    <div style={{ width: '250px', marginTop: '8px' }}>
                        <RelationAutocomplete.Presta
                            readOnly={readOnly || !val.by_presta}
                            placeholder="Selectionner un prestataire"
                            baseValue={val.presta}
                            errors={err.presta}
                            displayError={displayError}
                            onSelect={(value) => {
                                handleChange(value, null, 'presta');
                            }}
                            onUnselect={() => {
                                handleChange(undefined, ['Le prestataire est requis'], 'presta');
                            }}
                        />
                    </div>
                </div>
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    .p-selectbutton {
        margin-bottom: 10px;
    }
`;
