import { removeLastSlash, urlMaker } from '@bbng/util/misc';

import { config } from './env';

export const WEBSOCKET_URL: string = removeLastSlash(urlMaker(config.baseUrl, config.websocket.port));
export const ADMIN_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.adminOrchestration.port)
);
export const ADMIN_PLAYER_BASE_URL: string = removeLastSlash(urlMaker(config.baseUrl, config.adminPlayer.port));
export const PRODUCT_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.productOchestration.port)
    //urlMaker('https://localhost', config.productOchestration.port)
);
export const ZONE_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.zoneOrchestration.port)
);
export const COLLECT_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.collectOrchestration.port)
    // urlMaker('https://localhost', config.collectOrchestration.port)
);
export const COLLECTOR_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.collectorOrchestration.port)
);
export const PRESTA_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.prestaOrchestration.port)
    // urlMaker('https://localhost', config.prestaOrchestration.port)
);
export const CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.constructionSiteOrchestration.port)
);
export const CONTACT_PLAYER_BASE_URL: string = removeLastSlash(urlMaker(config.baseUrl, config.contactPlayer.port));
export const CUSTOMER_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.customerOrchestration.port)
    // urlMaker('https://localhost', config.customerOrchestration.port)
);
export const DISCOUNT_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.discountOrchestration.port)
);
export const DOCUMENT_PLAYER_BASE_URL: string = removeLastSlash(
    urlMaker(
        config.baseUrl,
        config.documentPlayer.port
        // 'https://localhost', config.documentPlayer.port
    )
);
export const DUMPSTER_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.dumpsterOrchestration.port)
);
export const ELASTIC_PLAYER_BASE_URL: string = removeLastSlash(urlMaker(config.baseUrl, config.elasticPlayer.port));
export const ITEM_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.itemOrchestration.port)
);
export const LANDFILL_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.landfillOrchestration.port)
    // urlMaker('https://localhost', config.landfillOrchestration.port)
);
export const ORDER_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.orderOrchestration.port)
    // urlMaker('https://localhost', config.orderOrchestration.port)
);
export const PLANNING_CONFIG_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.planningConfigOrchestration.port)
);
export const TRUCK_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.truckOrchestration.port)
);
export const USER_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.userOrchestration.port)
);
export const PLANNING_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.planningOrchestration.port)
    // urlMaker('https://localhost', config.planningOrchestration.port)
);
export const COLLECT_CONFIG_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.collectConfigOrchestration.port)
    // urlMaker('https://localhost', config.collectConfigOrchestration.port)
);
export const INVOICE_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.invoiceOrchestration.port)
);
export const ZOHO_SERVER_BASE_URL: string = removeLastSlash(urlMaker(`${config.zohoServer.host}`));
export const BSD_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.bsdOrchestration.port)
);
export const KPI_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.kpiOrchestration.port)
    // urlMaker('https://localhost', config.kpiOrchestration.port)
);

export const SMS_ORCHESTRATION_BASE_URL: string = removeLastSlash(
    urlMaker(config.baseUrl, config.smsOrchestration.port)
    // urlMaker('https://localhost', config.smsOrchestration.port)
);

export const urlBuilder = {
    /**
     * Root
     */
    root: () => `/`,

    /**
     * Accept user invitation
     */
    acceptUserInvitation: (token: string) => `/customer-invitation/${token}`,

    /**
     * Confirm order
     */
    orderConfirmation: (token: string) => `/order-confirmation/${token}`,

    /**
     * Login
     */
    login: () => `/login`,

    /**
     * Coming soon
     */
    comingSoon: () => `/coming-soon`,

    /**
     * Dashboard
     */
    dashboard: () => `/dashboard`,

    endless3: () => `/dashboard/endless3`,

    /**
     * Lost dumpsters
     */
    stockDumpster: () => `/stock/dumpster`,

    /**
     * Planning
     */
    planning: () => `/dashboard/planning`,

    /**
     * User
     */
    userList   : (query?: string) => `/dashboard/users${query ? `?${query}` : ''}`,
    userCreate : () => `/dashboard/users/create`,
    userEdit   : (userId: string) => `/dashboard/users/edit/${userId}`,
    userView   : (userId: string) => `/dashboard/users/view/${userId}`,

    /**
     * Admin
     */
    adminList: () => `/dashboard/admins`,

    /**
     * Collector
     */
    collectorList   : () => `/dashboard/collectors`,
    collectorCreate : () => `/dashboard/collectors/create`,
    collectorEdit   : (collectorId: string) => `/dashboard/collectors/edit/${collectorId}`,
    collectorView   : (collectorId: string) => `/dashboard/collectors/view/${collectorId}`,

    /**
     * Presta
     */
    prestaList   : () => `/dashboard/prestas`,
    prestaCreate : () => `/dashboard/prestas/create`,
    prestaEdit   : (prestaId: string) => `/dashboard/prestas/edit/${prestaId}`,
    prestaView   : (prestaId: string) => `/dashboard/prestas/view/${prestaId}`,

    /**
     * Truck
     */
    truckList   : () => `/dashboard/trucks`,
    truckCreate : () => `/dashboard/trucks/create`,
    truckEdit   : (truckId: string) => `/dashboard/trucks/edit/${truckId}`,
    truckView   : (truckId: string) => `/dashboard/trucks/view/${truckId}`,

    /**
     * Landfill
     */
    landfillList   : () => `/dashboard/landfills`,
    landfillCreate : () => `/dashboard/landfills/create`,
    landfillEdit   : (landfillId: string) => `/dashboard/landfills/edit/${landfillId}`,
    landfillView   : (landfillId: string) => `/dashboard/landfills/view/${landfillId}`,

    /**
     *
     *  Revalorisation Export
     */
    revalorisationExport: () => `/dashboard/revalorisation`,

    /**
     * Customer
     */
    customerList           : () => `/dashboard/customers`,
    customerInvitationList : () => `/dashboard/customers/invitations`,
    customerCreate         : () => `/dashboard/customers/create`,
    customerEdit           : (customerId: string) => `/dashboard/customers/edit/${customerId}`,
    customerView           : (customerId: string) => `/dashboard/customers/view/${customerId}`,

    /**
     * Customer mandates
     */
    customerMandateList   : () => `/dashboard/mandates`,
    customerMandateCreate : () => `/dashboard/mandates/create`,

    /**
     * Order
     */
    orderList          : () => `/dashboard/orders`,
    orderCreate        : () => `/dashboard/orders/create`,
    orderCustomersList : () => `/dashboard/order-customer`,
    orderEdit          : (orderId: string) => `/dashboard/orders/edit/${orderId}`,
    orderView          : (orderId: string) => `/dashboard/orders/view/${orderId}`,
    orderDuplicate     : (orderId: string) => `/dashboard/orders/duplicate/${orderId}`,

    /**
     * Product
     */
    productList      : () => `/dashboard/products`,
    productCreate    : () => `/dashboard/products/create`,
    productEdit      : (productId: string) => `/dashboard/products/edit/${productId}`,
    productView      : (productId: string) => `/dashboard/products/view/${productId}`,
    productDuplicate : (productId: string) => `/dashboard/products/duplicate/${productId}`,

    /**
     * Zone
     */
    zoneList      : () => `/dashboard/zones`,
    zoneCreate    : () => `/dashboard/zones/create`,
    zoneEdit      : (zoneId: string) => `/dashboard/zones/edit/${zoneId}`,
    zoneView      : (zoneId: string) => `/dashboard/zones/view/${zoneId}`,
    zoneDuplicate : (zoneId: string) => `/dashboard/zones/duplicate/${zoneId}`,

    /**
     * Construction Site
     */
    constructionSiteList   : () => `/dashboard/construction-sites`,
    constructionSiteCreate : () => `/dashboard/construction-sites/create`,
    constructionSiteEdit   : (constructionSiteId: string) => `/dashboard/construction-sites/edit/${constructionSiteId}`,
    constructionSiteView   : (constructionSiteId: string) => `/dashboard/construction-sites/view/${constructionSiteId}`,

    /**
     * Discount
     */
    discountList      : () => `/dashboard/discounts`,
    discountCreate    : () => `/dashboard/discounts/create`,
    discountEdit      : (discountId: string) => `/dashboard/discounts/edit/${discountId}`,
    discountDuplicate : (discountId: string) => `/dashboard/discounts/duplicate/${discountId}`,
    discountView      : (discountId: string) => `/dashboard/discounts/view/${discountId}`,

    /**
     * Collect config
     */
    collectConfigBase       : () => `/dashboard/collect-configs`,
    collectConfigPresta     : () => `/dashboard/collect-configs/presta`,
    collectConfigDumpster   : () => `/dashboard/collect-configs/dumpster`,
    collectConfigBigbag     : () => `/dashboard/collect-configs/bigbag`,
    collectConfigDeliveries : () => `/dashboard/deliveries`,
    collectConfigEdit       : (collectConfigId: string) => `/dashboard/collect-configs/edit/${collectConfigId}`,
    collectConfigView       : (collectConfigId: string, isInternal?: boolean) =>
        `/dashboard/collect-configs/view/${collectConfigId}${isInternal ? '?isInternal=true' : ''}`,
    ccReplanActivities: () => `/dashboard/collect-configs/replan`,

    /**
     * Collect
     */
    collectHistory         : () => `/dashboard/collects`,
    collectPrestaHistory   : () => `/dashboard/collects/presta`,
    collectView            : (collectId: string) => `/dashboard/collects/view/${collectId}`,
    collectEdit            : (collectId: string) => `/dashboard/collects/edit/${collectId}`,
    collectCreate          : (collectId: string) => `/dashboard/collects/create/${collectId}`,
    collectBulkTerminateBB : () => `/dashboard/collects/bulk-terminate-bigbag`,

    /**
     * Dumpsters on site
     */
    dumpsterOnSiteList   : () => `/dashboard/dumpsters-on-site`,
    dumpsterOnSiteCreate : () => `/dashboard/dumpsters-on-site/create`,
    dumpsterOnSiteView   : (dumpsterId: string) => `/dashboard/dumpsters-on-site/view/${dumpsterId}`,
    dumpsterOnSiteEdit   : (dumpsterId: string) => `/dashboard/dumpsters-on-site/edit/${dumpsterId}`,

    /**
     * Invoice
     */
    invoiceList: () => `/dashboard/invoices`,

    /**
     * BSD
     */
    bsdList: () => `/dashboard/bsds`,

    /**
     * Google drive
     */
    googleDriveActions: () => `/dashboard/google-drive-actions`,

    /**
     * SMS
     */
    smsList: () => `/dashboard/sms`,

    /**
     * Zoho server
     */
    zohoBase    : () => `${ZOHO_SERVER_BASE_URL}`,
    zohoInvoice : (organizationId: number, invoiceId: string) =>
        `${ZOHO_SERVER_BASE_URL}/${organizationId}#/invoices/${invoiceId}`
} as const;

export const urlApiBuilder = {
    /**
     * ELASTICSEARCH
     */
    elasticCustomer         : () => `${ELASTIC_PLAYER_BASE_URL}/v1/elasticsearch/customer/autocomplete`,
    elasticTruck            : () => `${ELASTIC_PLAYER_BASE_URL}/v1/elasticsearch/truck/autocomplete`,
    elasticConstructionSite : () => `${ELASTIC_PLAYER_BASE_URL}/v1/elasticsearch/construction_site/autocomplete`,

    /**
     * CONTACT
     */
    contactAutocomplete: () => `${CONTACT_PLAYER_BASE_URL}/v1/contacts/autocomplete`,

    /**
     * CUSTOMER
     */
    customerGet       : (customerId: string) => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${customerId}`,
    customerGetMany   : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/retrieve`,
    customerHistory   : (customerId: string) => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${customerId}/history`,
    customerGetAll    : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/`,
    customerExport    : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/export`,
    customerEdit      : (customerId: string) => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${customerId}`,
    customerCreate    : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/`,
    customerDelete    : (customerId: string) => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${customerId}`,
    customerArchive   : (customerId: string) => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${customerId}/archive`,
    customerUnarchive : (customerId: string) =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${customerId}/unarchive`,
    customerCreateMissingDrives      : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/create-missing-google-drives`,
    customerCreateMissingMonthFolder : () =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/create-missing-month-folders`,
    customerCreateMissingConsSiteFolder: () =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/create-missing-cons-site-folders`,
    customerUploadMissingBsds     : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/upload-missing-bsds`,
    customerSendGoogleDriveEmails : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/send-google-drive-emails`,
    customerCreateZoho            : (customerId: string) =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/zoho/customer/create/${customerId}`,
    customerCreateMandateFlow : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/create-mandate-flow-gocardless`,
    customerAcceptInvitation  : (token: string) =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${token}/accept-invitation`,
    customerRejectInvitation: (token: string) =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${token}/reject-invitation`,
    customerResendInvitation: (siret: string) =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${siret}/create-invitation`,
    customerDeleteInvitation: (userId: string) =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/${userId}/delete-invitation`,
    customerGetInvitation              : (token: string) => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/invitation/${token}`,
    customerRevalorisationExport       : () => `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/revalorisation/export`,
    customerGetOrderConfirmationEmails : (customerId: string) =>
        `${CUSTOMER_ORCHESTRATION_BASE_URL}/v1/customers/confirmation-emails/${customerId}`,

    /**
     * LANDFILL
     */

    landfillGet               : (landfillId: string) => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/${landfillId}`,
    landfillGetMany           : () => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/retrieve`,
    landfillHistory           : (landfillId: string) => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/${landfillId}/history`,
    landfillGetAll            : () => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/`,
    landfillEdit              : (landfillId: string) => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/${landfillId}`,
    landfillCreate            : () => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/`,
    landfillArchive           : (landfillId: string) => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/${landfillId}/archive`,
    landfillDelete            : (landfillId: string) => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/${landfillId}`,
    landfillRevalorisationGet : (revalorisationId: string) =>
        `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/revalorisation/${revalorisationId}`,
    landfillRevalorisationGetByLandfillId: (landfillId: string) =>
        `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/${landfillId}/revalorisation`,
    landfillRevalorisationCreate : () => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/revalorisation`,
    landfillRevalorisationUpdate : (revalorisationId: string) =>
        `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/revalorisation/${revalorisationId}`,
    landillRevalorisationExport: () => `${LANDFILL_ORCHESTRATION_BASE_URL}/v1/landfills/revalorisation/export`,

    /**
     * CONSTRUCTION SITE
     */
    constructionSiteGet: (constructionSiteId: string) =>
        `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/${constructionSiteId}`,
    constructionSiteGetMany : () => `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/retrieve`,
    constructionSiteHistory : (constructionSiteId: string) =>
        `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/${constructionSiteId}/history`,
    constructionSiteGetAll       : () => `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/`,
    constructionSiteGetAllByUser : (userId: string) =>
        `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/user/${userId}`,
    constructionSiteEdit: (constructionSiteId: string) =>
        `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/${constructionSiteId}`,
    constructionSiteCreate  : () => `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/`,
    constructionSiteArchive : (constructionSiteId: string) =>
        `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/${constructionSiteId}/archive`,
    constructionSiteDelete: (constructionSiteId: string) =>
        `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/${constructionSiteId}`,
    constructionSiteCheckExiting: () =>
        `${CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}/v1/construction-sites/check-existing`,

    /**
     * ADMIN
     */
    adminGet     : (adminId: string) => `${ADMIN_ORCHESTRATION_BASE_URL}/v1/admins/${adminId}`,
    adminGetMany : () => `${ADMIN_ORCHESTRATION_BASE_URL}/v1/admins/retrieve`,
    adminHistory : (adminId: string) => `${ADMIN_ORCHESTRATION_BASE_URL}/v1/admins/${adminId}/history`,
    adminGetAll  : () => `${ADMIN_ORCHESTRATION_BASE_URL}/v1/admins/`,

    /**
     * USER
     */
    userCreate             : () => `${USER_ORCHESTRATION_BASE_URL}/v1/users/`,
    userEdit               : (userId: string) => `${USER_ORCHESTRATION_BASE_URL}/v1/users/${userId}`,
    userGet                : (userId: string) => `${USER_ORCHESTRATION_BASE_URL}/v1/users/${userId}`,
    userGetMany            : () => `${USER_ORCHESTRATION_BASE_URL}/v1/users/retrieve`,
    userHistory            : (userId: string) => `${USER_ORCHESTRATION_BASE_URL}/v1/users/${userId}/history`,
    userGetAll             : () => `${USER_ORCHESTRATION_BASE_URL}/v1/users/`,
    userAssignSpecificRole : (userId: string) =>
        `${USER_ORCHESTRATION_BASE_URL}/v1/users/roles/assign/specific/${userId}`,
    userAssignCustomRole : (userId: string) => `${USER_ORCHESTRATION_BASE_URL}/v1/users/roles/assign/custom/${userId}`,
    userArchive          : (userId: string) => `${USER_ORCHESTRATION_BASE_URL}/v1/users/${userId}/archive`,

    /**
     * TRUCK
     */
    truckGet     : (truckId: string) => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/${truckId}`,
    truckGetMany : () => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/retrieve`,
    truckHistory : (truckId: string) => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/${truckId}/history`,
    truckGetAll  : () => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/`,
    truckEdit    : (truckId: string) => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/${truckId}`,
    truckCreate  : () => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/`,
    truckArchive : (truckId: string) => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/${truckId}/archive`,
    truckDelete  : (truckId: string) => `${TRUCK_ORCHESTRATION_BASE_URL}/v1/trucks/${truckId}`,

    /**
     * COLLECTOR
     */
    collectorGet     : (collectorId: string) => `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/${collectorId}`,
    collectorGetMany : () => `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/retrieve`,
    collectorHistory : (collectorId: string) =>
        `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/${collectorId}/history`,
    collectorGetAll  : () => `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/`,
    collectorEdit    : (collectorId: string) => `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/${collectorId}`,
    collectorCreate  : () => `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/`,
    collectorArchive : (collectorId: string) =>
        `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/${collectorId}/archive`,
    collectorDelete: (collectorId: string) => `${COLLECTOR_ORCHESTRATION_BASE_URL}/v1/collectors/${collectorId}`,

    /**
     * PRESTA
     */
    prestaGet     : (prestaId: string) => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/${prestaId}`,
    prestaGetMany : () => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/retrieve`,
    prestaHistory : (prestaId: string) => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/${prestaId}/history`,
    prestaGetAll  : () => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/`,
    prestaEdit    : (prestaId: string) => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/${prestaId}`,
    prestaCreate  : () => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/`,
    prestaArchive : (prestaId: string) => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/${prestaId}/archive`,
    prestaDelete  : (prestaId: string) => `${PRESTA_ORCHESTRATION_BASE_URL}/v1/prestas/${prestaId}`,

    /**
     * PRODUCTS
     */
    productGet           : (productId: string) => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/${productId}`,
    productGetMany       : () => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/retrieve`,
    productHistory       : (productId: string) => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/${productId}/history`,
    productGetAll        : (type: '' | 'public') => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/${type}`,
    productEdit          : (productId: string) => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/${productId}`,
    productCreate        : () => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/`,
    productArchive       : (productId: string) => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/${productId}/archive`,
    productSetVisibility : (productId: string) =>
        `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/visibility/${productId}`,
    productDelete           : (productId: string) => `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/${productId}`,
    productCancelationPrice : (ccId: string) =>
        `${PRODUCT_ORCHESTRATION_BASE_URL}/v1/products/cancelation-price/${ccId}`,

    /**
     * ZONES
     */
    zoneGet     : (zoneId: string) => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/${zoneId}`,
    zoneGetMany : () => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/retrieve`,
    zoneHistory : (zoneId: string) => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/${zoneId}/history`,
    zoneGetAll  : () => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/`,
    zoneEdit    : (zoneId: string) => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/${zoneId}`,
    zoneCreate  : () => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/`,
    zoneArchive : (zoneId: string) => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/${zoneId}/archive`,
    zoneDelete  : (zoneId: string) => `${ZONE_ORCHESTRATION_BASE_URL}/v1/zones/${zoneId}`,

    /**
     * TRUCK
     */
    orderGet             : (orderId: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${orderId}`,
    orderGetMany         : () => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/retrieve`,
    orderHistory         : (orderId: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${orderId}/history`,
    orderGetAll          : () => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/`,
    orderPay             : (orderId: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${orderId}/pay`,
    orderResendLink      : (orderId: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${orderId}/resend-link`,
    orderCreate          : () => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/`,
    orderArchive         : (orderId: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${orderId}/archive`,
    orderDelete          : (orderId: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${orderId}`,
    orderPatch           : (orderId: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${orderId}`,
    orderGetConfirmation : (token: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/confirmation/${token}`,
    orderConfirm         : (token: string) => `${ORDER_ORCHESTRATION_BASE_URL}/v1/orders/${token}/confirm`,
    /**
     * DISCOUNT
     */
    discountGet          : (discountId: string) => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/${discountId}`,
    discountGetMany      : () => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/retrieve`,
    discountHistory      : (discountId: string) => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/${discountId}/history`,
    discountGetAll       : () => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/`,
    discountEdit         : (discountId: string) => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/${discountId}`,
    discountCreate       : () => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/`,
    discountArchive      : (discountId: string) => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/${discountId}/archive`,
    discountDelete       : (discountId: string) => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/${discountId}`,
    discountPublish      : (discountId: string) => `${DISCOUNT_ORCHESTRATION_BASE_URL}/v1/discounts/${discountId}/public`,

    /**
     * PLANNING
     */
    planningGetAll          : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings`,
    planningPost            : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings`,
    planningCalculate       : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/calculate`,
    planningCustomCalculate : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/calculate/custom`,
    planningManualShift     : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/manual-shift`,
    planningSave            : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/save`,
    planningUnassign        : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/unassign`,
    planningExport          : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/export`,
    planningGetInstance     : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/instance`,
    planningPrice           : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/planning-price`,
    planningUnassignedCC    : (planningId: string) =>
        `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/${planningId}/unassign-cc`,
    planningGetVersions : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/versions`,
    planningRemoveStep  : () => `${PLANNING_ORCHESTRATION_BASE_URL}/v1/plannings/remove-step`,

    /**
     * DOCUMENT
     */
    documentGetSignedUrl    : (documentId: string) => `${DOCUMENT_PLAYER_BASE_URL}/v1/documents/${documentId}`,
    documentGetMany         : () => `${DOCUMENT_PLAYER_BASE_URL}/v1/documents/retrieve`,
    documentCreateSignedUrl : () => `${DOCUMENT_PLAYER_BASE_URL}/v1/documents/create-url`,

    /**
     * COLLECT
     */
    collectBreakPost             : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/break`,
    collectDriverPost            : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/driver`,
    collectEmptyingPost          : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/emptying`,
    collectAdministrativePost    : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/administrative`,
    collectServicePost           : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/service/success`,
    collectServicePatch          : (collectId: string) => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/${collectId}/service`,
    collectServiceHazardPost     : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/service/hazard`,
    collectServiceUnplannedPost  : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/cc/terminate`,
    collectOtherPost             : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/other`,
    collectGetAll                : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/`,
    collectGetMany               : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/retrieve`,
    collectGetById               : (collectId: string) => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/${collectId}`,
    collectReplaceBSD            : (collectId: string) => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/${collectId}/replace-bsd`,
    collectHistory               : (collectId: string) => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/${collectId}/history`,
    lostDumpsters                : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/find-dumpsters`,
    exportLostDumpsters          : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/find-dumpsters/export`,
    exportCollectByProduct       : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/export-by-product`,
    exportCollectDefault         : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/export-default`,
    exportPrestaCollectByProduct : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/export-by-product/presta`,
    exportPrestaCollectDefault   : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/export-default/presta`,

    /**
     * DUMPSTER ON SITE
     */
    dumpsterOnSiteGetAll : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site`,
    dumpsterOnSitePost   : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site`,
    dumpsterOnSitePatch  : (dumpsterId: string) =>
        `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/${dumpsterId}`,
    dumpsterOnSiteGetById: (dumpsterId: string) =>
        `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/${dumpsterId}`,
    dumpsterOnSiteGetMany : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/retrieve`,
    dumpsterOnSiteArchive : (dumpsterId: string) =>
        `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/${dumpsterId}/archive`,
    dumpsterOnSiteHistory: (dumpsterId: string) =>
        `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/${dumpsterId}/history`,
    dumpsterOnSitePrepareSync : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/sync/prepare`,
    dumpsterOnSiteExecSync    : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/sync/exec`,
    dumpsterOnSiteExport      : () => `${COLLECT_ORCHESTRATION_BASE_URL}/v1/collects/dumpster-on-site/export`,

    /**
     * COLLECT_CONFIG
     */
    collectConfigCreate : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs`,
    collectConfigEdit   : (collectConfigId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/${collectConfigId}`,
    collectConfigHistory: (collectConfigId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/${collectConfigId}/history`,
    collectConfigRead: (collectConfigId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/${collectConfigId}`,
    collectConfigGetMany   : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/retrieve`,
    collectConfigReadAll   : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/`,
    ccAdministrativeCreate : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/administrative`,
    ccAdministrativeEdit   : (ccAdministrativeId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/administrative/${ccAdministrativeId}`,
    ccAdministrativeRead: (ccAdministrativeId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/administrative/${ccAdministrativeId}`,
    ccAdministrativeGetMany: () =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/administrative/retrieve`,
    ccAdministrativeReadAll : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/administrative/`,
    ccAdministrativeCancel  : (ccAdministrativeId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/administrative/${ccAdministrativeId}/cancel`,
    collectConfigCancel: (collectConfigId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/${collectConfigId}/cancel`,
    collectConfigSplit: (collectConfigId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/${collectConfigId}/split`,
    collectConfigMerge: (collectConfigId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/${collectConfigId}/merge`,
    collectConfigFinish: (collectConfigId: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/${collectConfigId}/finish`,
    collectConfigPostpone      : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/postpone`,
    collectConfigStatsDensity  : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/stats/density`,
    collectConfigStatsEndless3 : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/stats/endless3`,
    collectConfigReplan        : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/replan`,
    collectConfigSMSPreview    : (id: string) =>
        `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/sms-preview/${id}`,
    collectConfigSendSMS : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/sms/driver_incoming`,
    exportCollectConfig  : () => `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs/export`,

    /**
     * INVOICE
     */
    invoiceGetMany     : () => `${INVOICE_ORCHESTRATION_BASE_URL}/v1/invoices/retrieve`,
    invoiceGetAll      : () => `${INVOICE_ORCHESTRATION_BASE_URL}/v1/invoices/`,
    invoiceGenerate    : () => `${INVOICE_ORCHESTRATION_BASE_URL}/v1/invoices/generate`,
    invoicePreGenerate : () => `${INVOICE_ORCHESTRATION_BASE_URL}/v1/invoices/pre-generate`,
    invoiceEmail       : () => `${INVOICE_ORCHESTRATION_BASE_URL}/v1/invoices/email`,
    invoiceDebit       : () => `${INVOICE_ORCHESTRATION_BASE_URL}/v1/invoices/debit`,
    invoiceDownload    : (id: string) => `${INVOICE_ORCHESTRATION_BASE_URL}/v1/invoices/download/${id}`,

    /**
     * BSD
     */
    bsdGenerate: () => `${BSD_ORCHESTRATION_BASE_URL}/v1/bsd/`,

    adminAuthStartOtp     : () => `${ADMIN_PLAYER_BASE_URL}/v1/admins-auth/auth/otp`,
    adminAuthFinishOtp    : () => `${ADMIN_PLAYER_BASE_URL}/v1/admins-auth/auth/otp-verify`,
    adminAuthVerifyToken  : () => `${ADMIN_PLAYER_BASE_URL}/v1/admins-auth/auth/verify`,
    adminAuthRefreshToken : () => `${ADMIN_PLAYER_BASE_URL}/v1/admins-auth/auth/refresh`,
    adminAuthUser         : () => `${ADMIN_PLAYER_BASE_URL}/v1/admins-auth/auth/me`,

    kpiGet: () => `${KPI_ORCHESTRATION_BASE_URL}/v1/kpi-dashboard/`
} as const;
