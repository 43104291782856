import { Tag } from 'primereact/tag';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { CardErrors, EUserRole, PhoneValue, UserRo } from '@bbng/util/types';

import { mapUserRole } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import { UserFormState } from '../../pages/UserForm/helpers';

const EUserRoleWithoutCustom = Object.values(EUserRole).filter((role) => role !== EUserRole.BBNG_USER_CUSTOM);

export type UserGeneralProps = {
    readOnly?: boolean;
    value?: UserGeneralState;
    id: string;
    result: (value: UserGeneralState, errors: null | string[] | CardErrors<UserGeneralState>, id: string) => void;
    displayError?: boolean;
    apiState?: UserRo;
    formSate?: UserFormState;
};

export type UserGeneralState = {
    firstname: string;
    lastname: string;
    email: string;
    phone_number: PhoneValue | null;
    roles: EUserRole[];
    id_stripe_customer_1: string;
    id_stripe_customer_2: string;
    id_stripe_customer_3: string;
    notify_user: boolean;
};

export type UserGeneralErrorState = CardErrors<UserGeneralState>;

export const initialState: UserGeneralState = {
    firstname            : '',
    lastname             : '',
    email                : '',
    phone_number         : null,
    roles                : [EUserRole.BBNG_USER_EMPLOYEE],
    id_stripe_customer_1 : '',
    id_stripe_customer_2 : '',
    id_stripe_customer_3 : '',
    notify_user          : false
};

export const initialErrorState: UserGeneralErrorState = generateInitialErrorState(initialState);

export const UserGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    apiState,
    formSate
}: UserGeneralProps) => {
    const [val, setVal] = React.useState<UserGeneralState>(value);
    const [err, setErr] = React.useState<UserGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (
        value: boolean | string | string[] | null | number | undefined | PhoneValue,
        errors: string[] | null,
        childId: string
    ) => {
        const newVal = { ...val, [childId]: value };
        setVal(newVal);

        //Forbid dot for first name and last name.
        if (childId === 'firstname' || childId === 'lastname') {
            if ((value as string).trim().length === 0) {
                const newErr = { ...err, [childId]: ['Vous devez renseigner ce champ'] };
                setErr(newErr);
                return;
            }
            if (
                (value as string)
                    .trim()
                    .split('')
                    .every((char) => char.toLowerCase() === '.' || char.toLowerCase() === 'x') ||
                new Set((value as string).trim().toLowerCase().split('')).size === 1 //Check if all characters are the same
            ) {
                const newErr = {
                    ...err,
                    [childId]: [
                        'Vous ne pouvez pas renseigner que des points ou que des X ou la même lettre. ⚠️ Cela apparaitra sur les factures et communications.'
                    ]
                };
                setErr(newErr);
                return;
            }
            const newErr = { ...err, [childId]: errors };

            setErr(newErr);

            return;
        }
        const newErr = { ...err, [childId]: errors };
        setErr(newErr);
    };

    const allowNotifyUser = useMemo(() => {
        if (!apiState) {
            /**
             * If we are in create state, we allow notifying user.
             */
            return true;
        } else if (apiState?.customer_id?.length === 0 && !!formSate?.customer?.customer) {
            /**
             * If we are in edit state, if the existing user is not attached to a customer but a customer is selected, we allow notifying user.
             */
            return true;
        } else {
            return false;
        }
    }, [apiState, formSate]);

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <Input.Text
                    required={false}
                    label="ID STRIPE 1 (big bags)"
                    id="id_stripe_customer_1"
                    readOnly={true}
                    result={handleChange}
                    value={val.id_stripe_customer_1}
                    errors={err.id_stripe_customer_1}
                    displayError={displayError}
                />
                <Input.Text
                    required={false}
                    label="ID STRIPE 2 (bennes ampliroll)"
                    id="id_stripe_customer_2"
                    readOnly={true}
                    result={handleChange}
                    value={val.id_stripe_customer_2}
                    errors={err.id_stripe_customer_2}
                    displayError={displayError}
                />
                <Input.Text
                    required={false}
                    label="ID STRIPE 3 (bennes à chaîne)"
                    id="id_stripe_customer_3"
                    readOnly={true}
                    result={handleChange}
                    value={val.id_stripe_customer_3}
                    errors={err.id_stripe_customer_3}
                    displayError={displayError}
                />
                <Input.Text
                    required={true}
                    label="Prénom"
                    id="firstname"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.firstname}
                    errors={err.firstname}
                    displayError={displayError}
                />
                <Input.Text
                    required={true}
                    label="Nom"
                    id="lastname"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.lastname}
                    errors={err.lastname}
                    displayError={displayError}
                />
                <Input.Phone
                    required={true}
                    id="phone_number"
                    readOnly={readOnly}
                    result={handleChange}
                    errors={err.phone_number}
                    value={val.phone_number}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <InputWithLabel>
                    <Input.Email
                        required={true}
                        label="Email"
                        id="email"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.email}
                        errors={err.email}
                        displayError={displayError}
                    />
                    <Tag severity="warning" icon="pi pi-exclamation-triangle">
                        Attention, ce champ correspond à un email de connexion d'un utilisateur. À modifier avec
                        précaution.
                    </Tag>
                </InputWithLabel>
                <StyledGroup>
                    <StyledLabel>Rôles</StyledLabel>
                    <Button.Group
                        validationSchema={yup.array().min(1, 'Vous devez sélectionner au moins un rôle').required()}
                        id="roles"
                        readOnly={readOnly}
                        result={handleChange}
                        options={Object.values(EUserRoleWithoutCustom)}
                        labelMapper={(value) => mapUserRole(value as EUserRole)}
                        value={val.roles}
                        errors={err.roles}
                        displayError={displayError}
                    />
                </StyledGroup>
            </CardLine>
            {!readOnly && allowNotifyUser && (
                <StyledGroup>
                    <Button.Switch
                        id="notify_user"
                        result={handleChange}
                        value={val.notify_user}
                        label="Notifier l'utilisateur avec e-mail de bienvenue"
                        labelPosition="right"
                    />
                </StyledGroup>
            )}
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;

const StyledGroup = styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 12px;
`;

const StyledLabel = styled.div`
    font-weight: 500;
    margin-bottom: 4px;
`;

const InputWithLabel = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;
