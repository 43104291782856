import { formatPhoneE164ToPhoneValue, formatPhoneNumberToE164 } from '@bbng/util/misc';
import { CREATED_FROM, CustomerRo, EUserRole, UserCreateDto, UserEditDto, UserRo } from '@bbng/util/types';

import { getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    UserCustomerErrorState,
    UserCustomerState,
    initialErrorState as userCustomerInitialErrorState,
    initialState as userCustomerInitialState
} from '../../modules/user/Customer';
import {
    UserGeneralErrorState,
    UserGeneralState,
    initialErrorState as userGeneralInitialErrorState,
    initialState as userGeneralInitialState
} from '../../modules/user/General';

export type UserModulesStates = UserGeneralState | UserCustomerState;

export type UserModulesErrorStates = UserGeneralErrorState | UserCustomerErrorState;

export type UserFormState = {
    general: UserGeneralState;
    customer: UserCustomerState;
};

export type UserFormErrorState = {
    general: UserGeneralErrorState;
    customer: UserCustomerErrorState;
};

export const initialState: UserFormState = {
    general  : userGeneralInitialState,
    customer : userCustomerInitialState
};

export const initialErrorState: UserFormErrorState = {
    general  : userGeneralInitialErrorState,
    customer : userCustomerInitialErrorState
};

export const mapApiDataToState = (user: UserRo): UserFormState => {
    return {
        general: {
            email                : user.email,
            firstname            : user.firstname,
            lastname             : user.lastname,
            phone_number         : user.phone_number ? formatPhoneE164ToPhoneValue(user.phone_number) : null,
            roles                : user.roles as EUserRole[],
            id_stripe_customer_1 : user.id_stripe_customer_1 ?? '',
            id_stripe_customer_2 : user.id_stripe_customer_2 ?? '',
            id_stripe_customer_3 : user.id_stripe_customer_3 ?? '',
            notify_user          : false
        },
        customer: {
            customer: user.customer_id[0] as unknown as CustomerRo
        }
    };
};

export const mapStateToApiCreateData = (state: UserFormState): UserCreateDto => {
    return {
        email        : state.general.email,
        firstname    : state.general.firstname,
        lastname     : state.general.lastname,
        phone_number : state.general.phone_number ? formatPhoneNumberToE164(state.general.phone_number) : undefined,
        customer_id  : state.customer.customer ? [state.customer.customer.id] : [],
        roles        : state.general.roles,
        notify_user  : state.general.notify_user,
        created_from : CREATED_FROM.BACK_OFFICE
    };
};

export const mapStateToApiEditData = (state: UserFormState, apiState: UserFormState, discount: UserRo): UserEditDto =>
    optimiseEditDto(state, apiState, discount, mapStateToApiCreateData, getRelationsDto<UserRo>('customer_id'));
