import PrimeReact from 'primereact/api';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { EPlanningType } from '@bbng/util/types';

import { config } from './common/env';
import { urlBuilder } from './common/urlBuilder';
import { AuthenticatedRoutes } from './components/Auth';
import { withLayout } from './components/Layout';
import { Toast } from './components/Toast';
import { GoogleMapsProvider } from './context/GoogleMaps';
// import { WSCommunicationProvider } from './context/WSCommunication';
// import NxWelcome from './nx-welcome';
import { AdminsListing } from './pages/Admins';
import { BsdsListing } from './pages/Bsds';
import CollectDetail from './pages/Collect/detail';
import { CollectConfigFormPage } from './pages/CollectConfigForm';
import { CollectConfigsListing } from './pages/CollectConfigs';
import CollectorForm from './pages/CollectorForm';
import { CollectorsListing } from './pages/Collectors';
import { CollectsListing } from './pages/Collects';
import ComingSoon from './pages/ComingSoon';
import { ConstructionSiteListing } from './pages/ConstructionSite';
import ConstructionSiteForm from './pages/ConstructionSiteForm';
import CustomerForm from './pages/CustomerForm';
import { CustomerListing } from './pages/Customers';
import Dashboard from './pages/Dashboard';
import { DeliveryListing } from './pages/Deliveries';
import DiscountForm from './pages/DiscountForm';
import { DiscountsListing } from './pages/Discounts';
import Error404URL from './pages/Error404URL';
import { InvoicesListing } from './pages/Invoices';
import LandfillForm from './pages/LandfillForm';
import { LandfillsListing } from './pages/Landfills';
import LoginPage from './pages/Login';
import OrderForm from './pages/OrderForm';
import { OrdersListing, OrderCustomerListing } from './pages/Orders';
import Planning from './pages/Planning';
import { ProductListing } from './pages/Product';
import ProductForm from './pages/ProductForm';
import TruckForm from './pages/TruckForm';
import { TrucksListing } from './pages/Trucks';
import UserForm from './pages/UserForm';
import { UsersListing } from './pages/Users';
import { ZoneListing } from './pages/Zones';
import ZoneForm from './pages/ZonesForm';
import { GoogleDriveActions } from './pages/GoogleDrive';
import { FinishPage } from './pages/Finish';
import { LostDumpstersListing } from './pages/DumpsterStock';
import { MandateListing } from './pages/Mandate';
import MandateForm from './pages/MandateForm';
import AcceptCustomerInvitation from './pages/AcceptCustomerInvitation';
import { CustomerInvitationsListing } from './pages/CustomerInvitations';
import { RevalorisationPage } from './pages/Revalorisation';
import AcceptOrderConfirmation from './pages/AcceptOrderConfirmation';
import CollectForm from './pages/CollectForm/form';
import PrestaForm from './pages/PrestaForm';
import { PrestasListing } from './pages/Prestas';
import CompleteServiceCollectForm from './pages/CompleteServiceCollectForm/form';
import { DumpsterOnSiteListing } from './pages/DumpstersOnSite';
import DumpsterOnSiteForm from './pages/DumpsterOnSiteForm';
import { PrestaCollectsListing } from './pages/PrestaCollects';
import { PrestaCollectConfigsListing } from './pages/PrestaCollectConfigs';
import { CCReplanActivitiesListing } from './pages/CCReplanActivities';
import { SMSListing } from './pages/sms';
import { StatistiquesEndless3Page } from './pages/StatistiquesEndless3';

const StyledApp = styled.div<{ dev?: boolean }>`
    background-color: ${({ dev }) => (dev ? '#Ff00ca' : 'none')};
    padding: ${({ dev }) => (dev ? '12px' : '0')};
`;

const Version = styled.div`
    position: fixed;
    right: 5px;
`;

/**
 * Prime variables.
 **/
PrimeReact.ripple = true;
PrimeReact.cssTransition = true;

/**
 * Define Pages.
 */

export function App() {
    const envVersion = config.nodeEnv;

    return (
        <StyledApp dev={envVersion === 'dev'}>
            <GoogleMapsProvider>
                {/* <WSCommunicationProvider> */}
                <Version>{config.frontVersion}</Version>
                <Toast />
                <Routes>
                    <Route path={urlBuilder.root()} element={<Navigate to={urlBuilder.login()} />} />
                    <Route path={urlBuilder.login()} element={<LoginPage />} />
                    <Route path={urlBuilder.acceptUserInvitation(':token')} element={<AcceptCustomerInvitation />} />
                    <Route path={urlBuilder.orderConfirmation(':token')} element={<AcceptOrderConfirmation />} />
                    <Route path={urlBuilder.comingSoon()} element={withLayout(<ComingSoon />)} />

                    <Route element={<AuthenticatedRoutes />}>
                        <Route path={urlBuilder.dashboard()} element={withLayout(<Dashboard />)} />
                        <Route path={urlBuilder.endless3()} element={withLayout(<StatistiquesEndless3Page />)} />

                        <Route path={urlBuilder.stockDumpster()} element={withLayout(<LostDumpstersListing />)} />

                        <Route path={urlBuilder.truckList()} element={withLayout(<TrucksListing />)} />
                        <Route path={urlBuilder.truckCreate()} element={withLayout(<TruckForm key="create" />)} />
                        <Route path={urlBuilder.truckEdit(':id')} element={withLayout(<TruckForm edit key="edit" />)} />
                        <Route
                            path={urlBuilder.truckView(':id')}
                            element={withLayout(<TruckForm readOnly key="view" />)}
                        />

                        <Route path={urlBuilder.collectorList()} element={withLayout(<CollectorsListing />)} />
                        <Route path={urlBuilder.collectBulkTerminateBB()} element={withLayout(<FinishPage />)} />
                        <Route
                            path={urlBuilder.collectorCreate()}
                            element={withLayout(<CollectorForm key="create" />)}
                        />
                        <Route
                            path={urlBuilder.collectorEdit(':id')}
                            element={withLayout(<CollectorForm edit key="view" />)}
                        />
                        <Route
                            path={urlBuilder.collectorView(':id')}
                            element={withLayout(<CollectorForm readOnly />)}
                        />

                        <Route path={urlBuilder.userList()} element={withLayout(<UsersListing />)} />
                        <Route path={urlBuilder.userCreate()} element={withLayout(<UserForm key="create" />)} />
                        <Route path={urlBuilder.userEdit(':id')} element={withLayout(<UserForm edit key="edit" />)} />
                        <Route
                            path={urlBuilder.userView(':id')}
                            element={withLayout(<UserForm readOnly key="view" />)}
                        />

                        <Route path={urlBuilder.adminList()} element={withLayout(<AdminsListing />)} />

                        <Route
                            path={urlBuilder.collectPrestaHistory()}
                            element={withLayout(<PrestaCollectsListing />)}
                        />
                        <Route path={urlBuilder.prestaCreate()} element={withLayout(<PrestaForm key="create" />)} />
                        <Route
                            path={urlBuilder.prestaEdit(':id')}
                            element={withLayout(<PrestaForm edit key="edit" />)}
                        />
                        <Route
                            path={urlBuilder.prestaView(':id')}
                            element={withLayout(<PrestaForm readOnly key="view" />)}
                        />
                        <Route path={urlBuilder.prestaList()} element={withLayout(<PrestasListing />)} />

                        <Route path={urlBuilder.landfillList()} element={withLayout(<LandfillsListing />)} />
                        <Route path={urlBuilder.landfillCreate()} element={withLayout(<LandfillForm key="create" />)} />
                        <Route
                            path={urlBuilder.landfillEdit(':id')}
                            element={withLayout(<LandfillForm edit key="edit" />)}
                        />
                        <Route
                            path={urlBuilder.landfillView(':id')}
                            element={withLayout(<LandfillForm readOnly key="view" />)}
                        />

                        <Route path={urlBuilder.customerList()} element={withLayout(<CustomerListing />)} />
                        <Route path={urlBuilder.customerCreate()} element={withLayout(<CustomerForm key="create" />)} />
                        <Route
                            path={urlBuilder.customerEdit(':id')}
                            element={withLayout(<CustomerForm edit key="edit" />)}
                        />
                        <Route
                            path={urlBuilder.customerView(':id')}
                            element={withLayout(<CustomerForm readOnly key="view" />)}
                        />

                        <Route
                            path={urlBuilder.constructionSiteList()}
                            element={withLayout(<ConstructionSiteListing />)}
                        />
                        <Route
                            path={urlBuilder.constructionSiteCreate()}
                            element={withLayout(<ConstructionSiteForm key="create" />)}
                        />
                        <Route
                            path={urlBuilder.constructionSiteEdit(':id')}
                            element={withLayout(<ConstructionSiteForm edit key="edit" />)}
                        />
                        <Route
                            path={urlBuilder.constructionSiteView(':id')}
                            element={withLayout(<ConstructionSiteForm readOnly key="view" />)}
                        />

                        <Route path={urlBuilder.orderList()} element={withLayout(<OrdersListing />)} />

                        <Route path={urlBuilder.orderCustomersList()} element={withLayout(<OrderCustomerListing />)} />

                        <Route path={urlBuilder.orderCreate()} element={withLayout(<OrderForm key="create" />)} />
                        <Route
                            path={urlBuilder.orderView(':id')}
                            element={withLayout(<OrderForm readOnly key="view" />)}
                        />
                        <Route
                            path={urlBuilder.orderDuplicate(':id')}
                            element={withLayout(<OrderForm duplicate key="duplicate" />)}
                        />

                        <Route path={urlBuilder.planning()} element={withLayout(<Planning />)} />

                        <Route path={urlBuilder.discountList()} element={withLayout(<DiscountsListing />)} />
                        <Route path={urlBuilder.discountCreate()} element={withLayout(<DiscountForm key="create" />)} />
                        <Route
                            path={urlBuilder.discountEdit(':id')}
                            element={withLayout(<DiscountForm edit key="edit" />)}
                        />
                        <Route
                            path={urlBuilder.discountView(':id')}
                            element={withLayout(<DiscountForm readOnly key="view" />)}
                        />
                        <Route
                            path={urlBuilder.discountDuplicate(':id')}
                            element={withLayout(<DiscountForm duplicate key="duplicate" />)}
                        />

                        <Route
                            path={urlBuilder.collectConfigPresta()}
                            element={withLayout(<PrestaCollectConfigsListing />)}
                        />
                        <Route
                            path={urlBuilder.collectConfigBigbag()}
                            element={withLayout(<CollectConfigsListing type={EPlanningType.BIG_BAG} />)}
                        />
                        <Route
                            path={urlBuilder.collectConfigDumpster()}
                            element={withLayout(<CollectConfigsListing type={EPlanningType.DUMPSTER} />)}
                        />
                        <Route path={urlBuilder.collectConfigDeliveries()} element={withLayout(<DeliveryListing />)} />
                        <Route
                            path={urlBuilder.collectConfigEdit(':id')}
                            element={withLayout(<CollectConfigFormPage edit key="cc-edit" />)}
                        />
                        <Route
                            path={urlBuilder.collectConfigView(':id')}
                            element={withLayout(<CollectConfigFormPage readOnly key="cc-view" />)}
                        />

                        <Route
                            path={urlBuilder.ccReplanActivities()}
                            element={withLayout(<CCReplanActivitiesListing />)}
                        />

                        <Route path={urlBuilder.collectHistory()} element={withLayout(<CollectsListing />)} />
                        <Route path={urlBuilder.collectView(':id')} element={withLayout(<CollectDetail />)} />
                        <Route path={urlBuilder.collectEdit(':id')} element={withLayout(<CollectForm edit />)} />
                        <Route
                            path={urlBuilder.collectCreate(':id')}
                            element={withLayout(<CompleteServiceCollectForm key="complete" />)}
                        />

                        <Route path={urlBuilder.dumpsterOnSiteList()} element={withLayout(<DumpsterOnSiteListing />)} />
                        <Route path={urlBuilder.dumpsterOnSiteCreate()} element={withLayout(<DumpsterOnSiteForm />)} />
                        <Route
                            path={urlBuilder.dumpsterOnSiteEdit(':id')}
                            element={withLayout(<DumpsterOnSiteForm edit />)}
                        />
                        <Route
                            path={urlBuilder.dumpsterOnSiteView(':id')}
                            element={withLayout(<DumpsterOnSiteForm readOnly />)}
                        />

                        <Route path={urlBuilder.invoiceList()} element={withLayout(<InvoicesListing />)} />

                        <Route path={urlBuilder.bsdList()} element={withLayout(<BsdsListing />)} />

                        <Route path={urlBuilder.productList()} element={withLayout(<ProductListing />)} />
                        <Route path={urlBuilder.productCreate()} element={withLayout(<ProductForm key="create" />)} />
                        <Route
                            path={urlBuilder.productView(':id')}
                            element={withLayout(<ProductForm readOnly key="view" />)}
                        />
                        <Route path={urlBuilder.productEdit(':id')} element={withLayout(<ProductForm edit />)} />

                        <Route path={urlBuilder.zoneList()} element={withLayout(<ZoneListing />)} />
                        <Route path={urlBuilder.zoneCreate()} element={withLayout(<ZoneForm key="create" />)} />
                        <Route
                            path={urlBuilder.zoneView(':id')}
                            element={withLayout(<ZoneForm readOnly key="view" />)}
                        />
                        <Route path={urlBuilder.zoneEdit(':id')} element={withLayout(<ZoneForm edit />)} />
                        <Route path={urlBuilder.googleDriveActions()} element={withLayout(<GoogleDriveActions />)} />

                        <Route path={urlBuilder.customerMandateList()} element={withLayout(<MandateListing />)} />
                        <Route path={urlBuilder.customerMandateCreate()} element={withLayout(<MandateForm />)} />
                        <Route
                            path={urlBuilder.customerInvitationList()}
                            element={withLayout(<CustomerInvitationsListing />)}
                        />
                        <Route path={urlBuilder.smsList()} element={withLayout(<SMSListing />)} />
                    </Route>
                    <Route path={urlBuilder.revalorisationExport()} element={withLayout(<RevalorisationPage />)} />
                    <Route path="*" element={withLayout(<Error404URL />)} />
                </Routes>
                {/* </WSCommunicationProvider> */}
            </GoogleMapsProvider>
        </StyledApp>
    );
}

export default App;
